.client_filters_wrapper {
  justify-content: space-between !important;
  @include respond(mobile) {
    align-items: flex-start !important;
  }
  .client_search {
    width: 32rem !important;
  }
}

.admin_company_details {
  height: 100%;
  display: grid;
  grid-template-columns: 24rem 1fr;
  @include respond(tablet) {
    grid-template-columns: 1fr;
  }

  .sidebar_layout_wrapper {
    background: #fff;
  }
  .main_content_wrapper {
    max-width: 100vw;
    @include respond(tablet) {
      background: $slate50;
      grid-column: 1/3;
    }

    .aging_btns_wrapper {
      @include flex(row, center, space-between);

      .aging_due_wrapper {
        width: 15.5rem;
        background: $slate50;
        border: 1px solid $slate200;
        border-radius: 0.4rem;
        height: 4rem;
        @include flex(row, center, center);
        .title {
          @include font(400, $small);
        }
        .value {
          @include font(600, $normal);
          margin-left: 0.5rem;
        }
      }
    }
    .main_content_box_wrapper {
      overflow-y: auto;
      overflow-x: hidden;
      .main_content_box {
        background: #fff;
        height: 100%;
        .content_box {
          height: inherit;
        }
      }
    }
  }
  .legal_watchlist_wrapper {
    background: #fff;
  }
}

.account_transactions {
  @extend .admin_company_details;
  grid-template-columns: 1fr;
  .sidebar_layout_wrapper {
    display: none;
  }
}

.admin_add_user_form_wrapper {
  font-size: $normal;
  width: 100%;

  .add_user_titles {
    color: $slate900;
    @include font(500, $medium);
    padding: 1rem 0;
  }
  .add_user_extra_section {
    @include flex(row, center, space-between);
    button {
      padding: 0.8rem 1.2rem;
    }
    &:nth-child(1) {
      margin-bottom: 2rem;
    }
  }

  .add_user_form_section {
    display: grid;
    grid-auto-rows: auto;
    grid-row-gap: 0.15rem;

    .add_user_selections {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 1rem;
      grid-row-gap: 0.15rem;
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }
    .update_admin_account {
      .select_container {
        &:nth-child(3),
        &:nth-child(4) {
          display: none;
        }
      }
    }
    .add_update_sub_admin_account {
      .select_container {
        &:nth-child(1) {
          display: none;
        }
        &:nth-child(2) {
          grid-column: 1/3;
        }
      }
    }
    .subadmin_status {
      @include flex(row, center, flex-start);
      gap: 2rem;
    }
  }

  .add_user_section {
    @include flex(row, center, space-between);
  }
}

.add_client_wrapper {
  padding: 2rem 4rem;
  background: $slate50;
  overflow-y: auto;
  height: 100%;
  @include scrollbar;

  @include respond(mobile) {
    padding: 1rem 1.5rem;
  }

  .add_client_title {
    @include font(600, $big);
    color: $slate900;
    margin-bottom: 2rem;
  }

  .add_client_sub_title {
    @include font(500, $normal_big);
    color: $slate700;
    margin-bottom: 2rem;
  }
  .add_client_form {
    margin-bottom: 2rem;

    .logo_section {
      @include flex(row, center, flex-start);
      gap: 2.4rem;
      flex-wrap: wrap;
      @include respond(tablet) {
        gap: 1rem;
      }

      .logo_wrapper {
        width: 35.5rem;
        @include flex(row, center, flex-start);
        gap: 2.4rem;
        @include respond(mobile) {
          gap: 1rem;
        }

        .logo_image {
          border-radius: 0.4rem;
          border: 1px solid $slate200;
          height: 12rem;
          width: 12rem;
          background: #fff;
          img {
            max-height: 100%;
            object-fit: cover;
          }
          @include respond(mobile) {
            height: 10rem;
            width: 10rem;
          }
        }
        .logo_btns_title {
          @include flex(column, flex-start, center);
          gap: 1.6rem;
          .logo_title {
            @include font(500, $normal);
            color: $slate900;
          }
          .logo_btns {
            @include flex(row, center, flex-start);
            gap: 1.25rem;
            button {
              padding: 0.85rem 2.4rem;
            }
          }
        }
      }
    }
    .add_client_section {
      margin: 1rem 0;
      grid-column-gap: 2rem;
      grid-row-gap: 1rem;

      .add_client_extra_options {
        @include flex(row, center, flex-start);
        gap: 2rem;
      }
    }
    .add_client_section_1 {
      @include respond(tablet) {
        grid-template-columns: repeat(2, 1fr);
        gap: 1.25rem;
      }
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }

    .add_client_groups {
      margin-bottom: 2rem;
      @include flex(row, center, flex-start);
      gap: 2rem;
      .add_client_sub_title {
        margin-bottom: 0;
      }
    }
    .add_client_groups_wrapper {
      border: 1px solid $slate200;
      border-radius: 0.4rem;
      margin-bottom: 2rem;
      @include flex(column, flex-start, center);
      max-height: 25rem;
      overflow-y: auto;
      max-width: 35rem;
    }
    .add_client_form_5 {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-row-gap: 1rem;
      grid-column-gap: 2rem;
      @include respond(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }
    .add_client_form_2 {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-row-gap: 1rem;
      grid-column-gap: 2rem;
      @include respond(tablet) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }
    .client_active_status_wrapper {
      @include flex(row, center, flex-start);
      gap: 2rem;
      .status_title {
        color: $slate900;
        @include font(400, $normal);
      }
    }
  }
  .add_client_btn_wrapper {
    button {
      padding: 0.85rem 2.4rem;
    }
  }
}

.admin_client_industry_wrapper {
  .showOptions {
    max-height: 35rem !important;
  }
}
.admin_client_price_chart_wrapper {
  .showOptions {
    max-height: 25rem;
  }
}

// aging popup
.upto_date_wrapper {
  .upload_title {
    @include font(500, $normal);
    color: $slate900;
    margin: 1rem 0;
  }
  .upload_date_month_selection {
    @include flex(row, center, space-between);
    gap: 2rem;
    .showOptions {
      max-height: 9.5rem;
    }
  }
}

.client_details_container {
  height: calc(100vh - 20rem);
  overflow-y: auto;
  background: $slate50;
  padding: 1.6rem;

  .client_details_wrapper {
    padding: 1.6rem;
    background: #fff;
    margin-bottom: 2rem;

    .client_info_action {
      margin-bottom: 2rem;
      @include flex(row, flex-start, space-between);
      @include respond(mobile) {
        flex-wrap: wrap;
        gap: 1rem;
      }

      .client_logo_details {
        display: grid;
        grid-template-columns: 8rem 1fr;
        grid-column-gap: 1rem;
        align-items: center;
        width: 100%;

        .client_details {
          display: grid;
          grid-template-rows: repeat(3, 3rem);
          gap: 0.5rem;
          .client_name {
            max-width: 40%;
            span {
              color: $slate900;
              @include font(500, $normal);
            }
            @include respond(mobile) {
              max-width: 100%;
            }
          }
          .client_hierarchy,
          .client_status {
            max-width: 30%;
            @include respond(mobile) {
              max-width: 100%;
            }
          }
          .client_hierarchy {
            span {
              border-radius: 0.4rem;
              padding: 0.625rem;
              background: $slate800;
              color: #fff;
            }
          }
        }
      }

      .client_actions {
        button {
          padding: 0.75rem 1rem;
        }
      }
    }
    .client_info_wrapper {
      display: grid;
      grid-template-rows: repeat(5, 3.5rem);
      gap: 0.5rem;

      .client_info_section {
        display: grid;
        grid-template-columns: 8rem 1fr;
        gap: 5rem;
        @include respond(mobile) {
          grid-template-columns: 7rem 1fr;
          gap: 1rem;
        }

        .client_website {
          color: $primary_blue;
          text-decoration: underline;
          gap: 1rem;
          svg {
            cursor: pointer;
          }
        }
      }
    }
    .client_info_groups {
      margin-top: 2rem;
      @include flex(row, center, flex-start);
      gap: 3rem;
      flex-wrap: wrap;

      .client_info_section {
        width: 31.333%;
        @include respond(tablet) {
          width: 100%;
        }

        .client_info_value {
          max-width: 100%;

          .client_group_items {
            .client_group_item {
              padding: 0.25rem;
            }
          }
        }
        .client_info_mini_section {
          display: grid;
          grid-template-columns: auto 1fr;
          min-height: 3.5rem;
          max-height: max-content;
          gap: 1rem;
          width: 100%;
          margin: 0.5rem 0;
          &:nth-child(2n + 1) {
            background: $slate50;
          }
        }
      }
    }
    .client_info_title {
      @include flex(row, center, flex-start);
      gap: 0.5rem;
      @include font(500, $small);
      color: $slate500;
    }
    .client_info_value {
      max-width: 50%;
      @include respond(mobile) {
        max-width: 100%;
      }
      @include font(500, $small);
      color: $slate900;
      @include flex(row, center, flex-start);
    }
  }
}

.client_logo {
  height: 7.5rem;
  width: 7.5rem;
  background: #ddd;
  border-radius: 0.4rem;
  @include flex(row, center, center);
  img {
    object-fit: cover;
  }
}

.admin_client_tab {
  padding: 1.6rem;
  background: #fff;
  border-bottom: 1px solid $slate200;
  border-left: 1px solid $slate200;
}

.admin_client_filters {
  padding: 0;
  label {
    font-size: $normal;
  }
}

.add_sub_company_btn,
.add_user_btn_wrapper {
  button {
    margin-left: auto;
  }
}

.client_transaction_wrapper {
  background: $slate50;
  .transaction_view_wrapper {
    padding: 1.6rem;

    // summary
    .summary_wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 1rem;
      @include respond(tablet) {
        grid-template-columns: 1fr;
      }
      .summary_box {
        border-radius: 0.25rem;
        background: #fff;
        @include font(500, $normal);
        .summary_header {
          padding: 1rem;
          background: $primary_blue;
          border-radius: 0.25rem;
          @include flex(row, center, flex-start);
          gap: 1rem;

          .summary_title {
            width: 100%;
            color: #fff;
            @include flex(row, center, space-between);
          }
          &:last-child {
            background: unset;
            .summary_title {
              color: $slate500;
            }
          }
        }
        .summary_details {
          border-radius: 0.25rem;
          display: grid;
          grid-template-rows: repeat(4, 4rem);
          padding: 0 1rem;

          .summary_item {
            border-bottom: 1px solid $slate200;
            @include flex(row, center, space-between);
            &:last-child {
              border: none;
            }
            .summary_key {
              color: $slate500;
            }
          }
        }
      }
      .summary_group {
        display: grid;
        grid-template-rows: auto;
        grid-row-gap: 1rem;
      }
    }
  }
  .transaction_credit_report_wrapper {
    .actions_wrapper {
      @include flex(row, center, flex-wrap);
      gap: 1rem;
      .total_price_wrapper {
        white-space: nowrap;
        .key {
          @include font(400, $normal);
          color: $slate700;
        }
        .value {
          @include font(600, $normal);
          color: $primary_blue;
        }
      }
    }
  }
}
