.database_report_wrapper {
  .database_report_filter_selection_wrapper {
    background: $light_2;
    .database_report_filters {
      margin-bottom: 1rem;
      display: grid;
      grid-template-columns: auto 45rem 1fr;
      grid-column-gap: 2rem;
      padding: 1rem;

      @include respond(tablet) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
        grid-row-gap: 1.5rem;
      }

      .filter_2 {
        display: grid;
        grid-template-columns: 0.5fr 1fr 1fr 10rem;
        grid-column-gap: 2rem;
        align-items: center;
        .date_range {
          white-space: nowrap;
          @include font(500, $small);
          color: $secondary_blue;
        }
        @include respond(mobile) {
          grid-template-columns: 0.5fr 1fr 1fr;
          grid-column-gap: 1rem;
        }
      }
      .database_report_btn_wrapper {
        @include flex(row, center, flex-end);
        button:disabled {
          background: $green_3;
          color: $green_4;
        }
      }
      .search_by_wrapper {
        .search_heading {
          white-space: nowrap;
          font-size: $normal;
          @include flex(row, center, flex-start);
          margin-right: 1rem;
        }
        display: grid;
        grid-template-columns: auto auto 35rem;
        grid-column-gap: 2rem;
        @include respond(tablet) {
          grid-template-columns: 1fr 2fr 3fr;
        }
        @include respond(mobile) {
          grid-template-columns: 1fr 2fr;
          grid-gap: 1rem;
        }
        .selection_wrapper {
          background: #fff;
          @include flex(row, center, flex-start);
          padding: 0.35rem 1rem;
          border-radius: 0.8rem;
          width: max-content;
          gap: 1rem;

          .selection_option {
            width: max-content;
            padding: 0.35rem 1.6rem;
            border-radius: 0.4rem;
            @include font(500, $normal);

            @include flex(row, center, center);
            color: $dark;
            cursor: pointer;
            transition: 0.3s ease-in-out;
            &:hover {
              background: $lightgrey;
            }
            @include respond(mobile) {
              padding: 0.35rem 0.5rem;
            }
          }

          .selected {
            background: $primary_blue;
            color: #fff;
            &:hover {
              background: $primary_blue;
            }
          }
        }
        .table_field_wrapper {
          @include respond(mobile) {
            grid-column: 1/3;
          }
        }
      }
    }
    .database_report_type_selection_maxhit_wrapper {
      padding: 1rem;
      @include flex(row, center, space-between);
      .database_report_type_selection {
        .type {
          margin-right: 2rem;
        }
        @include font(400, $normal);
        @include flex(row, center, flex-start);
        label {
          @include flex(row, center, flex-start);
          margin-right: 2rem;
          input {
            margin-right: 0.5rem;
          }
        }
      }
      .maxhit_wrapper {
        @include font(400, $small);
        color: $dark;
      }
    }
    @include respond(mobile) {
      padding: 1rem;
    }
  }

  .make_your_search_wrapper,
  .no_results_found_wrapper {
    height: 100%;
    @include flex(column, center, center);
    .make_search_text {
      margin-top: 1rem;
      @include font(500, $medium);
      color: $secondary_blue;
    }
  }
  .no_results_found_wrapper {
    gap: 2rem;
    .no_results_found {
      @include font(600, $medium_big);
      color: $secondary_blue;
    }
    .database_tip {
      color: $secondary_blue;
      @include font(500, $normal_big);
    }
  }
}

.purchase_database_reports_wrapper {
  .purchase_backdrop_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.25);
    transition: 0.3s ease-in-out;
    @include flex(row, center, center);
    .purchase_wrapper {
      height: 29rem;
      width: 40rem;
      background: #fff;
      filter: drop-shadow(0px 8px 50px rgba(0, 0, 0, 0.1));
      border-radius: 0.8rem;

      @include font(500, $normal);
      @include flex(column, center, space-between);
      .purchase_heading {
        width: 100%;
        color: $primary_black;
        border-bottom: 1px solid $lightgrey;
        padding: 2.4rem;
        background: $dullgrey;
        @include flex(row, center, space-between);
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
        img {
          cursor: pointer;
        }
      }
      .purchase_content {
        width: 100%;
        padding: 2rem;
        .select_span,
        .purchase_text {
          color: $secondary_blue;
        }
        .purchase_text {
          margin-top: 2rem;
        }
      }
      .purchase_footer {
        width: 100%;
        border-top: 1px solid $lightgrey;
        padding: 1.6rem;
        background: $dullgrey;
        @include flex(row, center, flex-start);
        gap: 1rem;
        border-bottom-left-radius: 0.8rem;
        border-bottom-right-radius: 0.8rem;
        button {
          width: 16rem;
        }
      }
    }
  }
  .active {
    @extend .purchase_backdrop_wrapper;
    opacity: 1;
    visibility: visible;
  }
  .inactive {
    @extend .purchase_backdrop_wrapper;
    opacity: 0;
    visibility: hidden;
  }
}
