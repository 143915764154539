.table_field_wrapper {
  width: 100%;
  .table_input {
    outline: 1px solid $field;
    height: 4rem;
    border-radius: 0.4rem;
    font-size: $normal;
    padding: 0 1rem;
    width: calc(100% - 2rem);
    background: #fff;
  }
}
.input_container {
  .input_wrapper {
    width: 100%;
    position: relative;
    transition: all 0.3s;
    font-size: $normal;

    .custom_input {
      width: calc(100% - 1.2rem);
      outline: none;
      color: #000;
      margin: 0;
      height: 5rem;
      padding-left: 1rem;
    }
    .is_disabled {
      color: $light_23 !important;
      cursor: not-allowed !important;
    }
    .input_label {
      position: absolute;
      top: 50%;
      left: 1.25rem;
      transform: translate(0, -50%);
      transition: all 0.2s;
      pointer-events: none;

      .mandatory {
        color: $dark_1;
        font-weight: 500;
      }
      .recommended {
        @extend .mandatory;
        color: $success;
      }
    }
    .input_icon {
      position: absolute;
      top: 50%;
      left: 92%;
      transform: translate(0, -50%);
      transition: all 0.2s;
      pointer-events: none;
    }
    .fieldset_wrapper {
      border-radius: 0.4rem;
      position: absolute;
      pointer-events: none;
      top: -0.5rem;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      transition: all 0.2s;
      border-width: 1.5px;
      border-style: solid;
      background: transparent;
      border-color: $slate200;

      .legend_wrapper {
        white-space: nowrap;
        font-size: $small;
        max-width: 0;
        visibility: hidden;
        padding: 0;
        opacity: 0;
        transition: all 0.1s;
        height: 1rem;
      }
    }
    .custom_input:hover ~ .fieldset_wrapper {
      border-color: $slate800;
    }
    .custom_input:focus ~ .fieldset_wrapper {
      border-color: $primary_blue;
      .legend_wrapper {
        max-width: 100%;
        padding: 0 0.5rem;
      }
    }
    .custom_input:focus ~ .input_label {
      font-size: $small;
      top: 0;
      color: $primary_blue;
      padding: 0 0.5rem;
    }
    .custom_input:not(:placeholder-shown) ~ .input_label {
      top: 0;
      font-size: $small;
      padding: 0 0.5rem;
    }
    .custom_input:not(:placeholder-shown) ~ .fieldset_wrapper {
      .legend_wrapper {
        max-width: 100%;
        padding: 0 0.5rem;
      }
    }
    .is_disabled:not(:placeholder-shown) ~ .fieldset_wrapper {
      border-color: $slate200 !important;
    }
    .is_disabled ~ .input_label {
      color: $light_23 !important;
    }
    .is_error:is(:placeholder-shown) ~ .fieldset_wrapper {
      border-color: $danger !important;
    }
    .is_error:not(:placeholder-shown) ~ .fieldset_wrapper {
      border-color: $danger !important;
    }
    .is_valid:not(:placeholder-shown) ~ .fieldset_wrapper {
      border-color: $success !important;
    }
    .is_error ~ .input_label {
      color: $danger !important;
    }
    .is_valid ~ .input_label {
      color: $success !important;
    }
    .is_valid:focus ~ .fieldset_wrapper {
      border-color: $success !important;
    }
    .is_valid:not(:focus) ~ .fieldset_wrapper {
      border-color: $slate200 !important;
    }
    .is_valid:focus ~ .input_label {
      color: $success !important;
    }
    .is_valid:not(:focus) ~ .input_label {
      color: unset !important;
    }
  }
  .input_message {
    height: 2rem;
    margin: 0.5rem 0.25rem !important;

    @include font(500, $small);
    .is_valid {
      color: $success;
    }
    .is_error {
      color: $danger;
    }
    .is_warning {
      color: $ember600 !important;
    }
  }
}
