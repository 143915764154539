.legal_watchlist_wrapper {
  .legal_information_wrapper {
    padding: 1.6rem;
    border-bottom: 1px solid $lightgrey;
    .legal_info_bar {
      display: grid;
      grid-template-columns: 1fr auto;

      .btn_wrapper {
        @include flex(row, center, flex-start);
        gap: 1.5rem;
        button {
          font-weight: 500;
        }
        .secondary {
          color: $primary_black;
          background: $light_5;
          outline: 1.5px solid $light_6;
        }
      }
    }
    .information_section {
      display: grid;
      grid-template-columns: repeat(3, 22.5rem);
      grid-column-gap: 1rem;
      .section {
        border: 1px solid $light_4;
        border-radius: 0.4rem;
        @include flex(row, center, center);
        color: $primary_black;
        @include font(400, $small);
        .watchlist_count {
          margin-left: 1rem;
          color: $primary_blue;
          @include font(600, $normal_big);
        }
      }
    }
    .subject_watchlist_count_wrapper {
      @extend .information_section;
      grid-template-columns: repeat(2, 22.5rem);
    }
  }
}

.create_watchlist_popup {
  .popup_note {
    color: $primary_black;
    @include font(500, $small);
  }
  .popup_checkbox_wrapper {
    @include flex(row, center, flex-start);
    gap: 0.75rem;

    .popup_checkbox_lable {
      @include font(500, $normal);

      color: $primary_black;
    }
  }
}
.create_subscriber_popup {
  @extend .create_watchlist_popup;
}

.uploaded_file_name {
  color: $success;
  background: $dullgrey;
  padding: 1rem 2rem;
  @include font(500, $normal);
  border: 1px dashed $lightgrey;
  border-radius: 0.4rem;
}

.watchlist_tabs_wrapper {
  @include flex(row, stretch, flex-start);
  gap: 1rem;
  background: #fff;
  height: 5rem;
  border-bottom: 1px solid $lightgrey;
  .watchlist_tab {
    padding: 0.8rem 2.4rem;
    @include font(500, $normal);
    color: $secondary_blue;
    cursor: pointer;
    border-bottom: 0.2rem solid transparent;
    transition: 0.3s ease-in-out;
    @include flex(row, center, flex-start);
    white-space: nowrap;
  }
  .highlighted {
    @extend .watchlist_tab;
    color: $primary_blue;
    border-bottom: 0.2rem solid $primary_blue;
  }
  @include respond(mobile) {
    width: 100vw;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
