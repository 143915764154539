.header_sidebar_topbar_content_layout {
  position: relative;
  height: 100vh;
  display: grid;
  grid-template-rows: auto auto 1fr;
  grid-template-columns: 24rem 1fr;
  overflow: hidden;

  .topbar_layout_wrapper {
    grid-column: 1/3;
  }

  .header_layout_wrapper {
    grid-column: 1/3;
  }
  .hide_header {
    display: none;
    @include respond(tablet) {
      display: block;
    }
  }

  .sidebar_backdrop_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: rgba(0, 0, 0, 0.25);
    transition: 0.3s ease-in-out;
    z-index: 0;
  }

  @include respond(tablet) {
    grid-template-rows: 5rem 5rem 1fr;
    grid-template-columns: 1fr;

    .topbar_layout_wrapper {
      position: absolute;
      top: 0;
      bottom: 0;
      width: 75%;
      z-index: 1;
    }
    .sidebar_layout_wrapper {
      grid-column: 1/3;
    }
    .main_content_layout_wrapper {
      grid-column: 1/3;
      overflow-y: auto;
    }
  }

  .topbar_hidden {
    @extend .topbar_layout_wrapper;
    @include respond(tablet) {
      transition: 0.3s ease-in-out;
      left: -100%;
    }
  }
  .topbar_visible {
    @extend .topbar_layout_wrapper;
    transition: 0.3s ease-in-out;
    left: 0;
  }

  .sidebar_backdrop_visible {
    @extend .sidebar_backdrop_wrapper;
    visibility: visible;
    opacity: 1;
  }
  .sidebar_backdrop_hidden {
    @extend .sidebar_backdrop_wrapper;
    visibility: hidden;
    opacity: 0;
  }
}
