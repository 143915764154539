.content_layout_wrapper {
  display: grid;
  grid-template-columns: 1fr;
  .content_wrapper {
    width: 100vw;
    overflow-y: auto;
    scrollbar-width: none;

    background: $dullgrey;
    @include respond(mobile) {
      height: calc(100vh - 5rem);
    }
    height: calc(100vh - 6rem);
  }
  @include respond(tablet) {
    overflow-y: auto;
  }
}

.simulation_wrapper {
  background: $orange_1;
  padding: 2.4rem;
  display: grid;
  grid-template-columns: repeat(3, max-content) 1fr;
  align-items: center;
  gap: 3.6rem;

  @include respond(tablet) {
    grid-template-columns: repeat(4, max-content);
  }
  @include respond(mobile) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  .simulation_section {
    .title {
      @include font(500, $small);
      color: $ember_2;
    }
    .value {
      margin-left: 0.5rem;
      @include font(700, $normal);
      color: $ember50;
      position: relative;
      white-space: nowrap;
      &::after {
        content: '';
        height: 0.4rem;
        width: 0.4rem;
        border-radius: 50%;
        background: $ember_2;
        position: absolute;
        top: 50%;
        transform: translate(1.8rem, -50%);
        @include respond(mobile) {
          display: none;
        }
      }
    }
    .user_role {
      text-transform: capitalize;
    }
    .exit_simulation {
      @include font(600, $normal);
      color: $ember50;
      text-decoration: underline;
      text-align: right;
      @include respond(mobile) {
        text-align: left;
      }
      span {
        cursor: pointer;
      }
    }
  }
}

.pdf_viewer_action {
  margin: 0.5rem 4rem 0.5rem auto;
  .language_name {
    border-radius: 0.4rem;
    border: 1px solid rgba(0, 0, 0, 0.1);
    color: #fff;
    font-weight: 500;
    padding: 0.25rem 1.2rem;
    cursor: pointer;
    background: $primary_blue;
  }
}
