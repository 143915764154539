.groups_wrapper {
  .groups_btn_wrapper {
    padding: 1.6rem;

    button {
      margin-left: auto;
    }
  }
}

.group_form_wrapper {
  .group_section {
    .group_status {
      @include flex(row, center, flex-start);
      gap: 1.5rem;
    }
  }
}

.ethics_wrapper {
  @include font(500, $normal);
  color: $slate700;
}

.meetings_wrapper {
  .meetings_list_container {
    height: 100%;
    overflow-y: auto;
    padding: 1.6rem;
  }
}

.groups_tab_wrapper {
  padding: 1.6rem;
  background: #fff;
  border-bottom: 1px solid $slate200;

  .meetings_navigation {
    @include flex(row, center, flex-start);
    gap: 1rem;
    @include font(500, $normal);
    .meet_nav {
      color: $slate500;
      cursor: pointer;
    }
    .meet_nav_item {
      color: $slate800;
    }
  }
}
.meeting_event_wrapper {
  padding: 1.6rem;
  background: #fff;
  margin: 1rem 0;
  &:nth-child(1) {
    margin-top: 0;
  }

  .event_section {
    margin: 1rem 0;
    &:nth-child(1) {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }

    .event_description {
      color: $slate500;
      @include font(400, $normal);
    }
    .event_details {
      @include flex(row, center, flex-start);
      gap: 1rem;
      flex-wrap: wrap;
      @include respond(mobile) {
        @include flex(column, flex-start, center);
      }
      .event_detail {
        @include flex(row, center, flex-start);
        gap: 1rem;
        @include font(500, $small);
        .event_title {
          color: $slate500;
        }
        .event_value {
          color: $slate900;
        }
        &:not(:last-child):after {
          content: '';
          border: 1px solid $slate300;
          height: 1rem;
          @include respond(mobile) {
            border: none;
          }
        }
      }
    }
    .event_misc {
      @include flex(row, center, space-between);
      gap: 2rem;
      @include respond(mobile) {
        @include flex(column, flex-start, center);
        gap: 1rem;
      }

      .event_submissions {
        width: 50%;
        @include flex(row, center, space-between);
        @include font(500, $normal);
        @include respond(mobile) {
          width: 100%;
        }
        .event_title {
          color: $slate500;
        }
        .event_value {
          color: $slate900;
        }
      }
    }
    .event_actions {
      display: grid;
      grid-template-columns: 1fr auto auto;
      gap: 1rem;
    }
    .event_action_from_details {
      @include flex(row, center, space-between);
    }
    button {
      padding: 0.5rem 0.75rem;
    }
  }

  .event_name_status_date {
    @include flex(row, center, space-between);
    @include respond(mobile) {
      @include flex(column, flex-start, center);
      gap: 0.5rem;
    }
    color: $slate900;
    @include font(600, $normal);
    .event_status_date {
      @include flex(row, center, flex-start);
      gap: 2rem;
      @include respond(mobile) {
        justify-content: space-evenly;
      }

      .event_date {
        @include flex(row, center, flex-start);
        gap: 0.5rem;
        font-weight: 500;
      }
      .event_status {
        text-transform: capitalize;
      }
    }
  }
}

.event_details_wrapper,
.meeting_event_wrapper,
.meeting_subject_wrapper,
.reference_content_details {
  hr {
    border: 1px solid $slate200;
    margin-top: 1rem;
  }
}
.event_details_wrapper {
  .event_information {
    padding: 1.6rem;
    .event_actions_wrapper {
      @include flex(row, center, space-between);
      flex-wrap: wrap;
      gap: 1.5rem;

      .event_title {
        @include font(600, $normal);
        color: $slate900;
        @include flex(row, center, flex-start);
        gap: 0.5rem;
        white-space: nowrap;

        .event_status_released {
          border: 1px solid;
          @include font(500, $small);
          color: #fff;
          background: $orange_2;
          border-radius: 0.5rem;
          padding: 0.375rem 0.75rem;
        }
        .event_status_expired {
          @extend .event_status_released;
          background: $slate700;
        }
      }
      .event_action_btns {
        @include flex(row, center, flex-start);
        flex-wrap: wrap;
        gap: 2rem;
        button {
          padding: 0.75rem 1.5rem;
        }
      }
    }
  }
  .event_information_skeleton {
    padding: 1rem;
    height: 4.8rem;
    margin-bottom: 0.5rem;
  }
}
.meeting_event_skeleton_wrapper {
  @extend .meeting_event_wrapper;
  display: grid;
  grid-template-rows: repeat(4, 4rem);
  grid-row-gap: 1rem;
  .event_skeleton_one {
    width: 50%;
  }
  .event_skeleton_two {
    width: 75%;
  }
  .event_skeleton_three {
    width: 35%;
  }
}
.meeting_subject_wrapper {
  .meeting_subject_form {
    .form_basic_section {
      display: grid;
      grid-auto-rows: auto;
      grid-row-gap: 1.5rem;
      .form_section_wrapper {
        .subject_wrapper,
        .upload_section {
          .title {
            @include font(500, $small);
            color: $slate900;
          }
          .lable_checkbox_wrapper {
            span {
              @include font(400, $small);
              color: $slate900;
            }
          }
          @include flex(row, center, flex-start);
          gap: 2rem;
          @include respond(mobile) {
            flex-wrap: wrap;
            gap: 1rem;
          }
        }
        .upload_section {
          @include flex(column, flex-start, center);
          gap: 1rem;
          .upload_subject_file {
            width: 30rem;
            @include respond(mobile) {
              width: 100%;
            }
          }
        }
        .ref_title {
          @include font(500, $small);
          color: $slate900;
        }
      }
      .section_2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;
        @include respond(mobile) {
          grid-template-columns: 1fr;
          grid-gap: 1rem;
        }
      }
      .section_3 {
        @extend .section_2;
        grid-template-columns: repeat(3, 1fr);
        @include respond(mobile) {
          grid-template-columns: 1fr;
          grid-gap: 1rem;
        }
      }
      .section_4 {
        @extend .section_3;
        grid-template-columns: repeat(4, 1fr);
        @include respond(mobile) {
          grid-template-columns: 1fr;
          grid-gap: 1rem;
        }
      }
      .section_5 {
        @extend .section_4;
        grid-template-columns: repeat(5, 1fr);
        @include respond(mobile) {
          grid-template-columns: 1fr;
          grid-gap: 1rem;
        }
      }
      .alert_type {
        width: 32%;
        @include respond(mobile) {
          width: 100%;
        }
        .status {
          @include font(400, $normal);
          color: unset;
          padding: 0 1rem;
          height: unset;
          border-radius: unset;
          &:hover {
            color: #fff;
          }
        }
      }
      .alert_amount,
      .alert_corporate,
      .alert_end_date {
        width: 32%;
        @include respond(mobile) {
          width: 100%;
        }
      }
    }
  }
}

.event_subject_actions {
  @include flex(row, center, flex-start);
  gap: 1rem;
  padding: 1rem;
  background: #fff;
  border-bottom: 1px solid $slate200;
  @include respond(mobile) {
    flex-wrap: wrap;
  }

  button {
    &:nth-child(3) {
      margin-left: auto;
    }
  }
}

.create_meeting_wrapper {
  display: grid;
  grid-gap: 1rem;
  .create_meeting_section {
    .create_meeting_box {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }

    .box_3 {
      grid-row-gap: 2rem;
    }
  }
  hr {
    border: 1px solid $slate200;
  }
}

.mark_duplicate_wrapper {
  .title {
    color: $primary_blue;
    @include font(500, $normal);
  }
  .dublicate_subjects_wrapper {
    @include flex(row, center, flex-start);
    gap: 1rem;
  }
}

.event_submissions_wrapper {
  padding: 1.6rem;
  height: 62rem;
  overflow-y: auto;

  .event_submission_list_wrapper {
    display: grid;
    grid-template-columns: 1fr;
    grid-row-gap: 1rem;

    .event_submission_list_skeleton {
      height: 5rem;
      &:nth-child(2n + 1) {
        width: 75%;
      }
    }
    .event_submission_list {
      border: 1px solid $slate200;
      padding: 1rem;
      border-radius: 0.25rem;
      background: #fff;
      @include flex(row, center, space-between);
      @include respond(mobile) {
        @include flex(column, flex-start, center);
        gap: 1rem;
      }
      .event_name {
        @include font(500, $normal);
        color: $slate900;
      }
      .event_misc {
        @include flex(row, center, flex-start);
        @include respond(mobile) {
          @include flex(column, flex-start, center);
          gap: 1rem;
        }
        gap: 2rem;
        .event_ref {
          @include font(400, $small);
          color: $slate500;
        }

        .event_show_details {
          @include flex(row, center, flex-start);
          cursor: pointer;
          span {
            color: $slate700;
            @include font(500, $small);
          }
          .event_icon_down {
            height: 1.4rem;
            svg {
              fill: $slate500;
              font-size: $normal;
              transition: 0.3s ease-in-out;
            }
          }
          .event_icon_up {
            @extend .event_icon_down;
            svg {
              transform: rotate(180deg);
              transition: 0.3s ease-in-out;
            }
          }

          gap: 0.5rem;
        }
      }
      .event_details_content {
        @include flex(row, center, flex-start);
        flex-wrap: wrap;
        gap: 0.75rem;
        .event_details_wrapper {
          .event_title_value {
            @extend .event_details_content;
          }
        }
      }
    }
    .event_submission_details {
      height: auto;
      max-height: 0;
      transition: 0.3s ease-in-out;

      .event_submission_details_wrapper {
        display: grid;
        grid-row-gap: 1rem;
        grid-template-columns: 1fr;
        .event_submission_details_skeleton {
          height: 4rem;
        }
        .event_submission_details_content {
          padding: 0 1rem 1rem;
          .event_name_action {
            padding: 1rem 0;
            @include flex(row, center, space-between);
            span {
              @include font(600, $normal);
              color: $slate700;
            }
            button {
              padding: 0.5rem 0.75rem;
            }
          }
        }
      }
    }
    .event_expanded {
      @extend .event_submission_details;
      max-height: 100rem;
      transition: 0.3s ease-in-out;
    }
    .event_collapsed {
      @extend .event_expanded;
      max-height: 0;
    }
  }
}

.event_report_filters_wrapper {
  background: #fff;
  display: grid;
  padding: 1rem;
  grid-template-columns: 35rem 30rem auto;
  gap: 1rem;
  @include respond(tablet) {
    grid-template-columns: 35rem 30rem;
  }
  @include respond(mobile) {
    grid-template-columns: 1fr;
  }
}

.submit_subject_wrapper {
  padding: 1rem 0;
  height: 62rem;
  overflow-y: auto;
  .desc_content_wrapper {
    padding: 1.6rem;
    background: #fff;
    @include flex(row, center, space-between);
    gap: 1rem;
    flex-wrap: wrap;
    .deadline_wrapper,
    .subject_submissions_btn_wrapper {
      @include flex(row, center, flex-start);
      gap: 0.5rem;
      flex-wrap: wrap;
      .title {
        color: $slate500;
        @include font(500, $normal);
      }
      .value {
        color: $slate900;
        @include font(500, $small);
      }
      .subject_submission {
        @extend .deadline_wrapper;
        border-right: 1px solid $slate200;
        padding-right: 1rem;
        height: 4rem;
        @include respond(mobile) {
          border-right: none;
          padding-right: 0;
        }
      }
    }
    .subject_submissions_btn_wrapper {
      gap: 1rem;
    }
  }
  .desc_content_wrapper_skeleton {
    height: 6rem;
  }
  .subject_section_title {
    padding: 1.6rem;
    @include font(600, $normal_big);
    color: $primary_blue;
  }
}

.event_misc_divider {
  border: 1px solid $slate300;
  height: 1rem;
  @include respond(mobile) {
    display: none;
  }
}

////////////////////////////
/// network
.network_wrapper {
  .network_container {
    border-top: 1px solid $slate200;
    height: 100%;
    @include flex(row, stretch, flex-start);
    @include respond(tablet) {
      @include flex(column, flex-start, center);
    }
    .network_sidebar {
      padding: 1rem;
      background: #fff;
      border-right: 1px solid $slate200;
      // height: 100%;
      width: 34rem;
      @include respond(tablet) {
        border-right: none;
        height: auto;
        width: unset;
      }
      .sidebar_title {
        @include font(600, $normal);
        color: $slate700;
      }
      .sidebar_items_skeleton {
        @include flex(column, center, flex-start);
        gap: 1rem;
        margin: 1rem 0;
        .sidebar_item_skeleton {
          height: 4rem;
          width: 100%;
        }
        @include respond(tablet) {
          flex-direction: row;
        }
      }
      .sidebar_items {
        height: 100%;
        @include flex(column, flex-start, flex-start);
        gap: 1rem;
        margin: 1rem 0;

        .sidebar_item {
          height: 4rem;
          padding: 0.625rem 0.75rem;
          @include font(400, $normal);
          @include flex(row, center, flex-start);
          gap: 0.5rem;
          transition: 0.3s ease-in-out;
          width: 100%;
          cursor: pointer;
          border-radius: 0.25rem;
          @include respond(tablet) {
            width: max-content;
            white-space: nowrap;
          }
          .sidebar_icon {
            height: 2rem;
            width: 2rem;
            @include flex(row, center, center);
          }
        }
        .selected {
          @extend .sidebar_item;
          background: $sidebarItembg;
          color: $primary_blue;
        }
        .sidebar_items_wrapper {
          height: calc(100% - 8rem);
          overflow-y: auto;
          @include scrollbar;
          @include respond(tablet) {
            @include flex(row, stretch, flex-start);
            gap: 1rem;
            overflow-x: auto;
            width: calc(100vw - 2rem);
            &::-webkit-scrollbar {
              display: none;
            }
          }
          .category_name {
            width: 100%;
            @include flex(row, center, space-between);
          }
        }
        button {
          margin: 0 auto;
          @include respond(tablet) {
            margin: unset;
          }
        }
      }
    }
    .network_content {
      height: 100%;
      width: 100%;
      .request_reference {
        @include flex(row, center, flex-start);
        background: #fff;
        padding: 1rem;
        border-bottom: 1px solid $slate200;

        @include respond(mobile) {
          @include flex(column, flex-start, center);
          gap: 1rem;
        }
        button {
          margin-left: auto;
        }
        .reference_navbar {
          @include flex(row, center, flex-start);
          gap: 0.5rem;
          .ref_title {
            @include font(400, $normal);
            color: $slate500;
            cursor: pointer;
          }
          .ref_value {
            @extend .ref_title;
            color: $slate900;
          }
        }
        .alert_navbar {
          margin-left: auto;
        }
      }
      .network_content_wrapper {
        height: 100%;
        padding: 1.6rem;

        // credit references
        .topics_wrapper {
          .topics_skeleton_wrapper {
            display: grid;
            grid-auto-rows: 5rem;
            grid-row-gap: 1rem;
          }
          .topics_list_wrapper {
            max-height: 55rem;
            overflow-y: auto;
            @include scrollbar;
            @extend .topics_skeleton_wrapper;
            grid-auto-rows: auto;
            .topic_content_item {
              background: #fff;
              border-radius: 0.25rem;
              border: 1px solid $slate200;
              padding: 1rem;
              cursor: pointer;

              .subject_time_wrapper {
                @include flex(row, center, space-between);
                @include respond(mobile) {
                  @include flex(column, flex-start, center);
                }
                .subject {
                  @include font(600, $normal);
                  font-weight: 600;
                }
                .time_date {
                  @include font(500, $small);
                  color: $slate500;
                  @include flex(row, center, flex-start);
                  gap: 1rem;
                  @include respond(mobile) {
                    gap: 0.5rem;
                    margin-left: auto;
                  }
                  .divider {
                    @extend .event_misc_divider;
                    @include respond(mobile) {
                      display: block;
                    }
                  }
                }
                .alert_on_submitted_by {
                  @include flex(row, center, flex-start);
                  gap: 1rem;
                  flex-wrap: wrap;
                  .alert_on,
                  .submitted_by {
                    @include flex(row, center, flex-start);
                    gap: 0.5rem;
                    .event_title {
                      font-size: $small;
                    }
                  }
                  .divider {
                    @extend .event_divider;
                  }
                }
              }
              .type_details {
                margin-top: 0.5rem;
                @include flex(row, center, space-between);
                .type {
                  @include flex(row, center, flex-start);
                  gap: 1rem;
                  .event_title {
                    font-size: $small;
                  }
                }
                .details {
                  cursor: pointer;
                  @include flex(row, center, flex-start);
                  gap: 0.5rem;
                  span {
                    @include font(500, $normal);
                    color: $slate700;
                  }
                  svg {
                    transform: rotate(180deg);
                    font-size: $normal;
                  }
                  .down {
                    transition: 0.3s ease-in-out;
                  }
                  .up {
                    @extend .down;
                    transform: rotate(0deg);
                  }
                }
              }
              .topic_content_details {
                @include flex(row, center, flex-start);
                padding-top: 0.25rem;
                gap: 4rem;
                flex-wrap: wrap;
                @include respond(mobile) {
                  gap: 1.5rem;
                }
                .title_value {
                  @extend .topic_content_details;
                  gap: 1rem;

                  .title {
                    @include font(500, $small);
                    color: $slate500;
                  }
                  .value {
                    @extend .title;
                    color: $slate900;
                    white-space: nowrap;
                  }
                }
              }
              // category topics
              .topic_details {
                width: 100%;
                .topic_title {
                  @include font(600, $normal);
                  color: $slate900;
                }
                .category_topic_content_details {
                  .category_topic_one {
                    @include flex(row, center, flex-start);
                    gap: 0.5rem;
                    .title {
                      @extend .event_title;
                      font-size: $small !important;
                    }
                    .value {
                      @extend .event_value;
                      color: $slate700 !important;
                    }
                    .message_date {
                      &::before {
                        content: '';
                        height: 0.5rem;
                        width: 0.5rem;
                        background: $slate200;
                        border-radius: 50%;
                        margin-right: 0.5rem;
                        display: inline-block;
                      }
                    }
                    .divider {
                      @extend .event_divider;
                    }
                  }
                }
              }
              .topic_action {
                cursor: pointer;
              }
            }
            .category_topic_item {
              @include flex(row, center, space-between);
              gap: 1rem;
            }
            .flash_alert_item {
              cursor: default;
            }
            .no_credit_ref {
              text-align: center;
              @include font(500, $normal);
              color: $slate500;
            }
            .alert_details_container {
              background: $slate50;
              .alert_details_wrapper {
                border: 1px solid $slate100;
                transition: max-height 0.5s;
                max-height: 0;
                overflow: hidden;
                .event_basic_details {
                  margin: 1rem 1rem 0 1rem;
                }
                .event_other_details {
                  margin: 0 1rem;
                }
                .event_comments {
                  margin: 0 1rem 1rem 1rem;
                }
              }
              .show_alert_details {
                @extend .alert_details_wrapper;
                max-height: 100rem;
              }
              .hide_alert_details {
                @extend .show_alert_details;
                max-height: 0;
                overflow: hidden;
                border: 1px solid #fff;
              }
            }
          }
        }
        // reference details
        .reference_details_wrapper {
          .reference_skeleton_wrapper {
            display: grid;
            grid-auto-rows: 5rem;
            grid-row-gap: 1rem;
          }
          .reference_details_content_wrapper {
            height: 55rem;
            overflow-y: auto;
            .reference_details {
              @include flex(column, flex-start, center);
              .ref_section_one,
              .ref_section_two,
              .ref_section_three {
                @include flex(row, center, flex-start);
                gap: 1rem;
                flex-wrap: wrap;
              }
              border: 1px solid $slate200;
              padding: 1rem;
              background: #fff;
              border-radius: 0.25rem;
              gap: 1rem;
              flex-wrap: wrap;

              .title_value {
                @include flex(row, center, flex-start);
                gap: 1rem;
                .title {
                  @include font(500, $small);
                  color: $slate500;
                }
                .value {
                  @extend .title;
                  color: $slate900;
                }
                .requested_on {
                  font-weight: 600;
                }
              }
              .comment {
                @extend .title_value;
              }
              .ref_divider {
                @extend .event_divider;
              }
            }
            .reference_submitted_on {
              @include flex(row, center, flex-start);
              gap: 1rem;
              padding: 1rem 0;
              .date_title {
                @include font(500, $small);
                color: $slate500;
              }
              .date_value {
                @extend .date_title;
                color: $slate900;
              }
            }

            .reference_content_details {
              .event_basic_details {
                grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
                .event_title_value {
                  .event_title {
                    font-size: $small;
                  }
                }
              }
              .event_other_details {
                grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
                .event_title_value {
                  .event_title {
                    font-size: $small;
                  }
                }
              }
              .ref_from_members {
                @include font(500, $small);
                color: $primary_blue;
                padding: 1rem 0;
              }
            }
          }
        }
      }
      .topic_chats_container {
        background: #fff;
      }
      .topic_chats_wrapper {
        height: 100%;
        position: relative;
        .topic_chats_messages_wrapper {
          display: grid;
          grid-auto-rows: 6rem;
          grid-row-gap: 1rem;
          overflow-y: auto;
          @include scrollbar;
          .topic_chat_message_item {
            position: relative;
            @include flex(row, center, flex-start);
            height: max-content;
            background: $slate100;
            @include respond(mobile) {
              height: max-content;
            }

            .topic_chat_details_container {
              width: calc(100% - 4rem);
              padding: 1rem;
              border-radius: 0.25rem;
              .topic_chat_info {
                margin-bottom: 0.5em;
                @include flex(row, center, flex-start);
                flex-wrap: wrap;
                gap: 1rem;
                .user_name,
                .company_name {
                  @include font(600, $small);
                  color: $primary_blue;
                }
                .current {
                  color: $success;
                }
                .user_name {
                  &::after {
                    content: '';
                    height: 0.5rem;
                    width: 0.5rem;
                    background: $slate200;
                    border-radius: 50%;
                    vertical-align: middle;
                    margin-left: 1rem;
                    display: inline-block;
                    &:hover {
                      background: red;
                    }
                  }
                }

                .on,
                .date {
                  @include font(500, $small);
                  color: $slate400;
                }
                .date {
                  color: $slate500;
                }
              }
              .topic_chat_message {
                @include font(500, $small);
                color: $slate800;
              }
            }
            .topic_chat_action {
              svg {
                cursor: pointer;
                rect {
                  transition: 0.3s ease-in-out;
                  &:hover {
                    fill: $slate300;
                  }
                }
              }
            }
            .topic_action_wrapper {
              position: absolute;
              right: 1rem;
              height: 6rem;
              width: 10rem;
              bottom: -5rem;
              z-index: 9999;
              background: #fff;
              visibility: hidden;
              border-radius: 0.25rem;
              border: 1px solid $slate200;
              box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.15);
              @include respond(mobile) {
                bottom: -3.5rem;
              }
              .chat_action {
                height: 3rem;
                @include flex(row, center, center);
                gap: 1rem;
                transition: background 0.2s;
                color: $slate700;
                @include font(400, $small);
                &:hover {
                  background: $slate100;
                  cursor: pointer;
                }
              }
            }
            .topic_action_visible {
              @extend .topic_action_wrapper;
              visibility: visible;
            }
          }
        }

        .react_editor_wrapper {
          border: 1px solid $slate200;
          position: absolute;
          bottom: 5.5rem;
          z-index: 9;
          width: 100%;
          background: #fff;
          .demo-editor {
            max-height: 30rem;
            overflow-y: auto;
            @include scrollbar;
          }
          .send_btn {
            margin-left: auto;
            width: max-content;
            @include flex(row, center, center);
            height: 3rem;
            width: 3rem;
            border-radius: 0.25rem;
          }
        }
      }
    }
  }
}

.event_basic_details,
.event_other_details,
.event_comments {
  border: 2px solid blue;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));

  .event_title_value {
    background: #fff;
    border: 1px solid $slate200;
    padding: 1rem;
    border-radius: 0.25rem;
  }
}
.event_title {
  @include font(500, $normal);
  color: $slate500;
}
.event_value {
  @include font(500, $small);
  color: $slate900;
}
.event_other_details {
  grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  .event_title_value {
    background: unset;
    border-top: none;
  }
}
.event_comments {
  grid-template-columns: 1fr;
  .event_title_value {
    border-top: none;
  }
}

.event_divider {
  border: 1px solid $slate200;
  height: 1.8rem;
  @include respond(mobile) {
    display: none;
  }
}

// groups
.groups_members_wrapper {
  padding: 1.6rem;
  .group_skeleton_wrapper {
    display: grid;
    grid-template-rows: repeat(10, 5rem);
    grid-row-gap: 1rem;
  }
  .members_wrapper {
    .total_members {
      color: $slate700;
      @include font(400, $normal);
      .member_count {
        color: $primary_blue;
        @include font(600, $normal);
      }
    }
  }
  .members_list_wrapper {
    margin-top: 2rem;
    .member_item {
      margin-bottom: 1rem;
      background: #fff;
      padding: 1.6rem 1.6rem 0 1.6rem;
      border: 1px solid $slate200;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &:hover {
        background: $slate50;
      }
      .member_item_wrapper {
        @include flex(row, center, space-between);
        border-radius: 0.25rem;

        .member_details {
          .member_company {
            color: $slate900;
            @include font(600, $normal);
          }
          .member_since {
            color: $slate500;
            @include font(500, $small);
            .value {
              color: $slate900;
              margin-left: 1rem;
            }
          }
        }
        .member_action {
          cursor: pointer;
          @include flex(row, center, flex-start);
          gap: 1rem;
          .event_icon_down {
            height: 1.4rem;
            font-size: $small;
            svg {
              transition: 0.3s ease-in-out;
            }
          }
          .event_icon_up {
            @extend .event_icon_down;
            svg {
              transform: rotate(180deg);
            }
          }
          .member_title {
            color: $slate700;
            @include font(500, $small);
          }
        }
      }
      &:last-of-type {
        margin-bottom: 0;
      }
      .member_users_wrapper {
        max-height: 0;
        transition: 0.3s ease-in-out;
        visibility: hidden;
        opacity: 0;
        margin-top: 1rem;
        .member_table_header {
          background: $dark;
          color: #fff;
          @include font(500, $small);
          display: grid;
          grid-template-columns: 0.5fr 0.5fr 0.5fr 1fr;
          gap: 1rem;
          padding: 1rem;
          border-radius: 0.25rem;
          .header_item {
            border-right: 1px solid $secondary_blue;
            &:last-of-type {
              border-right: none;
            }
          }
        }
        .member_table_values {
          background: none;
          color: $primary_black;
          .header_item {
            border-right: none;
          }
        }
      }
      .members_expanded {
        @extend .member_users_wrapper;
        max-height: 100rem;
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

// database
.database_wrapper {
  .db_filters {
    display: grid;
    grid-template-columns: 1fr 40rem 40rem;
    grid-gap: 1rem;
    background: #fff;
    padding: 1.6rem;
    @include respond(mobile) {
      grid-template-columns: 1fr;
    }
    .db_search {
      .select_container {
        width: 30rem;
        height: 4rem;
        .custom_select {
          height: 4rem;
        }
        @include respond(mobile) {
          width: 100%;
        }
      }
    }
    .db_selects,
    .db_date {
      @include flex(row, center, flex-start);
      gap: 1rem;
      @include respond(mobile) {
        flex-wrap: wrap;
      }
    }
    .db_date {
      .date_range {
        white-space: nowrap;
        @include font(500, $small);
        color: $slate500;
      }
    }
  }
  .db_listing_wrapper {
    .db_info {
      padding: 1.6rem;
      @include flex(row, center, flex-start);
      gap: 0.5rem;
      .db_results {
        @include font(700, $normal);
        color: $slate900;
      }
      .db_for_subject {
        color: $slate700;
        @include font(400, $normal);
      }
      .db_subject {
        color: $primary_blue;
        @include font(700, $normal);
      }
    }
    .db_listing {
      max-height: 52rem;
      overflow-y: auto;
      @include scrollbar;
      padding: 1.6rem;
      .db_list {
        border: 1px solid $slate200;
        border-radius: 0.25rem;
        background: #fff;
        margin-bottom: 2rem;
        &:last-of-type {
          margin-bottom: 0;
        }
        .db_list_item_info {
          padding: 1rem;
          @include flex(row, center, flex-start);
          @include font(600, $normal);
          gap: 0.5rem;
          flex-wrap: wrap;
          .db_from {
            text-transform: lowercase;
          }
          .db_date,
          .db_reference,
          .db_from_value,
          .db_subdmitted_by_value {
            color: $primary_blue;
          }
          .db_from,
          .db_submitted_by {
            font-weight: 500;
          }
          .circle {
            height: 0.5rem;
            width: 0.5rem;
            border-radius: 50%;
            background: $slate300;
          }
        }
      }
    }
    .db_no_data_wrapper {
      height: 55rem;
      @include flex(column, center, center);
      gap: 1rem;
      color: $slate700;
      .no_data_to_show {
        @include font(600, $normal);
      }
      .db_subject_no {
        @include font(400, $small);
      }
    }
  }
}
