.primary {
  background: $primary_blue;
  color: #fff;
  padding: 1rem;
  border-radius: 0.4rem;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  white-space: nowrap;
  font-size: $normal;

  &:hover {
    background: $hover_color;
  }
  &:active {
    transform: scale(0.95);
    background: $primary_blue;
  }
  &:disabled {
    background: $light_15;
    cursor: not-allowed !important;
    pointer-events: none;
    color: $grey_5;
    border: 1px solid $grey_6;
  }
  @include flex(row, center, center);
}

.secondary {
  @extend .primary;
  background: $blue200;
  color: $primary_blue;
  border: 1px solid $primary_blue;
  svg {
    fill: $primary_blue;
  }
  &:hover {
    background: $blue_2;
    color: $primary_blue;
    svg {
      fill: $primary_blue;
    }
  }
}

.tertiary {
  @extend .primary;
  background: $brightOrange;
  color: #fff;
  &:hover {
    background: $brightOrange;
  }
}
.danger {
  @extend .primary;
  background: $red50;
  color: $red700;
  border: 1px solid $red700;
  &:hover {
    background: $red100;
  }
}

.quaternary {
  @extend .primary;
  background: $disabled;
  color: $slate900;
  &:hover {
    background: $disabled;
  }
}
.five {
  @extend .primary;
  background: $slate100;
  color: $slate700;
  &:hover {
    background: $slate200;
  }
}

.six {
  @extend .secondary;
  background: $green50;
  color: $green700;
  border: 1px solid $green700;
  &:hover {
    background: $ember_6;
    color: $green700;
  }
}

.seven {
  @extend .primary;
  background: #fff;
  border: 1px solid $light_24;
  color: $primary_black;
  &:hover {
    background: #fff;
  }
}

.button_icon_wrapper {
  width: 100%;
  @include flex(row, center, center);
  gap: 1rem;
}
