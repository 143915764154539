.input_component {
  box-sizing: border-box;
  @include flex(column, stretch, flex-start);
  gap: 0.8rem;
  width: 30rem;

  .input_label {
    padding-left: 0.4rem;
    @include font(500, $small);
    color: $slate700;
  }

  .input_group_wrapper {
    display: flex;
    border-radius: 0.6rem;
    border: 1px solid;
    border-color: $slate200;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
    background: #fff;
    transition: 0.2s ease-out;
    cursor: text;
    overflow: hidden;

    &:focus-within {
      border-color: $primary_blue;
      outline: 0.3rem solid $blue_8;
    }

    &:hover {
      border-color: $primary_blue;
    }

    .input_control {
      height: 4rem;
      padding: 0 1.2rem;
      flex: 1;
      @include font(400, $normal);
      color: $slate900;

      &::placeholder {
        color: $slate400;
        transition: inherit;
      }

      &:hover {
        border-color: $slate300;
        &::placeholder {
          color: $slate500;
        }
      }
    }

    .input_icon_left,
    .input_icon_right {
      width: max-content;
      z-index: 1;

      .input_icon {
        height: 1.6rem;
        width: 1.6rem;
        min-height: 1.6rem;
        min-width: 1.6rem;
        @include flex(row, center, center);
      }
    }

    .input_icon_left {
      @include flex(row, center, flex-end);
      @include font(500, $small);
      padding-left: 1.2rem;
    }

    .input_icon_right {
      @include flex(row, center, flex-start);
      padding-right: 1.2rem;
    }
    .input_close_icon {
      cursor: pointer;
    }
  }
}

// disabled input
.input_component_disabled {
  @extend .input_component;
  .input_group_wrapper {
    pointer-events: none;

    .input_control {
      background: $slate50;
      &::placeholder {
        color: $slate300;
      }
    }
    // checkbox
    .input_checked {
      background: $slate300 !important;
    }
  }
}

// error input
.input_component_error {
  @extend .input_component;
  .input_group_wrapper {
    border-color: $red700;
    background: $red50;
    &:focus-within {
      outline: 3px solid $red100;
      border-color: $red700;
    }
    &:hover {
      border-color: $red700;
    }
  }
}

//checkbox styles
.input_component_checkbox {
  @extend .input_component;
  @include flex(row, center, flex-start);
  width: max-content;

  .input_group_wrapper {
    border-radius: 0.4rem;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);

    &:focus-within {
      outline: 0.1rem solid $blue_8;
    }

    .input_control {
      height: 1.6rem;
      width: 1.6rem;
      padding: 0;
      position: relative;
      cursor: pointer;
    }
    .input_checked {
      background: $primary_blue;
      transition: cubic-bezier(0, 0, 0, 0.1);
      transition-duration: 0.2s;
      @include center;
      &::before {
        content: '';
        position: absolute;
        right: 50%;
        top: 50%;
        width: 0.5rem;
        height: 1rem;
        border: solid #fff;
        border-width: 0 2px 2px 0;
        margin: -1px -1px 0 -1px;
        transform: rotate(45deg) translate(-50%, -50%);
        z-index: 2;
      }
    }
    .input_unchecked {
      @extend .input_checked;
      background: #fff;
      transition: cubic-bezier(0, 0, 0, 0.1);
      transition-duration: 0.2s;
    }
  }
  .input_label_disabled {
    color: $slate300;
  }
}
