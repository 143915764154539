.user_report_wrapper {
  display: grid;
  grid-template-columns: 35rem 30rem 35rem 1fr;
  grid-column-gap: 2rem;
  padding: 1rem;
  .filter_2,
  .filter_3 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
  }

  @include respond(tablet) {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }

  .order_report_button_wrapper {
    @include flex(row, center, flex-end);
  }
}
