.select_container {
  width: 100%;
  position: relative;

  .select_wrapper {
    width: 100%;
    position: relative;
    transition: all 0.3s;
    font-size: $normal;

    .custom_select {
      width: 100%;
      outline: none;
      color: #000;
      margin: 0;
      height: 5rem;
      padding-left: 1rem;
    }
    .select_label {
      position: absolute;
      top: 50%;
      left: 1.25rem;
      transform: translate(0, -50%);
      transition: all 0.2s;
      pointer-events: none;
      font-size: $normal;
      .select_disabled {
        color: $light_23;
      }
      .mandatory {
        color: $dark_1;
        font-weight: 500;
      }
      .recommended {
        @extend .mandatory;
        color: $success;
      }
    }
    .fieldset_wrapper {
      border-radius: 0.4rem;
      position: absolute;
      pointer-events: none;
      top: -0.5rem;
      bottom: 0;
      left: 0;
      right: 0;
      margin: 0;
      transition: all 0.2s;
      border-width: 1.5px;
      border-style: solid;
      background: transparent;
      border-color: $slate200;

      .legend_wrapper {
        white-space: nowrap;
        font-size: $small;
        max-width: 0;
        visibility: hidden;
        padding: 0;
        opacity: 0;
        transition: all 0.1s;
        height: 1rem;
      }
    }
    .select_disabled {
      color: $light_23 !important;
      cursor: not-allowed !important;
    }
    .custom_select:hover ~ .fieldset_wrapper {
      border-color: $slate800;
    }
    .select_disabled:hover ~ .fieldset_wrapper {
      border-color: $slate200;
    }
    .custom_select:focus ~ .fieldset_wrapper {
      border-color: $primary_blue;
      .legend_wrapper {
        max-width: 100%;
        padding: 0 0.5rem;
      }
    }
    .custom_select:focus ~ .select_label {
      font-size: $small;
      top: 0;
      color: $primary_blue;
      padding: 0 0.5rem;
    }
    .custom_select:not(:placeholder-shown) ~ .select_label {
      top: 0;
      font-size: $small;
      padding: 0 0.5rem;
    }
    .custom_select:not(:placeholder-shown) ~ .fieldset_wrapper {
      .legend_wrapper {
        max-width: 100%;
        padding: 0 0.5rem;
      }
    }
    .select_error:is(:placeholder-shown) ~ .fieldset_wrapper {
      border-color: $red_3 !important;
    }
    .select_error:focus ~ .fieldset_wrapper {
      border-color: $primary_blue !important;
      .legend_wrapper {
        max-width: 100%;
        padding: 0 0.5rem;
      }
    }
  }

  .select_options_wrapper {
    white-space: nowrap;
    min-width: 100%;
    border-radius: 0.4rem;
    background: #fff;
    z-index: 3;
    position: absolute;
    margin-top: 0.35rem;
    font-size: $normal;
    display: grid;
    grid-auto-rows: 4rem;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.2s;
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;

    .select_options {
      padding: 0 1rem;
      display: none;
      &:hover {
        cursor: pointer;
        background: $primary_blue;
        color: #fff;
      }
    }

    .highlighted {
      background: #e0e0e0;
    }
    .checkbox_label_wrapper {
      width: 100%;
      height: 100%;
      @include flex(row, center, flex-start);
      gap: 1rem;
    }
    .select_message {
      color: $primary_blue;
      @include font(500, $small);
      @include flex(row, center, flex-start);
      padding-left: 1rem;
    }
  }
  .showOptions {
    transition: opacity 0.2s, visibility 0.2s;
    @extend .select_options_wrapper;
    opacity: 1;
    visibility: visible;
    padding: 0.5rem 0;
    max-height: 40rem;
    overflow-y: auto;
    &::-webkit-scrollbar {
      display: none;
    }

    .select_options {
      @include flex(row, center, flex-start);
    }
  }
  .select_icon {
    position: absolute;
    left: 96%;
    top: 33.33%;
    transform: translate(-96%, -33.33%);
    font-size: $normal;
    color: rgba(0, 0, 0, 0.54);
  }
  .select_disabled {
    color: $light_23;
  }
  .select_count {
    @extend .select_icon;
    left: 80%;
    height: 1.75rem;
    width: 1.75rem;
    border-radius: 50%;
    outline: 1px solid;
    @include flex(row, center, center);
    background: $dark;
    color: #fff;

    @include font(500, $small);
  }
  .select_error_message {
    height: 2rem;
    margin: 0.5rem 0.25rem !important;

    @include font(500, $small);
    color: $danger;
  }
}

.table_field_wrapper {
  height: 4rem;
  @extend .select_container;
  .table_input_select {
    @extend .table_input;
    width: calc(100% - 2rem);
    position: relative;
    color: $dark;

    font-weight: 500;
    height: 4rem;
  }
  .select_display_text {
    position: absolute;
    font-size: $normal;
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }
}

.table_select_wrapper {
  width: 100%;

  .select_icon {
    top: unset;
    top: 50%;
    transform: translate(-96%, -50%);
  }
}
