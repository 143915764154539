.table3_container {
  @include font(500, $normal);
  background: $slate100;
  margin: 0 auto;
  padding: 1.6rem 1.6rem 0 1.6rem;
  width: 100%;
  overflow-x: scroll;
  @include scrollbar;

  .table3_wrapper {
    min-width: max-content;
    .table_header_wrapper {
      display: grid;
      grid-column-gap: 1rem;
      border-top-left-radius: 0.4rem;
      border-top-right-radius: 0.4rem;
      background: $dark;
      height: 5rem;
      color: #fff;
      padding: 1rem;

      .table_header_item {
        position: relative;
        border-right: 1px solid $secondary_blue;
        @include flex(row, center, flex-start);

        .table_header_item_text {
          font-size: $normal;
        }

        .sortable,
        .locked {
          margin-left: 1rem;
          height: 100%;
          @include flex(row, center, center);
          svg {
            cursor: pointer;
          }
        }

        .resizer3 {
          position: absolute;
          width: 1rem;
          top: 0;
          right: 0;
          bottom: 0;
          display: block;
          border-right: 1px dashed transparent;
          &:hover {
            cursor: col-resize;
          }
        }
        .resizer3_active {
          @extend .resizer3;
          border-color: $secondary_blue;
        }
      }
    }

    .table_content_wrapper {
      max-width: 100%;
      overflow: auto;
      @include scrollbar;
      // &::-webkit-scrollbar {
      //   display: none;
      // }

      .table_content_row {
        display: grid;
        grid-gap: 1rem;
        background: #fff;
        padding: 0.6rem 1rem;
        font-weight: 400;
        border-bottom: 0.15rem solid $lightgrey;
        border-left: 0.15rem solid $lightgrey;
        border-right: 0.15rem solid $lightgrey;

        .table_content_item {
          border-right: 1px solid $slightgrey;
          padding-right: 0.5rem;
          @include flex(row, center, flex-start);
          span {
            @include ellipsis;
          }
          .credit_report_action_wrapper {
            height: 100%;
            width: 100%;
            cursor: pointer;
            @include flex(row, center, center);
          }
          .report_tracking_icons_wrapper {
            @include flex(row, center, flex-start);

            flex-wrap: wrap;
            gap: 1rem;
            img {
              height: 1.75rem;
            }
          }
          .company_user_wrapper {
            @include flex(column, flex-start, flex-start);
            .company_name {
              color: $secondary_blue;
              @include font(400, $small);
            }
          }
          .credit_application_subject {
            span {
              &:nth-child(3) {
                color: $secondary_blue;
                @include font(400, $small);
              }
            }
          }
          .event_subject_name {
            @include flex(row, center, flex-start);
            gap: 1rem;
            label {
              height: 1.6rem;
            }
          }
          .report_owned {
            background: $success;
            color: #fff;

            @include font(500, $very-small);
            border-radius: 0.2rem;
            padding: 0.4rem;
            margin-left: 1rem;
          }
          .user_credit_report_status {
            background: $dullgrey;
            border-radius: 0.4rem;
            padding: 0.5rem;
            .user_credit_status_box {
              @include font(500, $small);
              @include flex(row, center, flex-start);
              img,
              span {
                border: 1px solid $lightgrey;
                border-radius: 0.4rem;
                background: #fff;
              }
              span {
                padding: 0.7rem 1rem;
              }
              .final_report {
                background: $success;
                color: #fff;
                cursor: pointer;
                @include flex(row, center, center);
                gap: 0.5rem;
              }
            }
          }
          .legal_active {
            background: $success;
            color: #fff;
            border-radius: 0.4rem;
            padding: 0.6rem 1.2rem;
            @include font(500, $small);
          }
          .legal_inactive {
            @extend .legal_active;
            background: $error;
          }
          .watchlist_actions_wrapper,
          .admin_client_btns_wrapper {
            @include flex(row, center, space-between);
            gap: 1rem;
            button {
              padding: 0.75rem 1.2rem;
              @include font(500, $normal);
            }
          }
          .admin_client_users {
            @include flex(row, center, flex-start);
            gap: 1rem;
            button {
              width: 12rem !important;
            }
          }
          .legal_watchlist_name {
            @include flex(row, center, flex-start);
            gap: 0.8rem;
          }
          .aging_btns_wrapper {
            .aging_btns {
              @extend .admin_client_btns_wrapper;
            }
          }
          .credit_application_btns_wrapper {
            @extend .admin_client_users;
          }
          .group_btns_wrapper {
            @extend .admin_client_btns_wrapper;
          }

          .esc_data_btns {
            @extend .admin_client_btns_wrapper;
            button {
              width: max-content !important;
            }
          }

          .admin_credit_btns_wrapper {
            @include flex(row, center, flex-start);
            gap: 1rem;
            button {
              background: #fff;
              border: 1.5px solid $lightgrey;
              padding: 0.8rem;
              cursor: pointer;
              border-radius: 0.4rem;
              @include font(500, $small);
              color: $secondary_blue;
            }
            .more_actions_wrapper {
              height: 3.2rem;
              width: 3.2rem;
              @include flex(row, center, center);
              background: $lightgrey;
              border-radius: 0.4rem;
              cursor: pointer;
            }
          }

          .credit_risk_actions {
            width: 100%;
            @include flex(row, center, flex-end);
            gap: 1rem;
            button {
              padding: 0.8rem 1.2rem;
              min-width: 12rem;
            }
            svg {
              cursor: pointer;
            }
            .expand_icon {
              transition: 0.3s ease-in-out;
            }
            .expand_active {
              @extend .expand_icon;
              transform: rotate(180deg);
            }

            .processing {
              color: $ember600;
            }
          }

          .credit_application_id_wrapper {
            @include flex(column, flex-start, center);
            padding: 0.5rem 0;

            .credit_ref_number {
              @include font(400, $very-small);
              .ref_text {
                color: $slate500;
              }
            }
          }
          .transaction_item {
            @include flex(column, flex-start, flex-start);
            .transaction_key_value {
              width: 100%;
              @include ellipsis;
            }
            .transaction_value {
              @include font(500, $normal);
              color: $primary_black;
            }
            .transaction_key {
              @include font(400, $small);
              color: $slate500;
            }
            .transaction_completed_date {
              margin-top: 0.5rem;
            }
          }
          .transaction_ordered_reports {
            .transaction_report {
              @include font(500, $small);
              color: $slate500;
            }
          }
        }
        .duplicate_group_meeting_subject {
          @extend .table_content_item;
          color: $slate300;
          .company_user_wrapper {
            .company_name {
              color: $slate300;
            }
          }
          input {
            cursor: not-allowed !important;
          }
        }

        &:hover {
          background: $lightgrey;
        }
      }
      .loading_state {
        @extend .table_content_row;
        &:hover {
          background: #fff;
        }
      }
      .first_row {
        @extend .table_content_row;
        border-top: 0.15rem solid $lightgrey;
        border-radius: 0;
      }
      .last_row {
        @extend .table_content_row;
        border-radius: 0 0 0.4rem 0.4rem;
      }
      .odd_row {
        background: $light_3;
      }

      .table_expanded_view {
        transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out,
          visibility 0.2s ease-in-out;
        overflow: hidden;
      }
      .expanded {
        @extend .table_expanded_view;
        max-height: 100rem;
        visibility: visible;
        opacity: 1;
        // expanded view css

        .credit_risk_expanded_view_wrapper {
          padding: 0 1.2rem 1.2rem 1.2rem;
          border: 1px solid $slate200;

          .expanded_view_actions {
            padding: 1rem 0;
            @include flex(row, center, flex-end);
            gap: 1rem;
            button {
              padding: 0.8rem 1.2rem;
            }
          }
        }
      }

      .shrinked {
        @extend .expanded;
        max-height: 0;
        visibility: hidden;
        opacity: 0;
        padding: 0;
      }
      .table_no_data_wrapper {
        height: 100%;
        @include flex(column, center, center);
        gap: 1rem;
        .no_data {
          color: $secondary_blue;
        }
      }
    }

    .credit_risk_client_list,
    .credit_risk_historical_data {
      @extend .table_content_wrapper;
      height: 65rem !important;
      color: $slate900;
      .client_id,
      .legal_name,
      .subject_name {
        @include font(500, $small);
      }
      .reveal_animation {
        animation-name: reveal, colorshift;
        animation-duration: 1000ms, 5000ms;
        animation-timing-function: ease-in-out, ease-in-out;
        animation-fill-mode: forwards;
      }
      .client_name {
        position: relative;
        width: 100%;
        @include font(600, $small);
        @include flex(row, center, flex-start);

        overflow: visible !important;
        gap: 0.8rem;
        svg {
          min-height: 1.6rem;
          min-width: 1.6rem;
        }
        .client_icon {
          @include flex(row, center, center);
          position: relative;
          min-width: max-content;
        }
        button {
          padding: 0;
          border: none;
        }
        .button_loading_secondary {
          background: transparent !important;
          &::after {
            border: 0.25rem solid $ember600;
            border-top: 0.25rem solid transparent;
          }
        }
      }
      .name_over_limit {
        color: $slate400;
      }

      .current_credit_limit {
        @include flex(row, center, flex-start);
        gap: 1.2rem;
      }
      .Pending_row {
        background: $slate50;
        .legal_name,
        .client_name {
          color: $slate400;
        }
        .credit_risk_actions {
          color: $slate500;
        }
      }
      .Error_row,
      .Pending_row {
        background: $ember50;
      }
      .Limit_row {
        background: $slate50;
      }
      .score_expired,
      .risk_expired {
        .score_expired_icon {
          margin-right: 0.4rem;
        }
        @include flex(row, center, center);
        .credit_risk_locked {
          margin-left: 0.8rem;
        }
      }
    }
    .credit_risk_historical_data {
      height: 48rem !important;
    }

    .credit_risk_available_reports,
    .credit_risk_owned_reports {
      .report_type {
        background: $slate100;
        border-radius: 0.4rem;
        padding: 0.8rem;
        @include font(500, $small);
        color: $slate900;
        margin-right: 1rem;
      }
    }

    .legal_subject_list {
      height: calc(100vh - 30rem);
    }
    .user_database_report {
      color: $primary_black;
      span {
        @include font(500, $normal);
      }
      @extend .table_content_wrapper;
      .database_report_subject {
        label {
          @include flex(row, center, flex-start);
          gap: 1rem;
          cursor: pointer;
          .company_name {
            .subject_name {
              @include font(500, $normal);
              color: $primary_black;
            }
            .original_name {
              color: $dark;
              @include font(400, $small);
              font-style: italic;
            }
          }
        }
      }
    }
    .credit_report {
      @extend .table_content_row;
      .Completed_row {
        outline: 1px solid $success;
        border-radius: 0.4rem;
      }
    }
  }
}

@keyframes reveal {
  from {
    filter: blur(4px);
    opacity: 0;
  }
  to {
    filter: blur(0);
    opacity: 100%;
  }
}
@keyframes colorshift {
  0% {
    opacity: 100%;
  }
  10% {
    opacity: 25%;
  }
  20% {
    opacity: 100%;
  }
  30% {
    opacity: 25%;
  }
  40% {
    opacity: 100%;
  }
  50% {
    opacity: 25%;
  }
  60% {
    opacity: 100%;
  }
  70% {
    opacity: 25%;
  }
  80% {
    opacity: 100%;
  }
  90% {
    opacity: 25%;
  }
  100% {
    opacity: 100%;
  }
}
