@mixin respond($breakpoint) {
  @if $breakpoint== mobile {
    @media (max-width: 37.5em) {
      //600px
      @content;
    }
  }
  @if $breakpoint== tablet {
    @media (max-width: 64em) {
      // 1024px
      @content;
    }
  }
  @if $breakpoint == desktop {
    @media (max-width: 80em) {
      //1280px
      @content;
    }
  }
}

@mixin flex($direction, $alignItems, $justifyContent) {
  display: flex;
  flex-direction: $direction;
  align-items: $alignItems;
  justify-content: $justifyContent;
}

@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin font($weight, $size) {
  font-weight: $weight;
  font-size: $size;
}

@mixin scrollbar {
  &::-webkit-scrollbar {
    width: 0.5rem;
    height: 0.5rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $lightgrey;
    border-radius: 200rem;
  }
  &::-webkit-scrollbar-thumb {
    background-color: $primary_blue;
    border-radius: 1.2rem;
  }
}

@mixin center {
  display: grid;
  place-items: center;
}
