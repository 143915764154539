.login_wrapper {
  height: 100vh;
  font-size: $normal;

  @include flex(row, center, center);

  .login_form,
  .login_banner {
    width: 50%;
    height: 100%;
    @include respond(mobile) {
      width: 100%;
    }
  }

  .login_banner {
    background: url('../images/bg_login.jpg');
    background-size: cover;
    background-position: center center;

    .message_logo_wrapper {
      height: 100%;
      padding: clamp(2rem, 4vw, 8rem);
      @include flex(column, flex-start, space-between);

      .message_wrapper {
        font-size: $big;
        color: #fff;

        .message_small {
          font-weight: 500;
          line-height: 2.4rem;
          margin-bottom: 3rem;
        }

        .message_big {
          font-weight: 600;
          font-size: clamp(3rem, 4.25vw, 7rem);
        }
      }
    }
    @include respond(tablet) {
      display: none;
    }
  }

  .login_form {
    padding-top: 10rem;
    @include flex(column, center, flex-start);
    @include respond(mobile) {
      @include flex(column, center, flex-start);
      margin-top: 2rem;
      padding-top: unset;
    }

    .logo_message_wrapper {
      padding: 1rem;
      display: grid;
      grid-template-rows: 10rem 4rem auto;
      width: 40rem;
      @include respond(mobile) {
        width: 30rem;
      }

      .login_message {
        color: $primary_black;
        @include font(600, $big);
      }

      .welcome_message {
        color: #6d738d;
        font-weight: 400;
        font-family: 'Inter';

        .user_email {
          color: $secondary_blue;
          font-weight: 600;
        }
      }
    }

    .login_form_wrapper {
      display: grid;
      grid-auto-rows: auto;
      padding: 1rem;
      width: 40rem;
      @include respond(mobile) {
        width: 30rem;
      }

      .forgot_password {
        text-align: center;
        color: $primary_blue;
        cursor: pointer;
        text-decoration: underline;
        font-weight: 500;
        margin-top: 2rem;
      }
    }

    .email_confirmation_wrapper {
      @extend .login_form_wrapper;
      .resend_text {
        color: $secondary_blue;
        @include font(400, $small);
      }
      button {
        margin: 0.5rem 0;
      }
      .resend_timer {
        color: $success;
        font-size: $small;
      }
    }
    .password_rules_wrapper {
      margin-bottom: 1rem;
      @include font(500, $small);
      .rule_heading {
        color: $primary_black;
      }

      .rule {
        @include flex(row, center, flex-start);
        gap: 0.5rem;
        color: $secondary_blue;

        .rule_success {
          color: $success;
        }
        .rule_failure {
          color: $error;
        }
        .rule_disabled {
          color: $disabled;
        }
      }
    }
  }

  .copyright {
    position: fixed;
    left: 3.5rem;
    bottom: 3.5rem;
    @include font(400, $small);
    color: $secondary_blue;
    @include respond(mobile) {
      left: unset;
    }
  }
  @include respond(mobile) {
    height: calc(100vh - 2rem);
  }
}
