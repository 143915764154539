.modal_backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s ease-in-out;
}
.modal_visible {
  @extend .modal_backdrop;
  background: rgba(0, 0, 0, 0.25);
  opacity: 1;
  visibility: visible;
  z-index: 9;

  .modal_container {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 0.8rem;
    filter: drop-shadow(0 0.8rem 5rem rgba(0, 0, 0, 0.1));

    .modal_content {
      padding: 3.2rem;
      @include respond(mobile) {
        padding: 1.2rem;
      }

      .modal_gap {
        display: grid;
        grid-auto-rows: auto;
        grid-row-gap: 1.5rem;
      }

      // credit report update status
      .report_status_update_modal {
        @extend .modal_gap;
        .existing_status {
          width: max-content;
          font-size: $small;
        }

        .status_wrapper {
          display: grid;
          grid-auto-flow: row;
          grid-row-gap: 1.25rem;

          .status_content {
            label {
              @include flex(row, center, flex-start);
              gap: 1rem;
              span {
                @include font(400, $normal);
                color: $primary_black;
              }
            }
          }
        }
      }
      // edit bot data
      .report_bot_data_modal {
        @extend .modal_gap;
      }
      // tracking icons
      .report_tracking_icons_modal {
        padding-right: 1rem;
        max-height: 40rem;
        overflow-y: scroll;
        @include scrollbar;
        @extend .modal_gap;

        .tracking_icon_wrapper {
          .icon_category {
            margin-bottom: 1.2rem;
            @include font(500, $normal);
            color: $primary_black;
          }
          .icons_wrapper {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(10rem, 10.1rem));
            grid-gap: 1rem;

            .icon_content {
              padding: 1.2rem;
              @include flex(column, center, center);
              background: $lightgrey;
              border-radius: 0.4rem;
              border: 1.5px solid transparent;
              transition: 0.3s ease-in-out;
              cursor: pointer;
              .icon_image {
                height: 2.4rem;
                width: 2.4rem;
                @include flex(row, center, center);
              }
              .icon_name {
                @include font(400, $small);
                margin-top: 1rem;
              }
            }
            .selected_icon {
              @extend .icon_content;
              border: 1.5px solid $primary_blue;
              background: $sidebarItembg;
            }
          }
        }
      }

      //edit price
      .report_edit_price_modal,
      .group_form_wrapper,
      .credit_form_wrapper,
      .credit_application_resend_email,
      .create_meeting_wrapper,
      .mark_duplicate_wrapper,
      .send_fax_wrapper,
      .credit_risk_order_report_wrapper {
        @extend .modal_gap;
      }
      .credit_risk_discover_content {
        @include font(400, $normal);
        color: $slate900;
      }
    }

    .modal_header,
    .modal_footer {
      background: $dullgrey;
      padding: 1.6rem;
      height: 7rem;
      @include flex(row, center, space-between);
    }
    .modal_header {
      border-bottom: 1px solid $lightgrey;
      border-radius: 0.8rem 0.8rem 0 0;
      color: $primary_black;
      @include font(500, $normal);
      img {
        cursor: pointer;
      }
    }
    .modal_footer {
      border-top: 1px solid $lightgrey;
      border-radius: 0 0 0.8rem 0.8rem;
      gap: 1rem;
      button {
        padding: 1.2rem 2.4rem;
        width: 18rem;
        font-weight: 500;
      }
    }
    .modal_actions_left {
      @extend .modal_footer;
      justify-content: flex-end;
      @include respond(mobile) {
        justify-content: unset;
      }
      button {
        width: auto;
      }
    }
  }
  // status update
  .credit_report_status_update_modal,
  .credit_report_edit_price_modal,
  .message_board,
  .esc_data,
  .credit_risk {
    @extend .modal_container;
    width: 40rem;
    @include respond(mobile) {
      width: 35rem;
    }
  }
  .admin_client_upload_aging {
    @extend .modal_container;
    width: 35rem;
  }
  //edit bot data
  .credit_report_edit_bot_data_modal,
  .credit_report_tracking_icon_modal {
    @extend .modal_container;
    width: 55rem;
    @include respond(mobile) {
      width: 35rem;
    }
  }
  .send_credit_application,
  .admin_groups,
  .credit_application_resend_email,
  .client_groups_meetings_event_subjects {
    @extend .modal_container;
    width: 50rem;
    @include respond(mobile) {
      width: 35rem;
    }
  }
  .credit_application_order_report,
  .last_resort_option,
  .credit_risk_order_report {
    @extend .modal_container;
    width: 71rem;
    @include respond(mobile) {
      width: 35rem;
    }
  }
  .credit_risk_overlimit {
    @extend .modal_container;
    width: 80rem;
    @include respond(mobile) {
      width: 35rem;
    }
  }
}

.modal_hidden {
  @extend.modal_visible;
  visibility: hidden;
  opacity: 0;
}
