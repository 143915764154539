.side_drawer_wrapper {
  background: rgba(0, 0, 0, 0.25);
  transition: 0.4s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.side_drawer_visible {
  visibility: visible;
  opacity: 1;
  @include flex(row, flex-start, flex-end);
  z-index: 10;

  .side_drawer_container {
    background: #fff;
    height: 100%;
    display: grid;
    grid-template-rows: 6% 81% 10%;
    grid-row-gap: 1rem;
    position: absolute;
    right: -100%;
    transition: 0.4s ease-in-out;
    .side_drawer_header {
      color: $primary_black;
      @include font(600, $medium_big);
      @include flex(row, center, center);
      padding: 0.75rem 1.5rem;
    }
    .side_drawer_content {
      padding: 0.75rem 1.5rem;
      overflow-y: auto;
      z-index: 0;
      @include scrollbar;
    }
    .side_drawer_footer {
      @include flex(row, center, center);
      background: $greywhite;
      border-top: $lightgreywhite;
      border-radius: 0.8rem 0.8rem 0 0;
      gap: 1.5rem;
      padding: 1rem 0;
      button {
        width: 14rem;
        @include font(500, $normal);
        @include respond(mobile) {
          width: unset !important;
          font-size: $small;
        }
      }
    }
  }
  .footer_hidden {
    @extend .side_drawer_container;
    grid-template-rows: 6% 1fr;
  }
  .side_container_visible {
    @extend .side_drawer_container;
    right: 0;
  }
}
.side_drawer_invisible {
  @extend .side_drawer_visible;
  visibility: hidden;
  opacity: 0;
  transition: 0.7s ease-in-out;
}
.generic_drawer {
  @extend .side_drawer_visible;
  .side_drawer_container {
    .side_drawer_header {
      color: $primary_blue;
      justify-content: flex-start;
    }
    .side_drawer_content {
      background: $slate50;
    }
  }
}
.credit_risk_arrange_columns {
  .side_drawer_container {
    width: 50rem;
    @include respond(tablet) {
      width: 100%;
    }
  }
}
.my_account_edit_info,
.add_user {
  .side_drawer_container {
    width: 60rem;
    @include respond(tablet) {
      width: 100%;
    }
  }
}
.group_create_meetings {
  .side_drawer_container {
    width: 65rem;
  }
}
.credit_risk {
  .side_drawer_container {
    width: 65rem;
    @include respond(tablet) {
      width: 50rem;
    }
    @include respond(mobile) {
      width: 100%;
    }
    .side_drawer_content {
      background: #fff;
    }
    .side_drawer_header {
      justify-content: space-between;

      .credit_risk_navigation {
        @include flex(row, center, flex-start);
        gap: 1rem;
        svg {
          &:nth-child(1) {
            cursor: pointer;
          }
        }
        span {
          &:nth-child(2) {
            color: $slate500;
            cursor: pointer;
          }
          &:nth-child(4) {
            color: $slate900;
          }
        }

        .credit_risk_navs {
          @include font(500, $normal);
        }
      }
    }
  }
}
.credit_risk_add_client {
  .side_drawer_container {
    width: 85%;
    @include respond(mobile) {
      width: 100%;
    }
  }
}

.group_create_subject {
  .side_drawer_container {
    width: 75%;
    @include respond(tablet) {
      width: 100%;
    }
  }
  .side_drawer_footer {
    button {
      width: 18rem !important;
    }
  }
}
