.select_component {
  width: max-content;

  .options_component {
    margin-top: 0.8rem;
    max-height: 0;
    width: 30rem;

    transform: scale(0.95);
    opacity: 0;
    visibility: hidden;
    transition: 0.1s ease-out;
    border: 1px solid $slate200;
    border-radius: 0.6rem;
    background: #fff;
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.05);
    position: fixed;
    z-index: 2;

    .options_label_component {
      width: 100%;
      padding: 0.8rem;
    }

    .select_top_arrow,
    .select_bottom_arrow {
      height: 1.6rem;
      width: 100%;
      @include center;
      background: #fff;
      position: absolute;
      transition: 0.1s ease-out;
      opacity: 0;
    }
  }
  .show_options {
    @extend .options_component;
    transform: scale(1);
    opacity: 1;
    visibility: visible;
    max-height: max-content;

    .select_top_arrow {
      top: 0;
    }

    .select_bottom_arrow {
      bottom: 0;
    }

    .show_select_top_arrow {
      @extend .select_top_arrow;
      opacity: 1;
    }

    .hide_select_top_arrow {
      @extend .show_select_top_arrow;
      opacity: 0;
      pointer-events: none;
    }

    .show_select_bottom_arrow {
      @extend .select_bottom_arrow;
      opacity: 1;
    }
    .hide_select_bottom_arrow {
      @extend .show_select_bottom_arrow;
      opacity: 0;
      pointer-events: none;
    }

    .options_label_component {
      max-height: 28.5rem;
      @include flex(column, flex-start, flex-start);
      overflow-y: auto;

      &::-webkit-scrollbar {
        scrollbar-width: none;
      }

      .option_label {
        padding: 0.6rem 0.8rem;
        color: $slate400;
        @include font(600, $normal);
        width: 100%;
        height: 3.2rem;
        border-radius: 0.4rem;
        transition: 0.2s ease-out;
        cursor: pointer;
      }

      .option_focus_in {
        @extend .option_label;
        background: $slate50;
        color: $slate900;
      }

      .option_focus_out {
        @extend .option_focus_in;
        background: #fff;
        color: $slate400;
      }

      .option_no_result {
        width: 100%;
        padding: 0 1.2rem;
        @include flex(row, center, flex-start);
        gap: 0.8rem;
        .no_result_text {
          @include font(400, $normal);
          color: $slate500;
        }
      }
    }
  }
  .hide_options {
    @extend .show_options;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s ease-out;
    transform: scale(0.95);
    max-height: 0;
  }
  .input_icon_right {
    svg {
      transition: 0.2s ease-out;
    }
  }
}
.select_component_active {
  @extend .select_component;
  .input_icon_right {
    svg {
      transform: rotate(180deg);
    }
  }
}
