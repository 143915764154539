.credit_risk_summary_wrapper {
  padding: 1rem;
  @include flex(column, center, center);
  height: 100%;

  .health_heading {
    @include font(600, $normal);
    color: $slate700;
    margin-top: 4rem;
  }
  .health_sub_heading {
    @include font(400, $small);
    color: $slate500;
    margin-top: 1rem;
    text-align: center;
  }

  .btns_wrapper {
    @include flex(row, center, center);
    gap: 2rem;
    margin-top: 2rem;
    button {
      padding: 0.8rem 1.6rem;
    }
  }
}

.no_credit_risk_clients {
  height: 100%;
  .secondary {
    border: none;
    background: transparent;
  }
}

.credit_risk_header {
  padding: 1.6rem;
  .title_actions {
    @include flex(row, center, space-between);
    gap: 1rem;
    @include respond(tablet) {
      flex-wrap: wrap;
    }
    .title {
      @include font(600, $normal_big);
      .package_name {
        border-radius: 0.6rem;
        background: $blue200;
        padding: 0.8rem;
        margin: 0 1.2rem;
        @include font(700, $small);
        color: $primary_blue;
      }
      .package_validity {
        @include font(500, $small);
        color: $slate400;
      }
    }
    .actions {
      display: grid;
      grid-template-columns: 30rem 1fr;
      grid-gap: 1rem;
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
      .select_error_message {
        display: none;
      }
      .label {
        @include font(500, $normal);
        color: $slate500;
      }
    }
  }
}

.health_dashboard_wrapper {
  overflow-y: scroll;
  scrollbar-width: none;
  @include scrollbar;
  .credit_risk_basic_wrapper {
    .health_section_wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1.6rem;
      padding: 0 1.6rem;

      @include respond(tablet) {
        grid-template-columns: 1fr;
      }

      .payments_info {
        @include flex(column, stretch, flex-start);
      }

      .general_info,
      .client_health,
      .payments_info,
      .alerts_wrapper {
        .label {
          @include font(500, $small);
          color: $slate500;
          margin-bottom: 1.2rem;
          height: 1.7rem;
          @include flex(row, center, flex-start);
        }

        .general_section {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          gap: 1rem;
          @include respond(mobile) {
            grid-template-columns: 1fr;
          }
        }
        .client_section {
          @extend .general_section;
          grid-template-columns: repeat(4, 1fr);
          @include respond(mobile) {
            grid-template-columns: repeat(2, 1fr);
          }
        }
        .payments_section {
          margin-bottom: 1.2rem;

          @extend .general_section;
          grid-template-columns: repeat(3, 1fr);
          @include respond(mobile) {
            grid-template-columns: 1fr;
          }
        }
      }

      .summary_alerts_loading {
        height: 24rem;
        margin-top: 3.2rem;
      }
      &:last-child {
        padding: 0 1.6rem 1.6rem;
      }
    }

    .credit_risk_fixed_wrapper {
      padding: 0;
      transition: 0.1s ease-in-out;
      outline: 1px solid $slate100;
      box-shadow: 0 16px 16px rgba(0, 0, 0, 0.02);
      @include respond(mobile) {
        display: none;
      }
      @extend .health_section_wrapper;
      position: sticky;
      top: 0;
      background: #fff;
      z-index: 2;
      padding: 1.2rem;

      .general_info,
      .client_health {
        .health_card {
          height: auto;
          @include flex(row, center, space-between);
          gap: 1rem;
          .health_card_text {
            margin-top: 0;
            @include flex(row, center, flex-start);
            gap: 0.8rem;
          }
        }
      }
    }
    .credit_risk_fixed_wrapper_out_of_view {
      @extend .credit_risk_fixed_wrapper;
      visibility: visible;
      opacity: 1;
      max-height: max-content;
    }

    .credit_risk_fixed_wrapper_in_view {
      @extend .credit_risk_fixed_wrapper;
      visibility: hidden;
      opacity: 0;
      max-height: 0;
    }

    .subscription_wrapper {
      @include flex(column, center, center);
      margin-top: 4rem;
      .title {
        margin: 4rem 0 1.6rem 0;
        @include font(600, $normal_big);
        color: $slate600;
      }
      .text {
        @include font(400, $normal);
        color: $slate500;
      }
      .subscription_content {
        margin-top: 4rem;
        max-width: 85rem;
        border-radius: 0.8rem;
        background: $slate50;
        padding: 2.4rem;
        .header {
          margin: 1.6rem 0;
          @include font(600, $normal);
          color: $slate700;
        }
        .content_section {
          display: grid;
          grid-template-columns: auto 1fr;
          grid-gap: 1.6rem;
          &:not(:last-child) {
            margin-bottom: 1.6rem;
          }
          .image {
            @include flex(row, center, center);
          }
          .content {
            .content_title {
              @include font(600, $normal);
              color: $primary_blue;
              margin-bottom: 0.6rem;
            }
            .content_subtitle {
              @include font(400, $small);
              color: $slate500;
            }
          }
        }
      }
    }
  }
  .subscription_container {
    background: #fff;
  }

  .client_loading_wrapper {
    background: #fff;
    padding: 1rem;
    display: grid;
    grid-template-rows: repeat(4, 1fr);
    grid-row-gap: 1rem;

    div {
      height: 4.5rem;
      &:nth-child(2n + 2) {
        width: 50%;
      }
    }
  }

  .credit_risk_client_wrapper {
    padding: 1.6rem 1.6rem 0 1.6rem;

    .credit_risk_client_filters {
      background: $slate900;
      border-radius: 0.8rem;
      padding: 0 0.8rem;
      height: 4.8rem;
      @include flex(row, center, space-between);

      .action_info,
      .action_description {
        @include flex(row, center, flex-start);
        gap: 1rem;
        button {
          padding: 0.8rem 1.2rem;
        }
        .action_text,
        .action_description_text {
          @include font(500, $normal);
          color: #fff;
        }
        .risk_info_loading {
          background: $slate100;
          width: 32rem;
          height: 1.8rem;
          padding: 0.8rem;
          border-radius: 0.4rem;
        }
      }
    }
    .list_action_wrapper {
      @include flex(row, center, flex-start);
      gap: 1.2rem;
      flex-wrap: wrap;

      .list_action {
        border-radius: 0.8rem;
        padding: 0.8rem;
        @include font(500, $small);
        letter-spacing: 0.5px;
        @include flex(row, center, flex-start);
        flex-wrap: wrap;
        gap: 0.8rem;
        height: 5.2rem;
        white-space: nowrap;
        position: relative;
        button {
          padding: 0.8rem 1.2rem;
          @include font(500, $small);
          color: $secondary_foreground;
          width: auto !important;
        }
        .dollar_icon {
          position: absolute;
          top: -0.6rem;
          right: -0.6rem;
        }
        .list_user_action {
          position: relative;
        }
        .text {
          font-weight: 700;
        }
      }
      .list_action_processing {
        @extend .list_action;
        background: $ember50;
        color: $ember600;
        .list_action_processing_icon {
          cursor: pointer;
        }
      }
      .list_action_High {
        @extend .list_action;
        background: $red50;
        color: $slate800;
      }
      .list_action_Medium {
        @extend .list_action;
        background: $ember50;
        color: $ember600;
      }
      .list_action_Low {
        @extend .list_action;
        background: $green50;
        color: $green700;
      }
      .list_action_Minimal {
        @extend .list_action;
        background: $blue200;
        color: $primary_blue;
      }
      .list_action_not_found {
        @extend .list_action;
        background: $slate200;
        color: $slate800;
      }
    }
    .credit_risk_list_actions_loading {
      height: 6rem;
      @include flex(row, center, flex-start);
      gap: 1rem;
    }
  }
  .credit_risk_clients_loading {
    display: grid;
    grid-auto-rows: 5.4rem;
    grid-gap: 1rem;
    .skeleton_wrapper {
      &:nth-of-type(1) {
        width: 75%;
      }
      &:nth-of-type(2),
      &:nth-of-type(4) {
        width: 95%;

        width: 85%;
      }
      &:nth-of-type(3) {
        width: 50%;
      }
    }
  }

  .credit_risk_client_filters {
    .credit_risk_filters_loading {
      height: 4.1rem;
      width: 100%;
    }
  }
}

.health_actions {
  @include flex(row, center, flex-start);
  gap: 1rem;
  @include respond(mobile) {
    flex-wrap: wrap;
  }
  button {
    padding: 0.8rem 1.6rem;
  }
}

.credit_risk_sidedrawer_wrapper {
  .health_upload_aging_add_client_wrapper {
    .health_companies_wrapper {
      margin-bottom: 2.4rem;
    }
    .health_upload_aging_file_wrapper {
      .title {
        @include font(500, $normal);
        color: $slate700;
      }
      .sub_title {
        @include font(400, $small);
        color: $red_7;
        margin: 0.5rem 0;
      }
      .uptodateby {
        @include font(500, $small);
        color: $slate700;
        margin-bottom: 1rem;
      }
    }
    .health_year_month_wrapper {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 2rem;
      margin-bottom: 1rem;
    }

    .health_download_sample_aging {
      height: 3.2rem;
      padding: 0.8rem 1.2rem;
      @include flex(row, center, center);
      margin: 0.5rem 0;
      @include font(500, $normal);
      color: $text_foreground;
      cursor: pointer;
    }

    button {
      width: calc(100% - 2rem) !important;
    }
    .or_section {
      color: $slate400;
      @include font(500, $small);
      @include flex(row, center, center);
      margin: 2rem 0;
      &::before,
      &::after {
        content: '';
        flex: 1;
        border-bottom: 1px solid $slate100;
        margin: 0 1rem;
      }
    }
    .confirmation_section {
      @include flex(column, center, center);

      .confirmation_title {
        @include font(600, $normal_big);
        color: $slate800;
        margin: 4rem 0 1.6rem 0;
        @include respond(mobile) {
          font-size: $normal;
        }
      }
      .confirmation_sub_title {
        @include font(400, $normal);
        color: $slate600;
        margin-bottom: 2.4rem;
      }
    }
    .confirmation_divider {
      margin-bottom: 1.6rem;
    }
  }
  .max_limit_warning {
    margin-top: 2.4rem;
    @include font(500, $normal);
    color: $red700;
  }
}

.health_add_client_form_wrapper {
  .add_client_form {
    .health_title {
      @include font(500, $normal);
      color: $slate900;
      margin: 1rem 0;
    }
    .first_section {
      display: grid;
      gap: 1rem;
      grid-template-columns: repeat(2, 1fr);
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }
    .second_section {
      @extend .first_section;
      grid-template-columns: repeat(3, 1fr);
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }
    .third_section {
      @extend .first_section;
      grid-template-columns: repeat(4, 1fr);
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
    }
    .fourth_section {
      @extend .first_section;
      grid-template-columns: repeat(5, 1fr);
      @include respond(mobile) {
        grid-template-columns: 1fr;
      }
      .input_icon {
        left: 88%;
      }
    }
    .fifth_section {
      @include flex(row, center, space-between);
      flex-wrap: wrap;

      .currency_term_section {
        @include flex(row, center, flex-start);
        gap: 5rem;
        flex-wrap: wrap;
        @include respond(mobile) {
          gap: 1.5rem;
        }

        .period_section {
          white-space: nowrap;
          @include flex(row, center, flex-start);
          gap: 1rem;
        }
      }
      .total_due_section {
        .total_due {
          @include font(400, $normal);
          color: $slate700;
        }
        .total_amount {
          @include font(600, $normal);
          color: $slate900;
        }
      }
    }
    .sixth_section {
      margin-top: 1.5rem;
    }
    hr {
      border: 1px solid $slate100;
      margin-bottom: 2rem;
    }
  }
}

.health_card {
  height: 8.6rem;
  border-radius: 0.8rem;
  background: #fff;
  padding: 1.6rem;
  border: 1px solid $slate100;

  .health_card_title {
    @include font(500, $normal);
    color: $slate900;
    @include flex(row, center, flex-start);
    gap: 1rem;

    .title_wrapper {
      position: relative;
      width: 100%;
      @include flex(row, center, space-between);

      .credit_risk_locked_wrapper {
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .health_card_text {
    @include flex(row, center, space-between);
    margin-top: 0.8rem;

    .health_text {
      @include font(700, $normal_big);
      color: $slate900;
      @include flex(row, center, flex-start);
      gap: 1.2rem;

      .health_text_parts {
        span {
          &:nth-child(2) {
            @include font(500, $small);
            color: $slate400;
          }
        }
      }
    }
    .health_percentage_wrapper {
      padding: 0.4rem;
      background: $green50;
      border-radius: 0.4rem;
      @include flex(row, center, flex-start);
      gap: 0.4rem;

      .percent_text {
        @include font(700, $very-small);
        color: $green700;
      }
    }
    .health_multiple_text {
      width: 100%;
      @include flex(row, center, space-between);
      .health_text_one {
        @include flex(row, center, flex-start);
        gap: 0.8rem;
        color: $red700;
        span {
          &:nth-child(1) {
            @include font(400, $small);
          }
          &:nth-child(2) {
            @include font(700, $normal_big);
          }
        }
      }
    }
  }
}

.health_card_loading {
  @extend .health_card;
  padding: 0;
}

.arrange_column_wrapper {
  .label {
    @include font(500, $normal);
    color: $red_7;
  }
  .arrange_columns {
    margin-top: 1rem;

    .arrange_column {
      display: grid;
      grid-template-columns: auto auto 1fr auto;
      grid-gap: 1rem;
      align-items: center;
      cursor: move;
      width: 100%;
      padding: 0.8rem;
      border-radius: 0.4rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      margin-bottom: 0.4rem;

      @include font(500, $normal);
      color: $slate500;

      .column_check {
        &::before {
          position: relative;
        }
      }
    }
  }
}

.company_profile_wrapper {
  @include flex(column, stretch, flex-start);
  height: calc(100vh - 6rem);
  overflow-y: auto;
  @include scrollbar;

  .profile_navigation {
    position: sticky;
    top: 7.367rem;
    border-bottom: 1px solid $slate100;
    z-index: 4;

    min-height: 6.5rem;
    background: #fff;
    @include flex(row, center, flex-start);
    gap: 1rem;
    .skeleton_wrapper {
      width: 15rem;
      height: 4rem;
    }
    .profile_navigation_wrapper {
      @include flex(row, center, flex-start);
      padding: 1.2rem 1.6rem;

      @include respond(mobile) {
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }
      }

      .profile_navigation_tab {
        padding: 0.6rem 1.6rem;
        border-radius: 0.4rem;
        color: $red_7;
        @include font(500, $normal);
        transition: background 0.2s;
        cursor: pointer;
        white-space: nowrap;
        @include flex(row, center, flex-start);
        gap: 1rem;

        .risk {
          padding: 0.4rem;
        }
      }
      .news_feed {
        pointer-events: none;
      }
      .profile_nav_active {
        @extend .profile_navigation_tab;
        background: $primary_blue;
        color: #fff;
      }
    }
  }
  .credit_risk_profile_wrapper {
    padding: 1.6rem;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.6rem;
    @include respond(tablet) {
      grid-template-columns: 1fr;
    }

    .credit_risk_section_one {
      .client_information {
        height: 20rem;
        padding: 1.6rem;
        background: #fff;
        border-radius: 0.8rem;
        border: 1px solid $slate100;
        display: grid;
        grid-auto-rows: auto;
        grid-row-gap: 1.2rem;
        @include respond(tablet) {
          height: auto;
        }

        .name {
          @include flex(row, center, flex-start);
          gap: 1.2rem;
          @include respond(mobile) {
            @include flex(column, flex-start, center);
            gap: 0.8rem;
          }
        }

        .line-divider {
          background-color: $slate100;
        }

        .risk_score_wrapper {
          @include flex(row, center, flex-start);
          gap: 1.2rem;

          .risk_type,
          .score {
            @include flex(row, center, flex-start);
            gap: 0.8rem;
            .risk {
              font-weight: 500;
            }
          }
        }

        .last_updated {
          align-self: flex-end;
          margin-left: auto;
          span {
            @include font(600, $very-small);
            color: $slate700;
            &:nth-child(1) {
              @include font(400, $very-small);
              color: $slate500;
            }
          }
        }

        .label {
          @include font(500, $normal);
          color: $slate500;
        }
        .label_value {
          @include font(700, $small);
          @include respond(mobile) {
            font-size: $normal;
          }
          .entity_expired {
            @include flex(row, center, flex-start);
            gap: 0.8rem;
          }
        }
        .transaction_information {
          @include flex(row, center, flex-start);
          .health_card {
            border: none;
            padding: 0;
            height: 4.6rem;
          }
          @include respond(mobile) {
            flex-direction: column;
            align-items: flex-start;
            gap: 0.8rem;
          }
          .vertical_divider {
            border: 1px solid $slate100;
            height: 4.6rem;
            margin: 0 1.6rem;
            @include respond(mobile) {
              display: none;
            }
          }
        }
        hr {
          border: 1px solid $slate100;
        }
        .client_info_loading {
          display: grid;
          grid-template-rows: repeat(3, 1fr);
          grid-row-gap: 1rem;
          .skeleton_wrapper {
            &:nth-child(2) {
              width: 50%;
            }
          }
        }
      }
    }
  }

  .company_profile_content {
    padding: 1.6rem;

    .credit_risk_title {
      margin: 2rem 0;
      @include font(500, $normal);
      color: $primary_blue;
    }

    .credit_risk_company_details_wrapper {
      overflow-y: auto;
      @include scrollbar;
      padding-right: 0.5rem;

      .company_actions {
        margin-bottom: 1.2rem;
        &:not(:first-child) {
          margin-top: 1.2rem;
        }
        @include flex(row, center, space-between);
        .upto {
          @include font(600, $normal);
          color: $primary_blue;
        }
        button {
          padding: 0.8rem 1.2rem;
        }
      }
      .show_previous_data {
        margin-top: 1.6rem;
        border-radius: 0.6rem;
        background: $slate100;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
        @include flex(row, center, center);
        padding: 0.8rem 1.2rem;
        .show_data {
          cursor: pointer;
          gap: 0.8rem;
          @include font(400, $normal);
          color: $secondary_foreground;
          margin-right: 0.8rem;
        }
      }
      .show_less {
        @extend .show_previous_data;
        svg {
          transform: rotate(180deg);
        }
      }
    }

    .credit_risk_corporate_details_wrapper,
    .legal_details_wrapper {
      position: relative;

      .credit_risk_corporate_details,
      .credit_risk_legal_details {
        height: calc(100% - 1rem);
        @include scrollbar;
        overflow-y: auto;
      }

      .corporate_actions_wrapper {
        padding: 0.8rem 1.2rem;
        background: #fff;
        border-bottom: 1px solid $slate100;
        margin: -1.6rem 0 1.6rem 0;
        @include flex(row, center, flex-end);
        .corporate_actions {
          @include flex(row, center, flex-start);
          gap: 1rem;
          .action {
            position: relative;
            button {
              padding: 0.8rem 1.2rem;
            }
            .dollar_icon {
              position: absolute;
              top: -0.6rem;
              right: -0.6rem;
            }
          }

          .last_update {
            @include font(500, $small);
            color: $slate900;
            span {
              &:first-child {
                font-weight: 400;
                color: $slate500;
              }
            }
          }
        }
      }

      .details_locked_wrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        @include flex(row, flex-start, center);

        background: linear-gradient(
          180deg,
          rgba(248, 250, 252, 0) 0%,
          $slate50 100%
        );

        .details_locked_content {
          @include flex(column, center, center);
          gap: 1.6rem;

          .details_locked {
            position: relative;
            background: #fff;
            border: 1px solid $light_24;
            border-radius: 0.6rem;
            padding: 0.8rem 3.2rem;
            box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
            margin-top: 1.6rem;
            @include flex(row, center, flex-start);
            gap: 0.8rem;
            cursor: pointer;

            .details_locked_icon {
              position: absolute;
              top: -0.6rem;
              right: -0.5rem;
            }
            .locked_title {
              @include font(500, $normal);
              color: $secondary_foreground;
            }
          }

          .details_locked_text {
            @include font(500, $small);
            color: $slate500;
          }
        }
      }
      .locked_empty_state {
        margin: -1.6rem -1.6rem 0 -1.6rem;
        svg {
          width: 100%;
        }
      }
    }

    .company_information_loading {
      display: grid;
      grid-template-rows: auto;
      grid-row-gap: 1rem;
      div {
        height: 4.5rem;
        &:nth-child(odd) {
          width: 50%;
        }
      }
    }

    .legal_details_wrapper,
    .credit_risk_corporate_details {
      .summary_wrapper {
        border: 1px solid $slate200;
        border-radius: 0.8rem;
        max-width: 100%;
        overflow-x: auto;
        &::-webkit-scrollbar {
          display: none;
        }

        .summary_list_header {
          background: $slate800;
          div {
            border-bottom: none;
            border-right: 1px solid $slate600 !important;
            color: $slate300;
            &:last-child {
              border-right: none;
            }
          }
        }

        .summary_header,
        .summary_details {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          @include respond(mobile) {
            grid-template-columns: repeat(4, 15rem);
          }
          div {
            padding: 0.8rem 1.2rem;
            border-right: 1px solid $slate200;
            border-bottom: 1px solid $slate200;
          }
        }

        .summary_header {
          @include font(400, $small);
          color: $slate600;
        }
        .summary_details {
          @extend .summary_header;
          @include font(500, $small);
          color: $slate700;
          &:nth-child(2n) {
            background: #fff;
          }
          .last_item {
            border-bottom: none;
          }
        }
        .last_item {
          @extend .summary_details;
          div {
            border-bottom: none;
          }
        }
      }
      .civil_records,
      .bankruptcy {
        @extend .summary_wrapper;
        .summary_header,
        .summary_details {
          grid-template-columns: repeat(6, 1fr);
          @include respond(mobile) {
            grid-template-columns: 8rem 25rem 25rem 10rem 25rem 15rem;
          }
        }
        .summary_details {
          &:last-child {
            div {
              border-bottom: none;
            }
          }
        }
      }
      .other_names {
        @extend .bankruptcy;
        .summary_header,
        .summary_details {
          grid-template-columns: repeat(3, 1fr);
          .shareholder_name {
            .majority {
              @include font(500, $very-small);
              color: #fff;
              background: $primary_blue;
              padding: 0.4rem;
              border-radius: 0.2rem;
              margin-left: 1rem;
            }
          }
          .name_status {
            @include flex(row, center, flex-start);
            gap: 0.4rem;
          }
        }
      }
      .address {
        @extend .other_names;
        .summary_header,
        .summary_details {
          grid-template-columns: repeat(2, 1fr);
          @include respond(mobile) {
            grid-template-columns: 10rem 1fr;
          }
        }
      }
      .administrators {
        @extend .other_names;
        .summary_header,
        .summary_details {
          grid-template-columns: repeat(4, 1fr);
        }
      }
      .construction_internal {
        border-radius: 0;
        padding: 1.2rem 1.2rem 0 1.2rem;
        background: $slate50;
        margin-bottom: 2rem;
        .summary_list_header {
          grid-template-columns: repeat(3, 1fr);
          border-top-left-radius: 0.8rem;
          border-top-right-radius: 0.8rem;
          div {
            &:last-child {
              border-right: none !important;
            }
          }
        }
        .summary_details {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    .credit_risk_activities_wrapper {
      .activities_container {
        max-height: 36rem;
        @include scrollbar;
        overflow-y: auto;
        .activities_wrapper {
          position: relative;
          padding-left: 2rem;
          .activities_title {
            @include font(500, $small);
            margin-bottom: 0.8rem;
            @include flex(row, center, space-between);
            @include respond(mobile) {
              @include flex(column, flex-start, center);
              gap: 1rem;
            }

            .misc_details {
              color: $slate500;
              @include flex(row, center, flex-start);
              gap: 0.8rem;
            }
          }
          .activities_description {
            @include font(500, $very-small);
            color: $slate500;
          }
          hr {
            margin: 1.5rem 0 1.5rem 4rem;
            border: 1px solid $slate100;
          }
          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 25%;
            width: 1rem;
            height: 1rem;
            background-color: $slate900;
            border-radius: 50%;
            transform: translateY(-25%);
          }
        }
        .activity_General {
          @extend .activities_wrapper;
          .activities_title {
            color: $green700;
          }
          &::before {
            background: $green700;
          }
        }
      }
      .activities_actions {
        margin: -1.6rem 0 1.6rem 0;
        padding: 1.2rem;
        background: #fff;
        border-left: 1.5px solid $slate100;
        border-right: 1.5px solid $slate100;
        border-bottom: 1.5px solid $slate100;
        @include flex(row, center, flex-end);
        .lable_checkbox_wrapper {
          padding: 0;
          width: max-content;
          span {
            @include font(500, $normal);
            color: $text_foreground;
          }
        }
      }
    }

    .credit_risk_reports_wrapper {
      .credit_report_actions {
        @include flex(row, center, space-between);
        gap: 1rem;
        flex-wrap: wrap;
        button {
          padding: 0.8rem 1.2rem;
        }

        .report_selector_wrapper {
          padding: 0.8rem;
          width: max-content;
          background: #fff;
          border-radius: 0.6rem;
          border: 1px solid $slate100;
          @include flex(row, center, flex-start);
          .report_selector {
            padding: 0.6rem 1.6rem;
            border-radius: 0.4rem;
            @include font(500, $small);
            color: $red_7;
            transition: 0.3s ease-in-out;
            white-space: nowrap;
            cursor: pointer;
          }
          .report_selected {
            @extend .report_selector;
            color: #fff;
            background: $primary_blue;
          }
        }
      }
    }
    .no_activity_wrapper {
      @include flex(column, center, center);
      @include font(400, $normal);
      color: $slate500;
      gap: 1.6rem;
    }
  }
}

.credit_risk_tooltip {
  .tooltip_wrapper {
    width: 24rem !important;
    .report_status_tooltip {
      &::after {
        left: 10%;
        transform: translateX(-10%);
      }
    }
  }
}

.credit_risk_locked_wrapper {
  @include flex(row, center, flex-start);
  position: relative;
  gap: 1rem;
  .credit_risk_locked_text {
    filter: blur(0.3rem);
  }
  .credit_risk_locked {
    overflow: visible !important;
    background: #fff;
    display: block;
    border: 1px solid $light_24;
    height: 2.4rem;
    width: 2.4rem;
    border-radius: 0.6rem;
    position: relative;
    @include flex(row, center, center);
    cursor: pointer;
    .dollar_lock_icon {
      position: absolute;
      left: 1.5rem;
      bottom: 1.5rem;
    }
  }
}

.alerts_wrapper {
  .alerts_info {
    @include flex(row, flex-start, space-between);
    gap: 1rem;
    @include respond(tablet) {
      flex-wrap: wrap;
    }
    .alert_section_left {
      height: 2.2rem;
      @include flex(row, center, flex-start);
      gap: 0.5rem;
      width: max-content;
      margin-bottom: 0.7rem;
      .label {
        margin: unset !important;
      }

      .alert_count {
        margin: 0 auto;
        @include font(500, $small);
      }
      .alert_new {
        border-radius: 5rem;
        background: $slate900;
        color: #fff;
        @include font(500, $extra-small);
        padding: 0.4rem 0.8rem;
      }
      .show_all {
        padding: 0.6rem 0.8rem;
        cursor: pointer;
        border-radius: 0.6rem;
        transition: 0.3s ease-in-out;
        .text {
          @include font(600, $small);
          color: $slate700;
        }
        svg {
          margin-left: 1.2rem;
        }
        &:hover {
          background: $slate100;
        }
      }
    }
    .alert_section_right {
      @extend .alert_section_left;

      .label {
        @include font(400, $small);
      }

      .alert_options {
        @include flex(row, center, flex-start);
        gap: 0.5rem;
        .alert_option {
          @include font(400, $small);
          border-radius: 2rem;
          padding: 0.4rem 1.2rem;
          transition: 0.3s ease-in-out;
          cursor: pointer;
        }
        .alert_active {
          background: $primary_blue;
          color: #fff;
        }
      }
    }
  }
  .alerts_list {
    display: grid;
    grid-template-rows: auto;
    grid-row-gap: 1.2rem;
    padding: 1.6rem;
    background: #fff;
    border-radius: 0.8rem;
    border: 1px solid $slate100;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.03);
    overflow-y: auto;
    @include scrollbar;
    @include respond(mobile) {
      padding: 1rem;
    }

    .alert_wrapper {
      padding: 2rem;
      border-radius: 0.8rem;
      @include font(400, $small);
      position: relative;
      .alert_text {
        display: grid;
        grid-template-columns: 2rem 1fr;
        margin-bottom: 1.2rem;
        justify-content: center;
        svg {
          margin-top: 0.35rem;
        }
      }

      .alert_action {
        @include flex(row, center, flex-start);
        gap: 1rem;
        button {
          min-width: 8rem;
          padding: 0.8rem 1.2rem;
        }
      }
      .alert_new {
        position: absolute;
        top: -1rem;
        left: -1rem;
        @include font(600, $very-small);
        border-radius: 0.4rem;
        background: $primary_blue;
        color: #fff;
        padding: 0.4rem 0.6rem;
        box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
      }
      .alert_close {
        position: absolute;
        right: 1rem;
        top: 1rem;
        cursor: pointer;
      }
      .timestamp {
        @include flex(row, center, flex-end);
        @include font(500, $very-small);
        color: $timestamp;
        margin-right: -1rem;
        margin-bottom: -1rem;
      }
    }
    .alert_Urgent {
      @extend .alert_wrapper;
      background: $red50;
      border-left: 2px solid $red700;
      color: $red700;
    }
    .alert_Moderate {
      @extend .alert_wrapper;
      background: $ember50;
      border-left: 2px solid $ember700;
      color: $ember700;
    }
    .alert_Minimal {
      @extend .alert_wrapper;
      background: $slate100;
      border-left: 2px solid $slate700;
      color: $slate700;
    }
    .alert_loading {
      padding: 0;
      height: 11.1rem;
    }
    .no_alerts_found {
      height: 24rem;
      @include flex(row, center, center);
      color: $slate300;
      @include font(500, $normal_big);
    }
  }
  .alerts_dashboard {
    @extend .alerts_list;
    max-height: 28.8rem;
  }
}

.historical_navigation_wrapper {
  @include flex(row, center, flex-start);
  gap: 1.6rem;
  padding: 1.6rem;
  background: #fff;

  .historical_nav_loading {
    height: 3.4rem;
    width: 8rem;
    border-radius: 10rem;
    .skeleton_wrapper {
      border-radius: inherit;
    }
  }
  .historical_nav {
    @extend .historical_nav_loading;
    padding: 1.2rem 1.6rem;
    border: 1px solid $slate100;
    background: $slate100;
    @include flex(row, center, center);
    width: max-content;
    cursor: pointer;
    transition: background 0.3s ease-in-out;
    .month {
      @include font(400, $normal);
      color: $slate700;
      margin-right: 0.4rem;
    }
    .count {
      @include font(400, $very-small);
    }
  }
  .nav_selected {
    @extend .historical_nav;
    background: $primary_blue;
    .month,
    .count {
      color: #fff;
    }
  }
}

.credit_risk_order_report_wrapper {
  @include respond(mobile) {
    height: auto;
  }
  .select_container,
  .input_container {
    width: 50%;
    @include respond(mobile) {
      width: 100%;
    }
  }
  .heading_subheading_wrapper {
    flex-direction: column;
  }
  .report_ordered_success_wrapper {
    @include flex(column, center, flex-start);
    .order_success_text {
      margin-top: 4rem;
      color: $slate900;
      @include font(500, $normal_big);
      @include respond(mobile) {
        font-size: $small;
      }
    }
    .order_success_description {
      color: $slate600;
      @include font(400, $normal);
      margin-top: 1.6rem;
    }
    button {
      margin: 2.4rem 0;
    }
  }
  .heading {
    @include font(500, $normal);
    color: $primary_blue;
  }
  .report_information_wrapper {
    margin-top: -1.4rem;
    .report_section {
      @include flex(row, center, flex-start);
      gap: 0.8rem;
      .title {
        @include font(500, $normal);
        color: $slate500;
        @include respond(mobile) {
          font-size: $small;
        }
      }
      .value {
        @include font(600, $normal);
        color: $slate900;
        @include respond(mobile) {
          font-size: $small;
        }
      }
      &:nth-child(1) {
        margin-bottom: 1.6rem;
      }
    }
    .missing_address_wrapper {
      margin-top: 1rem;
      background: $ember50;
      padding: 0.8rem;
      @include flex(row, center, flex-start);
      @include respond(mobile) {
        flex-direction: column;
      }
      .missing_address_section_left {
        .description {
          @include font(600, $small);
          color: $ember600;
        }
        .missing_info {
          @include flex(row, center, flex-start);
          gap: 0.8rem;
          @include respond(mobile) {
            margin: 0.8rem 0;
          }
          .title {
            @include font(400, $small);
            color: $slate900;
          }
          .value {
            @include font(700, $small);
            color: $slate900;
            @include flex(row, center, flex-start);
            flex-wrap: wrap;
          }
        }
        .missing_text {
          span {
            @include font(400, $small);
            color: $slate900;
            &:nth-child(2) {
              font-weight: 700;
            }
          }
        }
      }
      .missing_address_section_right {
        margin-left: auto;
        button {
          padding: 0.8rem 1.2rem;
        }
      }
    }
  }
}
.risk {
  border-radius: 0.4rem;
  padding: 0.8rem;
  @include font(500, $small);
  max-height: 2.5rem;
  @include flex(row, center, center);
}

.risk_High {
  background: $red50;
  color: $red700;
}
.risk_Low {
  background: $green50;
  color: $green700;
}
.risk_Medium {
  background: $ember50;
  color: $ember600;
}
.risk_Minimal {
  background: $blue200;
  color: $primary_blue;
}
.score_High {
  color: $red700;
}
.score_Low {
  color: $green700;
}
.score_Medium {
  color: $ember600;
}
.score_Minimal {
  color: $primary_blue;
}
.expired_Low {
  path {
    fill: $green700;
  }
}

.expired_High {
  path {
    fill: $red700;
  }
}
.expired_Medium {
  path {
    fill: $ember600;
  }
}
.expired_Minimal {
  path {
    fill: $primary_blue;
  }
}

.overlimit_wrapper {
  @include flex(column, center, center);
  .image {
    margin-bottom: 4rem;
  }
  .title {
    @include font(600, $normal_big);
    color: $slate800;
    margin-bottom: 1.6rem;
  }
  .description {
    @include font(400, $normal);
    color: $slate600;
    text-align: center;
  }
}

.graph_section {
  @include flex(row, center, flex-start);
  gap: 2.4rem;
  @include respond(mobile) {
    @include flex(column, stretch, center);
    gap: 1rem;
  }
  height: 100%;
  border: 1px solid $slate100;
  background: #fff;
  border-radius: 0.8rem;

  .graph_label_specific {
    margin-bottom: 0 !important;
    margin-left: 1.6rem;
  }

  .graph_wrapper {
    flex: 1;

    .insufficient_wrapper {
      height: 17.7rem;
      @include flex(column, center, center);

      .row_1 {
        @include flex(row, center, flex-start);
        gap: 0.8rem;
        .unavailable {
          @include font(500, $normal);
          color: $slate500;
        }
      }
      .row_2 {
        margin-top: 0.8rem;
        @include font(400, $small);
        color: $slate400;
        text-align: center;
      }
    }
  }

  .graph_1,
  .graph_2 {
    width: 100%;
    height: 16rem;
  }
}
