.my_account_container {
  height: calc(100vh - 6rem);
  display: grid;
  grid-template-columns: 24rem 1fr;

  .sidebar_layout_wrapper {
    background: #fff;
  }

  @include respond(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: 6rem 1fr;
  }

  .admin_details_wrapper {
    border-right: 1px solid $slate100;
    padding: 2rem;
    background: #fff;

    @include respond(tablet) {
      border-right: unset;
      padding: 1rem;
    }

    .admin_title {
      @include font(600, $normal_big);
      color: $primary_blue;
      margin-bottom: 1rem;
      @include respond(mobile) {
        margin-bottom: 1rem;
      }
    }
    .admin_name_role {
      margin-bottom: 1rem;
      @include flex(column, flex-start, flex-start);
      gap: 1rem;
      .admin_name {
        @include font(600, $big);
        color: $slate800;
        white-space: nowrap;
        @include ellipsis;
        max-width: 30rem;
      }
    }
    .admin_display_title {
      @include font(500, $normal);
      color: $slate700;
      margin-bottom: 1rem;
    }
    .admin_contact {
      border-top: 1px solid $slate100;
      border-bottom: 1px solid $slate100;
      @include font(500, $normal);
      color: $slate700;
      padding: 2rem 0;
      .phone,
      .email {
        @include flex(row, center, flex-start);
        gap: 1rem;
      }
      .phone {
        margin-bottom: 1rem;
      }
      @include respond(mobile) {
        padding: 1rem 0;
      }
    }
    .admin_actions {
      @include flex(row, center, flex-start);
      gap: 1rem;
      margin-top: 2rem;
      @include respond(mobile) {
        margin-top: 1rem;
      }
    }
  }
  .team_listing_wrapper {
    .title_action_wrapper {
      background: #fff;
      border-bottom: 1px solid $slate100;
      padding: 1.6rem;
      @include flex(row, center, space-between);
      @include font(500, $normal);
    }
    @include respond(tablet) {
      overflow-y: auto;
      overflow: hidden;
    }
  }
}

.admin_account_wrapper {
  grid-template-columns: 32rem 1fr;
  @include respond(tablet) {
    grid-template-columns: 1fr;
    grid-template-rows: auto 1fr;
  }
  .admin_details_loading {
    display: grid;
    grid-auto-rows: 4rem;
    grid-gap: 1rem;

    .admin_skeleton {
      &:nth-child(2n + 2) {
        width: 60%;
      }
    }
  }
}

.my_account_wrapper {
  height: 100%;
  background: $accountbg;
  padding: 2rem;

  .account_information_wrapper,
  .preferences_wrapper {
    width: 100%;
    .heading {
      color: $dark;
      @include font(500, $medium);
    }

    .information_wrapper {
      background: #fff;
      height: 21rem;
      margin-top: 2rem;
      box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.025);
      border-radius: 0.8rem;
      padding: 2rem;

      .user_wrapper {
        display: grid;
        grid-template-columns: 8rem 1fr;
        grid-column-gap: 1rem;
        align-items: center;

        .profile_photo {
          height: 7rem;
          width: 7rem;
          border-radius: 50%;
          background: #ddd;
        }

        .name_role {
          display: grid;
          grid-template-rows: 3rem 2rem;
          grid-row-gap: 1rem;
          padding: 0.15rem 0;
          .name_skeleton {
            height: 3rem;
            max-width: 30rem;
          }
          .role_skeleton {
            height: 2rem;
            max-width: 20rem;
          }
          .name {
            @include font(500, $big);
            color: $primary_black;
            margin-bottom: 0.5rem;
            line-height: 3rem;
            max-width: 30rem;
            @include ellipsis;
          }

          .role {
            @include font(500, $normal);
            color: $dark;
            line-height: 2rem;
          }
        }
      }

      .user_details_wrapper {
        display: grid;
        margin-top: 2rem;
        grid-template-columns: 10rem 3fr 1fr;
        grid-column-gap: 1rem;

        .user_lables {
          display: grid;
          grid-row-gap: 0.5rem;
          grid-auto-rows: 2.5rem;
          .role_icon {
            @include flex(row, center, flex-start);
            gap: 1rem;
            height: 100%;
            width: 100%;
          }
          .label {
            @include font(400, $normal);

            color: $secondary_blue;
            line-height: 2.5rem;
          }
        }

        .user_values {
          @extend .user_lables;
          .value {
            @include font(500, $normal);
            color: $primary_black;

            line-height: 2.5rem;
          }
          .value_skeleton {
            height: 100%;
            width: 60%;
            @include respond(mobile) {
              width: 100%;
            }
          }
        }

        .buttons_wrapper {
          @include font(500, $small);

          @include flex(row, center, flex-start);
          gap: 1rem;
          button {
            padding: 1.2rem 2.4rem;
          }
          align-self: flex-end;
        }
        @include respond(mobile) {
          grid-template-columns: 7rem 5fr;
          grid-gap: 1rem;
          .buttons_wrapper {
            margin-top: 2rem;
            @include flex(row, center, center);
            grid-column: 1/3;
          }
        }
      }
      @include respond(tablet) {
        height: max-content;
      }
    }
  }

  // .preferences_wrapper {
  //   margin-top: 5rem;

  //   .preferences_selection_wrapper {
  //     .preference_selection {
  //       .sub_heading {
  //         @include font(600, $small);
  //         color: $primary_blue;
  //         margin-bottom: 1rem;
  //       }

  //       .selection_wrapper {
  //         background: #fff;
  //         @include flex(row, center, flex-start);
  //         padding: 1rem;
  //         border-radius: 0.8rem;
  //         width: max-content;
  //         gap: 1rem;
  //         margin-bottom: 2rem;

  //         .selection_option {
  //           width: 14rem;
  //           padding: 0.6rem 1.6rem;
  //           border-radius: 0.4rem;
  //           @include font(500, $normal);

  //           @include flex(row, center, center);
  //           color: $dark;
  //           cursor: pointer;
  //           transition: 0.3s ease-in-out;
  //           &:hover {
  //             background: $lightgrey;
  //           }

  //           @include respond(mobile) {
  //             width: 8rem;
  //           }
  //         }

  //         .selected {
  //           background: $primary_blue;
  //           color: #fff;
  //           &:hover {
  //             background: $primary_blue;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  @include respond(tablet) {
    height: unset;
    @include flex(column, center, flex-start);
  }

  .company_wrapper {
    margin-bottom: 0;
    overflow-y: auto;
    height: calc(100vh - 8rem);
    @include scrollbar;
    @extend .my_account_wrapper;
    padding: 0 1rem 0 0;
    .company_info_wrapper {
      @extend .account_information_wrapper;
      .company_overview {
        border-left: 0.5rem solid $success;
        border-bottom: none;
        border-top: none;
        border-radius: 0.8rem;
        @extend .information_wrapper;
        margin-top: 0;
        height: max-content;
        @include respond(mobile) {
          padding: 1rem;
        }

        .company_logo_name_tag_wrapper {
          @extend .user_wrapper;
          grid-template-columns: 8rem 1fr max-content;
          .company_logo {
            @extend .profile_photo;
          }
          .company_name_tag_wrapper {
            .company_name {
              @include font(500, $big);
              color: $primary_black;
              margin-bottom: 0.5rem;
              @include respond(mobile) {
                font-size: $normal;
              }
              height: 3.3rem;
            }

            .company_tag {
              color: #fff;
              background: $dark;
              border-radius: 0.4rem;
              padding: 0.6rem 1.2rem;
              border-bottom: 0.4rem solid $primary_black;
              border-left: none;
              border-right: none;
              @include font(600, $very-small);
              @include flex(row, center, center);
              width: max-content;
            }
          }

          .company_tag_non_accessible {
            @extend .company_tag;
            background: $secondary_blue;
          }
          @include respond(mobile) {
            grid-template-columns: 8rem 1fr;
            grid-row-gap: 2rem;
          }
        }
        .company_status {
          color: #fff;
          border-radius: 0.4rem;
          background: $success;
          padding: 0.6rem 1.2rem;
          @include flex(row, center, center);
          @include font(600, $small);
          height: 2.7rem;
          width: max-content;
          align-self: flex-start;

          @include respond(mobile) {
            align-self: flex-end;
          }
        }
        .company_status_non_accessible {
          @extend.company_status;
          background: $lightred;
        }
        @include respond(mobile) {
          grid-template-columns: 1fr;
          grid-row-gap: 2rem;
        }

        .company_details_wrapper {
          margin-top: 2rem;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 1rem;
          grid-column: 1/3;
          .company_details_box_one,
          .company_details_box_two {
            .company_details_item {
              display: grid;
              grid-template-columns: 2.25rem 15rem 1fr;
              padding: 0.75rem 0;
              align-items: center;

              .label {
                @include font(400, $normal);
                color: $secondary_blue;
              }
              .value {
                color: $primary_black;
                @include font(500, $normal);
              }
              @include respond(mobile) {
                grid-template-columns: 2.25rem 8rem 1fr;
              }
            }
          }

          @include respond(tablet) {
            grid-template-columns: 1fr;
          }
        }
      }
      .company_overview_non_accessible {
        @extend .company_overview;
        border: none;
        margin-top: 2rem;
      }
    }
  }
  .table3_container {
    margin: -2rem;
  }
}
