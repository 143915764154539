.topbar_wrapper {
  height: 6rem;
  padding: 0 3rem;
  background: $primary_blue;
  font-size: $small;
  color: $offwhite;
  display: grid;
  grid-template-columns: 1fr 12rem 18rem;
  align-items: center;
  grid-column-gap: 2rem;
  font-weight: 500;
  position: relative;

  .navigation_wrapper {
    @include flex(row, center, flex-start);
    gap: 2rem;

    .navigation_item {
      transition: 0.3s ease-in-out;
      &:not(:nth-child(1)) {
        padding: 1rem 1.5rem;
        border-radius: 0.4rem;
        cursor: pointer;

        &:hover {
          color: #fff;
          font-weight: 500;
        }
      }
      .navbar_logo {
        height: 3.2rem;
        cursor: pointer;
      }
    }
    .selected {
      background: $navitembackgroud;
      color: #fff;
      font-weight: 500;
    }
  }
  @include respond(tablet) {
    height: 100%;
    @include flex(column, center, space-between);
    padding: 3rem 0;

    .navigation_wrapper {
      @include flex(column, center, flex-start);
      width: 100%;
      padding: 0 1.25rem;

      .navigation_item {
        &:nth-child(1) {
          @include flex(row, center, center);
        }
        width: inherit;
      }
    }
  }
  .language_switch {
    @include flex(row, center, flex-start);
    gap: 1rem;

    .language_name {
      border-radius: 0.4rem;
      border: 1px solid rgba(0, 0, 0, 0.1);
      color: #fff;
      font-weight: 500;
      padding: 0.25rem 1.2rem;
      cursor: pointer;
    }
  }
  .account_details {
    .account_information {
      color: #fff;
      @include flex(row, center, flex-end);
      cursor: pointer;

      .account_icon {
        margin-left: 1rem;
        // strokewidth: 5rem;
      }
      .loggedin_user_name {
        max-width: 15rem;
        @include ellipsis;
      }
    }
  }
  .account_popup_wrapper {
    position: absolute;
    border: 1px solid $slate200;
    width: 20rem;
    top: 5rem;
    right: 3rem;
    background: #fff;
    z-index: 1;
    // height: 20rem;
    border-radius: 0.8rem;
    box-shadow: 0px 4px 100px 0px rgba(0, 0, 0, 0.1);
    transition: 0.3s ease-in-out;
    padding: 0.8rem;
    .account_nav {
      border-radius: 0.4rem;
      padding: 1.2rem 2.4rem 1.2rem 1.2rem;
      @include flex(row, center, flex-start);
      gap: 1.2rem;
      color: $slate800;
      @include font(500, $normal);
      cursor: pointer;
      transition: all 0.3s;
      &:hover {
        background: $slate100;
      }
    }
    .account_logout {
      @extend .account_nav;
      color: $red700;
      svg {
        path {
          fill: $red700;
        }
      }
      &:hover {
        background: $red700;
        color: #fff;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  .dropdown_invisible {
    @extend .account_popup_wrapper;
    max-height: 0;
    opacity: 0;
    pointer-events: none;
  }
  .dropdown_visible {
    max-height: 100rem;
    opacity: 1;
  }
}
