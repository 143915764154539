.my_account_teams_wrapper {
  @extend .my_account_wrapper;
  .my_account_filters_wrapper {
    @include flex(row, center, space-between);
    .my_account_team_filters {
      display: grid;
      grid-template-columns: 25rem 20rem 20rem;
      grid-column-gap: 1rem;
    }
  }
}
