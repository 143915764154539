.credit_applications_filter_wrapper {
  display: grid;
  grid-template-columns: 35rem 12rem 1fr 1fr;
  grid-gap: 1rem;

  padding: 1rem;
  @include respond(tablet) {
    grid-template-columns: 30rem 10rem auto;
  }
  @include respond(mobile) {
    grid-template-columns: 1fr;
  }

  .credit_application_extras {
    @include flex(row, center, flex-end);
    gap: 1rem;
    @include respond(tablet) {
      justify-content: flex-start;
    }
    @include respond(mobile) {
      justify-content: space-between;
    }
    .credit_application_sent {
      border-radius: 0.25rem;
      background: $slate50;
      border: 1px dashed $slate200;
      padding: 0.5rem 1rem;
      @include flex(row, center, center);
      height: 100%;
    }
  }
}

.send_credit_application_wrapper {
  .credit_form_wrapper {
    width: 100%;
    .credit_section {
      width: 100%;
      padding-right: 1rem;
      .input_container,
      .select_container {
        .select_error_message,
        .input_message {
          height: 1rem;
        }
      }
    }
    .field_0 {
      margin-top: 1rem;
    }
    .credit_section_file {
      width: 100%;
      .upload_content_wrapper {
        width: 100%;
      }
    }
    height: 38rem;
    overflow-y: auto;
    @include scrollbar;
  }
  hr {
    width: 100%;
    border: 1px solid $slate100;
  }
}
.lang_pref_heading {
  @include font(500, $small);
  color: $slate900;
}
.credit_type_selection {
  @include flex(row, center, flex-start);
  gap: 2rem;
  label {
    @include font(400, $normal);
  }
}

.credit_application_order_report_wrapper {
  height: 50rem !important;
  overflow-y: auto;
  @include scrollbar;
  @include respond(mobile) {
    max-height: 40rem;
  }
  .report_options_wrapper {
    .report_selection_box {
      font-size: $normal;
    }
    .heading {
      font-size: $normal !important;
      margin-bottom: 1rem;
      color: $primary_blue;
      font-weight: 500;
    }
    padding: 0;
  }

  .credit_application_report_fields {
    border: 1px solid;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    @include respond(tablet) {
      grid-template-columns: 1fr;
    }
    .select_container,
    .input_container {
      width: calc(100% - 1rem);
    }
  }
}

.credit_details_wrapper {
  padding: 1.6rem;
  background: #fff;

  .credit_details_loader {
    .credit_details_skeleton {
      height: 4rem;
      margin-bottom: 1rem;
      &:nth-child(2n + 1) {
        width: 75%;
      }
    }
  }
  .credit_application_details {
    .credit_header {
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      min-width: 100%;
      display: grid;
      grid-template-columns: repeat(6, 15rem) 40rem;
      grid-gap: 1rem;
      height: 5rem;
      color: #fff;
      align-items: center;
      padding: 0 1rem;
      border-radius: 0.4rem;
      background: $dark;
      @include font(500, $normal);
      .credit_key {
        .credit_email {
          @include font(400, $small);
          color: $light_21;
        }
      }
    }
    .credit_basic_details_wrapper {
      margin-top: 1rem;
      border: 1px solid $slate200;
      border-radius: 0.25rem;
      padding: 1rem;
      display: grid;
      grid-template-columns: repeat(6, 15rem) 40rem;
      grid-gap: 1rem;
      background: #fff;
      overflow-x: auto;
      &::-webkit-scrollbar {
        display: none;
      }

      .credit_key_value {
        @include flex(column, flex-start, center);
        gap: 0.5rem;

        .credit_id {
          @include font(500, $normal);
          color: $slate900;
        }
        .date {
          @include font(400, $normal);
          color: $slate900;
        }
        .time {
          @include font(400, $small);
          color: $slate500;
        }
        .credit_application_report_wrapper {
          padding-right: 3rem;
          max-height: 20rem;
          overflow-y: auto;
          @include scrollbar;
        }
      }
    }
    .credit_details_all_information {
      overflow-y: auto;
      max-height: 100%;
      padding-bottom: 2rem;
      @include scrollbar;
      .credit_details_section {
        margin-top: 1rem;

        .key_value {
          @include flex(row, center, flex-start);
          @include font(400, $small);
          border-radius: 0.25rem;
          background: #fff;
          padding: 0.5rem 0.75rem;
          @include respond(mobile) {
            @include flex(row, center, space-between);
          }
          .key {
            color: $secondary_blue;
          }
          .value {
            color: $primary_black;
            text-transform: capitalize;
          }
        }
        .mini_section {
          margin-bottom: 3rem;
          @include flex(row, flex-start, flex-start);
          gap: 1rem;
          @include respond(tablet) {
            flex-direction: column;
          }
          .left_section,
          .right_section,
          .middle_section {
            width: 50%;
            @include respond(tablet) {
              width: 100%;
            }
            .key_value {
              @include flex(row, center, space-between);
              background: $ember_5;
            }
            .separator {
              margin-bottom: 1rem;
              border: none;
            }
          }
        }
        .supplier_section {
          @extend .mini_section;
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-row-gap: 2rem;
          grid-column-gap: 1rem;
          @include respond(tablet) {
            grid-template-columns: 1fr;
          }
          .left_section,
          .right_section {
            width: 100%;
          }
        }
        .alternate_shaded {
          .left_section,
          .right_section {
            .key_value {
              &:nth-child(2n + 1) {
                background: #fff;
              }
            }
          }
        }
        .tri_section {
          @extend .mini_section;
          .left_section,
          .middle_section,
          .right_section {
            width: 33%;
            @include respond(tablet) {
              width: 100%;
            }
          }
          .left_section {
            .key_value {
              background: $light_22;
            }
          }
          .middle_section,
          .right_section {
            .key_value {
              background: $ember_6;
              .key {
                color: $secondary_blue;
              }
              .value {
                color: $success;
              }
            }
            .sub_heading {
              color: $success;
            }
          }
        }
        .sign_details_wrapper {
          margin-bottom: 3rem;
          width: 50%;
          @include flex(row, center, flex-start);
          @include respond(mobile) {
            flex-wrap: wrap;
            width: 100%;
          }
          gap: 1rem;
          .sign {
            height: 100%;
            width: 50%;
            @include respond(mobile) {
              width: 80%;
              margin: 0 auto;
            }
            img {
              object-fit: cover;
            }
          }
          .sign_info {
            width: 50%;
            @include respond(mobile) {
              width: 100%;
            }
            .key_value {
              @include flex(row, flex-start, space-between);
              .key {
                color: $dark;
              }
            }
          }
        }
        .comments_wrapper {
          border-radius: 0.065rem;
          background: $ember_5;
          padding: 1rem;
          .title {
            margin-bottom: 0.5rem;
            @include font(400, $small);
            color: $secondary_blue;
          }
          .comment {
            @include font(500, $small);
            color: $primary_black;
          }
        }
        .main_heading {
          @include font(600, $normal);
          color: $primary_black;
          text-decoration: underline;
          margin-bottom: 1rem;
        }
        .sub_heading {
          @extend .main_heading;
          font-size: $small;
          text-decoration: none;
          margin-bottom: 0.5rem;
        }
      }
      .section_one {
        margin-bottom: 3rem;
        .key_value {
          &:nth-child(2n + 1) {
            background: $ember_5;
          }
          .key {
            width: 50%;
            @include respond(mobile) {
              width: unset;
            }
          }
        }
      }
    }
  }
}

.last_resort_wrapper {
  max-height: 40rem;
  overflow-y: scroll;
  @include scrollbar;
  padding-right: 1rem;
  .last_resort {
    color: $primary_blue;
    @include font(700, $normal);
  }
  .last_resort_phone {
    white-space: nowrap;
  }
}

.credit_type_tag {
  border: 1px solid $blue_2;
  padding: 0.125rem 0.25rem;
  border-radius: 0.125rem;
  background: $blue_3;
  @include font(500, $very-small);
  color: $primary_blue;
  width: max-content;
}
.credit_application_report_wrapper {
  .credit_application_reports {
    padding: 0.5rem 0;
    @include flex(row, center, flex-start);
    gap: 1rem;
  }
}
