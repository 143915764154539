.navigation_header_wrapper {

  position: sticky;
  top: 0;
  z-index: 5;

  @include flex(row, center, space-between);
  padding: 1.6rem;
  background: #fff;
  border-bottom: 1px solid $slate100;
  gap: 1rem;

  @include respond(mobile) {
    @include flex(column, flex-start, center);
  }

  .navigation_header {
    @include flex(row, center, flex-start);
    gap: 1rem;

    .navigation_title {
      @include font(500, $normal);
      color: $slate900;
    }

    .navigation_icon_wrapper {
      border-radius: 0.8rem;
      width: 3.2rem;
      height: 3.2rem;
      @include flex(row, center, center);
      background: $slate100;
      transition: 0.3s ease-in-out;
      cursor: pointer;
    }
  }
  .navigation_actions {
    @extend .navigation_header;
    button {
      min-width: 10rem;
    }
  }
}
