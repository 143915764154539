.datepicker_wrapper {
  width: 100%;
  position: relative;

  .datepicker_icon {
    @include flex(row, center, center);
    font-size: $normal;
    color: rgba(0, 0, 0, 0.54);
  }

  .datepicker_box {
    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
      rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
    height: 35rem;
    position: absolute;
    left: 0;
    min-width: 30rem;
    background: #fff;
    z-index: 0;
    border-radius: 0.4rem;
    padding: 1rem;
    font-size: $normal;
    visibility: hidden;
    opacity: 0;
    transition: 0.3s ease-in-out;

    .month_year_switcher {
      @include flex(row, center, space-between);
      position: relative;

      .month_year {
        @include flex(row, center, flex-start);
        gap: 1rem;
        cursor: pointer;
        font-weight: 500;
      }

      .switcher {
        @include flex(row, center, flex-start);
        gap: 1rem;
        height: 4rem;

        .switcher_icon_wrapper {
          height: 3.5rem;
          width: 3.5rem;
          border-radius: 50%;
          transition: 0.3s ease-in-out;
          @include flex(row, center, center);
          cursor: pointer;
          &:hover {
            background: $lightgrey;
            .switcher_icon {
              color: $dark;
            }
          }
        }
        .switcher_disabled {
          @extend .switcher_icon_wrapper;
          background: $slate100;
          pointer-events: none;
        }
      }
    }

    .weeks_wrapper {
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-gap: 0.5rem;

      .week_box {
        color: $primary_black;
        font-weight: 500;
        padding: 1rem;
        display: grid;
        place-items: center;
      }
    }

    .days_wrapper {
      @extend .weeks_wrapper;
      flex-wrap: wrap;
      .day_box {
        @extend .week_box;
        border-radius: 50%;
        transition: 0.3s ease-in-out;
        @include font(500, $small);
        &:hover {
          cursor: pointer;
          background: $dullgrey;
        }
      }
      .day_today {
        outline: 1px solid $dark;
      }
      .hide_previous_day {
        background: $slate100;
        cursor: not-allowed !important;
        pointer-events: none;
      }
      .day_selected {
        outline: none;
        background: $primary_blue;
        color: #fff;
        &:hover {
          background: $primary_blue;
          color: #fff;
        }
      }
    }

    .years_wrapper {
      position: absolute;
      top: 6.25rem;
      left: 0;
      right: 0;
      padding: 1rem;
      height: 28rem;
      overflow-y: scroll;
      background: #fff;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-gap: 1rem;
      visibility: hidden;
      opacity: 0;
      transition: 0.3s ease-in-out;

      .year_box {
        transition: 0.3s ease-in-out;
        border-radius: 1.8rem;
        padding: 0.75rem 1rem;
        display: grid;
        place-items: center;
        cursor: pointer;
        &:hover {
          background-color: $lightgrey;
        }
      }
      .year_selected {
        background: $primary_blue;
        color: #fff;
        &:hover {
          background: $primary_blue;
          color: #fff;
        }
      }
    }
    .show_years {
      @extend .years_wrapper;
      visibility: visible;
      opacity: 1;
    }
  }
  .show_datepicker {
    @extend .datepicker_box;
    visibility: visible;
    opacity: 1;
    transform-origin: center top;
    z-index: 1;
  }
}

.table_datepicker_wrapper {
  input {
    height: 100%;
  }
  // .table_input {
  //   position: relative;
  // }
  // .date_icon {
  //   position: absolute;
  //   top: 50%;
  //   transform: translate(0, -50%);
  //   right: 0.25rem;
  // }
}
