.sidebar_wrapper {
  @include flex(column, center, flex-start);
  gap: 0.8rem;
  padding: 1rem;
  height: 100%;

  .navitem_wrapper {
    width: 100%;
    padding: 1rem 1.2rem;
    border-radius: 0.8rem;
    @include flex(row, center, flex-start);
    gap: 0.8rem;
    @include font(400, $normal);
    cursor: pointer;
    transition: 0.3s ease-in-out;
    white-space: nowrap;
    .sidebar_icon {
      height: 2.4rem;
      width: 2.4rem;
      @include flex(row, center, center);
      svg {
        path {
          fill: $secondary_blue;
        }
      }
    }

    &:hover {
      background: $lightgrey;
    }
  }
  .selected {
    @extend .navitem_wrapper;
    background: $sidebarItembg;
    color: $primary_blue;
    .sidebar_icon {
      svg {
        path {
          fill: $primary_blue;
        }
      }
    }
  }

  @include respond(tablet) {
    height: unset;
    width: 100vw;
    @include flex(row, center, flex-start);
    gap: 2rem;
    overflow-x: auto;
    overflow-y: hidden;
    border-bottom: 0.2rem solid $slate50;
    &::-webkit-scrollbar {
      display: none;
    }

    .navitem_wrapper {
      flex: 0 0 auto;
      width: max-content;
    }
  }
}
