.onboarding_wrapper {
  height: 100vh;
  font-size: $normal;

  overflow: hidden;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include respond(tablet) {
    grid-template-columns: 1fr;
  }

  .onboarding_right_section {
    z-index: 999999;
    background: #fff;
    @extend .login_wrapper;

    .onboarding_right_section_wrapper {
      @extend .login_form;

      .logo_message_wrapper {
        .login_message {
          color: $primary_blue;
        }
      }
      .onboarding_message {
        @include font(600, $normal_big);
        border-bottom: 0.4rem solid #f7f7f7;

        padding-top: 2rem;
        padding-bottom: 1rem;
      }

      .onboarding_preferences {
        @extend .login_form_wrapper;
        .onboarding_preferences_wrapper {
          height: 30rem;
          overflow-y: scroll;
          background: #ffffff;
          border: 1px solid $lightgrey;
          box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
          border-radius: 0.4rem;
          padding: 1.6rem;
          z-index: 0;
          @include scrollbar;

          .onboarding_details {
            display: grid;
            grid-auto-rows: auto;
            grid-row-gap: 0.25rem;

            .heading {
              margin-bottom: 1.2rem;
              color: $blur;
              @include font(700, $normal_big);
            }
          }
        }

        .onboarding_note {
          color: $secondary_blue;
          @include font(400, $small);

          margin: 1.25rem 0;
        }
      }
    }
  }

  .onboarding_left_section {
    background: $primary_blue;
    z-index: 1;

    .slider_wrapper {
      background: $primary_blue;
      background: url('../images/noise_bg.png');
      height: 100%;
      width: 100%;
      z-index: 0;

      @include flex(column, center, center);
      gap: 10rem;
      position: relative;

      .onboarding_logo_wrapper {
        width: 40rem;
      }

      .carousel {
        border-radius: 1.2rem;
        margin: 0 auto;
        overflow: hidden;
        max-width: 40rem;
        z-index: 1;

        .carousel_inner {
          white-space: nowrap;
          transition: ease 1s;

          .carousel_item {
            height: 30rem;
            width: 48rem;
            display: inline-block;
            width: 100%;

            .carousel_image {
              visibility: visible;
              border-radius: 1.2rem;
              width: 100%;
              height: 100%;
              object-fit: cover;
            }

            .carousel_text {
              margin-top: 4rem;
              text-align: center;
              color: #fff;
              @include font(700, $normal);
            }
          }
        }

        .carousel_buttons {
          margin-top: 4em;
          @include flex(row, center, space-between);

          .carousel_control {
            background-color: rgba(0, 0, 0, 0.25);
            border: none;
            display: inline-block;
            height: 3.2rem;
            width: 3.2rem;
            border-radius: 50%;
            color: #f3f3f3;
            cursor: pointer;
            @include flex(row, center, center);

            .left,
            .right {
              border: 0.1rem solid #fff;
              border-width: 0 0.1rem 0.1rem 0;
              display: inline-block;
              padding: 0.3rem;
            }
            .left {
              margin-left: 0.25rem;
              transform: rotate(135deg);
            }
            .right {
              margin-right: 0.25rem;
              transform: rotate(-45deg);
            }
          }
          .carousel_indicators {
            width: 7rem;
            @include flex(row, center, space-between);

            .item {
              height: 0.8rem;
              width: 0.8rem;
              border-radius: 50%;
              background: rgba(0, 0, 0, 0.25);
              cursor: pointer;
            }
            .active {
              background: #fff;
            }
          }
        }
      }

      .slider_blur {
        position: absolute;
        z-index: -1;
        height: 40rem;
        width: 40rem;
        transform: rotate(70deg);
        animation-name: soothing-blur;
        animation-duration: 60000ms;
        animation-iteration-count: infinite;
        animation-timing-function: ease-out;

        .orange_blur {
          background-color: $blur;
          height: 100%;
          width: 100%;
          filter: blur(200px);
          animation-name: blur-intensity;
          animation-duration: 60000ms;
          animation-iteration-count: infinite;
          animation-timing-function: ease-out;
        }
        .blue_blur {
          position: relative;
          top: 50%;
          @extend .orange_blur;
          background-color: violet;
        }
      }
    }
    @include respond(tablet) {
      display: none;
    }
  }
}

@keyframes soothing-blur {
  0% {
    top: 100%;
    left: 100%;
    transform: rotate(0deg);
  }

  20% {
    top: -50%;
    left: 100%;
    transform: rotate(25deg);
  }

  40% {
    top: 0;
    left: 0;
    transform: rotate(0deg);
  }

  60% {
    left: 0;
    top: 60%;
    transform: rotate(-25deg);
  }

  80% {
    top: -50%;
    left: -50%;
    transform: rotate(0deg);
  }

  100% {
    top: 100%;
    left: 100%;
    transform: rotate(0deg);
  }
}

@keyframes blur-intensity {
  0% {
    filter: blur(150px);
  }

  20% {
    filter: blur(100px);
  }

  40% {
    filter: blur(200px);
  }

  60% {
    filter: blur(100px);
  }

  100% {
    filter: blur(150px);
  }
}
