.button_component {
  border-radius: 0.6rem;
  font-weight: 500;
  @include center;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  position: relative;

  .button_spinner {
    border-top: solid transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    position: absolute;
    border-width: 0.15rem;
    border-style: solid;
  }

  &:active {
    filter: brightness(0.9);
  }
}

.button_spinning {
  pointer-events: none;
  opacity: 0.85;

  .button_text {
    opacity: 0;
  }
  .button_spinner {
    opacity: 1;
    visibility: visible;
  }
}

.button_static {
  .button_text {
    opacity: 1;
    @include flex(row, center, center);
    gap: 0.8rem;
  }
  .button_spinner {
    opacity: 0;
    visibility: hidden;
  }
}

.button_default {
  background: linear-gradient(180deg, $blue_5 0%, $primary_blue 100%);
  color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05),
    0px -1px 1px 0px rgba(0, 0, 0, 0.1) inset,
    0px 1px 1px 0px rgba(255, 255, 255, 0.1) inset;

  &:hover {
    background: linear-gradient(180deg, $blue_6 0%, $blue_7 100%);
  }
}

.button_destructive {
  color: #fff;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background: $red500;
  &:hover {
    background: $red600;
  }
}

.button_outlined {
  border: 1px solid $slate200;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  color: $slate900;
  &:hover {
    background: $slate100;
  }
}

.button_subtle {
  background: $slate100;
  color: $slate900;
  &:hover {
    background: $slate200;
  }
}

.button_ghost {
  background: rgba(255, 255, 255, 0);
  color: $slate900;
  &:hover {
    background: $slate100;
  }
}

.button_link {
  background: rgba(255, 255, 255, 0);
  color: $slate900;
  &:hover {
    text-decoration: underline;
    text-underline-position: from-font;
  }
}

.button_disabled {
  background: $slate100 !important;
  color: $slate300 !important;
  pointer-events: none;
  user-select: none;
  cursor: not-allowed !important;
}

.button_size_default {
  padding: 0 1.6rem;
  font-size: $normal;
  height: 4rem;
  .button_spinner {
    width: 1.6rem;
    height: 1.6rem;
  }
}

.button_size_small {
  padding: 0 1.2rem;
  font-size: $small;
  height: 3.2rem;

  .button_spinner {
    width: 1.4rem;
    height: 1.4rem;
  }
}

.button_icon_only {
  padding: 0.8rem;
  @include flex(row, center, center);
}

.button_icon_only_default {
  height: 2.4rem;
  width: 2.4rem;
  min-height: 2.4rem;
  min-width: 2.4rem;
}

.button_icon_only_small {
  height: 1.6rem;
  width: 1.6rem;
  min-height: 1.6rem;
  min-width: 1.6rem;
}

.button_icon_only_2_8 {
  height: 1.2rem;
  width: 1.2rem;
  min-height: 1.2rem;
  min-width: 1.2rem;
}
