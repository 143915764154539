.header_wrapper {
  @include flex(row, center, space-between);
  height: 5rem;
  border-bottom: 1px solid $lightgrey;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  padding: 0 1.6rem;

  .header_navigation {
    @include flex(row, center, flex-start);
    transition: 0.3s ease-in-out;
    padding: 1rem 2rem;
    border-radius: 0.4rem;
    &:hover {
      background: $lightgrey;
    }
  }

  svg {
    path {
      fill: $dark;
      &:hover {
        color: $primary_black;
      }
    }
  }

  .text {
    margin-left: 1rem;
    @include font(500, $normal);
    color: $dark;
    &:hover {
      color: $primary_black;
    }
  }

  .hamburger {
    margin-left: auto;
    display: none;

    @include respond(tablet) {
      display: block;
      font-size: $normal;
    }
  }

  img {
    margin-right: 2rem;
  }
  @include respond(mobile) {
    padding: 0 1rem;
  }

  .header_nav_text {
    @include flex(row, center, center);
    gap: 0.5rem;
    .header_nav_link,
    .header_nav_icon,
    .header_nav_content {
      @include flex(row, center, center);
      .back_to_report {
        cursor: pointer;
      }
    }
    .header_nav_link {
      color: $slate500;
      cursor: pointer;
    }

    .last_nav_item {
      margin-left: 0.5rem;
    }
  }
}
