*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  margin: 0;
  font-family: Inter;
}

body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
  margin: 0 auto;
  height: 100vh;
}
html {
  font-size: 10px;
  scroll-behavior: smooth;
}

html:focus-within {
  scroll-behavior: smooth;
}

input,
button {
  all: unset;
}

img,
picture,
video,
canvas {
  display: block;
  max-width: 100%;
}

//button spinner
.button_loading {
  pointer-events: none;
  &::after {
    content: '';
    width: 2.1rem;
    height: 2.1rem;
    border: 0.25rem solid #fff;
    border-radius: 50%;
    border-top: 0.25rem solid transparent;
    animation: spin 0.75s linear infinite;
  }
}
.button_loading_secondary {
  @extend .button_loading;
  &::after {
    border: 0.25rem solid $primary_blue;
    border-top: 0.25rem solid #fff;
  }
}
.button_loading_seven {
  @extend .button_loading;
  &::after {
    border: 0.25rem solid $primary_black;
    border-top: 0.25rem solid #fff;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

// toast message
.toast_message_wrapper {
  font-family: 'Inter';
  @include font(600, $small);
  position: fixed;
  bottom: 4rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 5rem;
  width: 40rem;
  color: #fff;
  text-align: center;
  visibility: hidden;
  white-space: nowrap;
  overflow: hidden;
  z-index: 11;
  box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.25);
  border-radius: 0.4rem;
  padding-left: 2rem;
  @include flex(row, center, flex-start);
  @include respond(mobile) {
    width: 30rem;
  }
  .toast_icon {
    font-size: 1.6rem;
    margin-right: 1rem;
  }
}
.show {
  visibility: visible;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}
.success {
  background: $success;
}
.error {
  background: $error;
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 4rem;
    opacity: 1;
  }
}
@keyframes fadeout {
  from {
    bottom: 4rem;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

// form errors
.form_error {
  color: $error;
  height: 2rem;
  margin: 0.5rem !important;

  @include font(500, $small);
}
.form_warning {
  color: $ember600 !important;
}

.container {
  border: 1px solid;
  padding: 0 2rem;
  @include respond(mobile) {
    padding: 0 0.5rem;
  }
}

.checkbox_wrapper {
  appearance: auto;
  width: 1.6rem;
  height: 1.6rem;
  border: 2px solid green;
}
label {
  max-width: max-content;
}

.clear_filter {
  white-space: nowrap;
  border: 0.2rem solid $lightwhite;
  color: $primary_black;
  @include font(500, $small);
  padding: 0.95rem 1rem;
  border-radius: 5rem;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  &:hover {
    border: 0.2rem solid $secondary_blue;
    background: $lightgrey;
  }
}

.navigation_bar_wrapper {
  height: 5.5rem;
  background: #fff;
  border-bottom: 1px solid $lightgrey;
  padding: 1rem 1.6rem;

  @include flex(row, center, flex-start);
  gap: 1rem;

  .nav_main {
    cursor: pointer;
    color: $secondary_blue;
    @include font(500, $normal);
    transition: 0.3s ease-in-out;
    &:hover {
      color: $dark;
      text-decoration: underline;
    }
  }
  .nav_submain {
    color: $primary_black;
    @include font(500, $normal);
  }
}

.popup_backdrop_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: 0.3s ease-in-out;
}
.popup_visible {
  @extend .popup_backdrop_wrapper;
  background: rgba(0, 0, 0, 0.25);
  opacity: 1;
  visibility: visible;
  @include flex(row, center, center);
  z-index: 2;

  .popup_container {
    width: 40rem;
    filter: drop-shadow(0 0.8rem 5rem rgba(0, 0, 0, 0.1));

    .popup_header,
    .popup_footer {
      background: $dullgrey;
      padding: 2.4rem;
      height: 7rem;
      @include flex(row, center, space-between);
    }
    .popup_content {
      padding: 3.2rem;
      background: #fff;
      @include flex(column, center, space-evenly);
      gap: 1.6rem;
      .popup_field {
        width: 100%;
      }
      .input_wrapper,
      .select_wrapper {
        z-index: 1;
        .custom_span,
        .select_span {
          color: $secondary_blue;
        }
      }
    }
    .confirmation_text_wrapper {
      @include font(500, $normal);
      color: $primary_black;
    }
    .popup_header {
      border-bottom: 1px solid $lightgrey;
      border-radius: 0.8rem 0.8rem 0 0;
      color: $primary_black;
      @include font(500, $normal);
      img {
        cursor: pointer;
      }
    }
    .popup_footer {
      border-top: 1px solid $lightgrey;
      border-radius: 0 0 0.8rem 0.8rem;
      gap: 1rem;
      button {
        padding: 1.2rem 2.4rem;
        width: 18rem;
        font-weight: 500;
      }
    }
  }
}
.popup_invisible {
  @extend .popup_visible;
  opacity: 0;
  visibility: hidden;
}

.legal_watch_list_delete_wrapper,
.subject_watch_list_delete_wrapper {
  .popup_content {
    height: 12rem;
  }
}

// search filters and button wrapper
.search_filters_actions_wrapper {
  padding: 1.6rem;
  background: #fff;
  @include flex(row, center, flex-start);
  gap: 1rem;

  @include respond(tablet) {
    flex-wrap: wrap;
  }

  .search_filters {
    width: 100%;
    @include flex(row, center, flex-start);
    gap: 1rem;
    @include respond(tablet) {
      flex-wrap: wrap;
    }

    .search_wrapper,
    .select_filter_wrapper {
      padding: 0 1rem;
      outline: 1px solid $field;
      height: 4rem;
      border-radius: 0.4rem;
      font-size: $normal;
      color: $dark;
    }

    .search_wrapper {
      display: grid;
      grid-template-columns: 1.6rem auto 1.6rem;
      grid-gap: 1rem;
      place-items: center;

      .filter_input {
        width: 100%;
        height: 100%;
        color: $dark;
      }
    }
    .select_filter_wrapper {
      @include flex(row, center, flex-start);
      @include respond(tablet) {
        justify-content: space-between;
      }
      gap: 2rem;
      .placeholder_count_wrapper,
      .close_down_icons_wrapper {
        @include flex(row, center, flex-start);
        gap: 0.5rem;
        .filter_count {
          height: 1.7rem;
          width: 1.7rem;
          border-radius: 50%;
          @include font(500, $very-small);
          @include flex(row, center, center);
          color: #fff;
          background: $dark;
        }
        .filter_count_invisible {
          @extend .filter_count;
          visibility: hidden;
          opacity: 0;
        }
        .filter_count_visible {
          @extend .filter_count;
          visibility: visible;
        }
      }
      position: relative;
      .placeholder_wrapper {
        width: max-content;
        cursor: default;
      }

      .filter_options_wrapper {
        white-space: nowrap;
        border-radius: 0.4rem;
        background: #fff;
        z-index: 2;
        margin-top: 0.35rem;
        font-size: $normal;
        display: grid;
        grid-auto-rows: 4rem;
        max-height: 40rem;
        overflow-y: auto;
        min-width: 100%;
        box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
          rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
        &::-webkit-scrollbar {
          display: none;
        }
        .filter_options {
          padding: 0 1rem;
          transition: 0.3s ease-in-out;
          &:hover {
            cursor: pointer;
            background: $primary_blue;
            color: #fff;
          }
          .checkbox_label_wrapper {
            width: 100%;
            height: 100%;
            @include flex(row, center, flex-start);
            gap: 1rem;
          }
        }
      }
      .filter_hideOptions {
        position: absolute;
        top: 4rem;
        left: 0;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease-in-out;
      }
      .filter_showOptions {
        @extend .filter_hideOptions;
        visibility: visible;
        opacity: 1;
      }
    }
    .date_filter_wrapper {
      @include flex(row, center, flex-start);
      gap: 2rem;
      outline: 1px solid $field;
      padding: 0 1rem;
      height: 4rem;
      border-radius: 0.4rem;
      font-size: $normal;
      width: 13.5rem;
      @include flex(row, center, space-between);
      position: relative;
      .placeholder_wrapper {
        color: $dark !important;
        cursor: default;
      }

      .datepicker_box {
        box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
          rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
        height: 35rem;
        position: absolute;
        left: 0;
        top: 4rem;
        min-width: 30rem;
        background: #fff;
        z-index: 0;
        border-radius: 0.4rem;
        padding: 1rem;
        font-size: $normal;
        visibility: hidden;
        opacity: 0;
        transition: 0.3s ease-in-out;

        .month_year_switcher {
          @include flex(row, center, space-between);
          position: relative;

          .month_year {
            @include flex(row, center, flex-start);
            gap: 1rem;
            cursor: pointer;
            font-weight: 500;
          }

          .switcher {
            @include flex(row, center, flex-start);
            gap: 1rem;
            height: 4rem;

            .switcher_icon_wrapper {
              height: 3.5rem;
              width: 3.5rem;
              border-radius: 50%;
              transition: 0.3s ease-in-out;
              @include flex(row, center, center);
              cursor: pointer;
              &:hover {
                background: $lightgrey;
                .switcher_icon {
                  color: $dark;
                }
              }
            }
            .switcher_disabled {
              @extend .switcher_icon_wrapper;
              background: $slate100;
              pointer-events: none;
            }
          }
        }

        .weeks_wrapper {
          display: grid;
          grid-template-columns: repeat(7, 1fr);
          grid-gap: 0.5rem;

          .week_box {
            color: $primary_black;
            font-weight: 500;
            padding: 1rem;
            display: grid;
            place-items: center;
          }
        }

        .days_wrapper {
          @extend .weeks_wrapper;
          flex-wrap: wrap;
          .day_box {
            @extend .week_box;
            border-radius: 50%;
            transition: 0.3s ease-in-out;
            @include font(500, $small);
            &:hover {
              cursor: pointer;
              background: $dullgrey;
            }
          }
          .day_today {
            outline: 1px solid $dark;
          }
          .hide_previous_day {
            background: $slate100;
            cursor: not-allowed !important;
            pointer-events: none;
          }
          .day_selected {
            outline: none;
            background: $primary_blue;
            color: #fff;
            &:hover {
              background: $primary_blue;
              color: #fff;
            }
          }
        }

        .years_wrapper {
          position: absolute;
          top: 6.25rem;
          left: 0;
          right: 0;
          padding: 1rem;
          height: 28rem;
          overflow-y: scroll;
          background: #fff;
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 1rem;
          visibility: hidden;
          opacity: 0;
          transition: 0.3s ease-in-out;

          .year_box {
            transition: 0.3s ease-in-out;
            border-radius: 1.8rem;
            padding: 0.75rem 1rem;
            display: grid;
            place-items: center;
            cursor: pointer;
            &:hover {
              background-color: $lightgrey;
            }
          }
          .year_selected {
            background: $primary_blue;
            color: #fff;
            &:hover {
              background: $primary_blue;
              color: #fff;
            }
          }
        }
        .show_years {
          @extend .years_wrapper;
          visibility: visible;
          opacity: 1;
        }
      }
      .show_datepicker {
        @extend .datepicker_box;
        visibility: visible;
        opacity: 1;
        transform-origin: center top;
        z-index: 1;
      }
    }
  }

  .admin_credit_report_filters,
  .client_user_filters,
  .transaction_credit_report_filters {
    .search_wrapper {
      @include respond(mobile) {
        width: 100%;
      }
    }
  }
  .admin_credit_report_filters {
    .select_filter_wrapper,
    .date_filter_wrapper {
      @include respond(tablet) {
        width: calc(33% - 0.5rem);
      }
      @include respond(mobile) {
        width: calc(50% - 0.5rem);
      }
    }
    .lable_checkbox_wrapper {
      font-size: $normal;
    }
  }
  .client_user_filters,
  .transaction_credit_report_filters {
    .select_filter_wrapper {
      @include respond(mobile) {
        width: 100%;
      }
    }
  }
  .transaction_summary_filters,
  .transaction_credit_report_filters {
    .date_filter_wrapper {
      @include respond(mobile) {
        width: calc(50% - 0.5rem);
      }
    }
  }
}

.content_filters {
  margin: -2rem -2rem 2rem -2rem;
  border-left: 1px solid $slate200;
  border-bottom: 1px solid $slate200;
}

.pagination_wrapper {
  background: #fff;
  height: 4.8rem;
  margin: 0 auto;
  width: calc(100vw - 3.4rem);

  .pagination_content_wrapper {
    height: 100%;
    max-width: max-content;
    margin: 0 auto;
    @include flex(row, center, flex-start);
    gap: 0.5rem;
    padding: 0 1rem;
    overflow-x: auto;
    &::-webkit-scrollbar {
      display: none;
    }
    .page_wrapper {
      padding: 1rem;
      border-radius: 50%;
      height: 3.2rem;
      width: 3.2rem;
      @include flex(row, center, center);
      cursor: pointer;
      background: #fff;
      @include font(400, $normal);
      transition: 0.3s ease-in-out;
      &:hover {
        background: $light_17;
      }
    }
    .selected_page {
      @extend .page_wrapper;
      background: $primary_blue;
      color: #fff;
      &:hover {
        background: $primary_blue;
      }
    }
  }
}

.status {
  padding: 0.6rem 1.2rem;
  border-radius: 0.4rem;
  @include font(500, $small);
  color: #fff;
  height: 3rem;
  @include flex(row, center, center);
}

.Error,
.Inactive {
  @extend .status;
  border: 1px solid $red100;
  color: $red700;
  background: $red50;
}
.Pending {
  @extend .status;
  background: $slate100;
  color: $slate700;
  border: 1px solid $slate300;
}
.NeedAction {
  @extend .status;
  background: $blur;
}
.Canceled {
  @extend .status;
  background: $secondary_blue;
}
.Active {
  @extend .status;
  background: $green_1;
  color: $success;
}
.Invited {
  @extend .status;
  background: $ember50;
  border: 1px solid $ember_2;
  color: $ember700;
}
.FileReceived {
  @extend .Invited;
}
.DBUploaded {
  @extend .Invited;
  background: $green50;
  color: $green700;
}
.Filled,
.Completed {
  @extend .status;
  background: $green50;
  color: $green700;
  border: 1px solid $green_7;
}
.Opened,
.Processing {
  @extend .Invited;
}
.Sent {
  @extend .status;
  border: 1px solid $slate200;
  color: $slate700;
  background: $slate100;
}

.form_2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.25rem;
  @include respond(mobile) {
    grid-template-columns: 1fr;
  }
}
.form_3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 1.25rem;
}

.form_6 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.25rem;
  @include respond(tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include respond(mobile) {
    grid-template-columns: 1fr;
  }
}

// input[type='checkbox'] {
//   position: relative;
//   cursor: pointer;
// }
// input[type='checkbox']:before {
//   content: '';
//   display: block;
//   position: absolute;
//   width: 1.6rem;
//   height: 1.6rem;
//   top: 0;
//   left: 0;
//   border: 2px solid $slate400;
//   border-radius: 3px;
//   background-color: white;
//   transition: cubic-bezier(0, 0, 0, 0.1);
//   transition-duration: 0.2s;
// }

// input[type='checkbox']:checked:before {
//   content: '';
//   display: block;
//   position: absolute;
//   width: 1.6rem;
//   height: 1.6rem;
//   top: 0;
//   left: 0;
//   background-color: $primary_blue;
//   border: 2px solid $primary_blue;
// }
// input[type='checkbox']:checked:after {
//   content: '';
//   display: block;
//   width: 5px;
//   height: 10px;
//   border: solid white;
//   border-width: 0 2px 2px 0;
//   -webkit-transform: rotate(45deg);
//   -ms-transform: rotate(45deg);
//   transform: rotate(45deg);
//   position: absolute;
//   top: 2px;
//   left: 6px;
// }
// input[type='checkbox']:checked:disabled:before {
//   background-color: $disabled;
//   border: 2px solid $disabled;
//   cursor: not-allowed;
// }

.switch_wrapper {
  @include flex(row, center, flex-start);
  gap: 0.75rem;

  .switch_holder {
    height: 2.6rem;
    width: 4.2rem;
    position: relative;
    border-radius: 5rem;
    padding: 0.2rem;
    cursor: pointer;
    transition: all 0.2s;

    .switch_cicle {
      height: 2.2rem;
      width: 2.2rem;
      border-radius: 50%;
      background: #fff;
      box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      transition: all 0.2s;
    }
  }
  .switch_active {
    background: $green_5;
    .switch_right {
      left: 1.8rem;
    }
  }
  .switch_inactive {
    background: $grey_7;
    .switch_left {
      left: 0.2rem;
    }
  }
}

.switch_label_active {
  margin-left: 1rem;
  @include font(400, $small);
  color: $green700;
}
.switch_label_inactive {
  @extend .switch_label_active;
  color: $slate400;
}

.lable_checkbox_wrapper {
  padding: 1rem 0;
  label {
    @include flex(row, center, flex-start);
    color: $slate900;
    gap: 1rem;
    span {
      white-space: nowrap;
    }
  }
}

.upload_content_wrapper {
  border: 2px solid;
  padding: 2.4rem;
  background: $blue_3;
  border: 1px dashed $primary_blue;
  position: relative;
  @include flex(column, center, center);
  transition: 0.3s ease-in-out;
  color: $primary_blue;
  .upload_title {
    @include font(600, $normal);
    margin-bottom: 0.5rem;
  }
  .upload_sub_title {
    @include font(500, $small);
  }
  .upload_drop_wrapper {
    border: 1px solid $primary_blue;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.upload_active {
  @extend .upload_content_wrapper;
  cursor: pointer;
}
.upload_inactive {
  @extend .upload_content_wrapper;
  cursor: not-allowed;
}

.uploaded_file_wrapper {
  @include font(500, $small);
  color: $slate900;
  margin: 1.25rem 0;
  @include flex(row, center, space-between);
  .file_name {
    width: 22rem;
    @include ellipsis;
  }
  svg {
    cursor: pointer;
  }
}

.option_Canada,
.option_USA,
.option_highlighted {
  pointer-events: none;
  color: $primary_blue;
  font-weight: 700;
}

.on_cursor {
  cursor: pointer;
}

.no_cursor {
  cursor: unset !important;
}

.internal_loader {
  width: 2rem;
  height: 2rem;
  border: 2px solid $primary_blue;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  margin: auto;

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}

.clients_processing {
  border: 1px solid;
}

.in_focus {
  transition: 0.3s ease-in-out;
  transform: rotate(180deg);
}
.out_focus {
  @extend .in_focus;
  transform: rotate(0deg);
}

.information_wrapper {
  border: 1px solid $slate200;
  border-radius: 0.4rem;
  .information_row {
    display: grid;
    &:first-child {
      border-radius: 0.4rem 0.4rem 0 0;
    }
    &:last-child {
      border-radius: 0 0 0.4rem 0.4rem;

      .information_content {
        border-bottom: none;
      }
    }

    .information_content {
      padding: 0.8rem 1.6rem;
      border-right: 1px solid $slate200;
      border-bottom: 1px solid $slate200;

      .information_label {
        margin-bottom: 0.4rem;
        @include font(500, $very_small);
        color: $slate500;
      }
      .information_value {
        @include font(500, $small);
        color: $slate900;
      }
    }
  }
  .information_row_2 {
    @extend .information_row;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  .information_row_4 {
    @extend .information_row;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  .information_row_6 {
    @extend .information_row;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
  }
  .information_row_7 {
    @extend .information_row;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
  }
  .information_row_8 {
    @extend .information_row;
    grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
  }

  .information_row_white {
    background: #fff;
  }
}

.circular_progress_bar {
  display: block;
  margin: auto;
}

.circular_progress_circle {
  transition: stroke-dashoffset 0.5s ease-in-out;
  transform: rotate(-90deg);
  transform-origin: center;
}

.tool_tip_wrapper {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: max-content;

  .tool_tip_text {
    background: $slate900;
    border-radius: 0.8rem;
    padding: 1rem;
    color: #fff;
    @include font(400, $small);
    position: relative;
  }

  .tool_tip_rectangle {
    height: 1.2rem;
    width: 1.2rem;
    transform: rotate(45deg);
    background: $slate900;
    border-radius: 0.2rem;
    position: absolute;
    bottom: -0.6rem;
  }
  .rectangle_left {
    margin-right: 2rem;
  }
  .rectangle_reverse {
    @extend .tool_tip_rectangle;
    top: -0.6rem;
  }
}
.tool_tip_visible {
  @extend .tool_tip_wrapper;
  opacity: 1;
  visibility: visible;
}
.tool_tip_center {
  @include flex(column, center, center);
  left: 50%;
  transform: translateX(-50%);
}
.tool_tip_left {
  @include flex(column, flex-end, center);
  left: 90%;
  transform: translateX(-90%);
}
.tool_tip_normal {
  bottom: 150%;
}
.tool_tip_reverse {
  top: 150%;
}
.credit_risk_overview {
  bottom: 155%;
}
.credit_risk_list_action {
  bottom: 120%;
}
.client_credit_risk_list {
  z-index: 2;
  left:85%;
}
.client_credit_risk_list_icon {
  left: 4%;
}
