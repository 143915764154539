.credit_report_sidebar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
}

.report_info_nav {
  height: 4rem;
  width: 4rem;
  @include flex(row, center, center);
  border-radius: 0.4rem;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background: $slate100;
  }
}

.show_sidebar_backdrop {
  @extend .credit_report_sidebar_wrapper;
  background: rgba(0, 0, 0, 0.25);
  opacity: 1;
  visibility: visible;
  overflow-y: auto;
}
.hide_sidebar_backdrop {
  @extend .credit_report_sidebar_wrapper;
  opacity: 0;
  visibility: hidden;
}
.credit_report_sidebar_content_wrapper {
  height: 100%;
  display: grid;
  grid-template-columns: 25rem 1fr;
  @include respond(tablet) {
    grid-template-columns: 1fr;
  }

  .activities_log_heading {
    @include font(500, $normal);
    color: $primary_black;
    margin-top: 2rem;
  }

  .credit_report_invisible_wrapper {
    @include respond(tablet) {
      display: none;
    }
  }

  .credit_report_sidebar_content {
    border-left: 1px solid $lightgrey;
    background: $light_8;
    box-shadow: -4px 0px 32px rgba(0, 0, 0, 0.1);
    padding: 2.4rem;
    position: absolute;
    top: 0;
    opacity: 0;
    visibility: hidden;
    right: -100%;
    transition: 0.3s ease-in-out;
    height: 100%;
    overflow-y: auto;
    @include scrollbar;
    @include respond(mobile) {
      padding: 1rem 0.5rem;
      width: 100%;
    }

    .header {
      @include font(500, $normal_big);

      svg {
        cursor: pointer;
      }

      color: $primary_black;
      @include flex(row, center, space-between);
      .report_info {
        @include flex(row, center, flex-start);
        gap: 1rem;
        .report_info_breadcrumb {
          color: $secondary_blue;
          transition: 0.3s ease-in-out;
          &:hover {
            color: $slate700;
            cursor: pointer;
          }
        }
      }
    }
    .credit_report_sidebar_section {
      background: #fff;
      border-radius: 0.4rem;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
      border: 1.5px solid $lightgrey;
      margin-top: 2rem;

      button {
        @include font(500, $small);
        color: $dark;
        padding: 0.8rem 1.2rem;
      }

      //////////////////////////////////////////////////////

      // report basic information
      .report_basic_info_wrapper {
        padding: 2.4rem;
        @include respond(mobile) {
          padding: 1.2rem;
        }

        .report_details_section {
          border-bottom: 1px solid $lightgrey;
          padding-bottom: 1rem;
          @include flex(row, flex-start, flex-start);
          gap: 3rem;
          @include respond(mobile) {
            flex-direction: column;
            gap: 1rem;
          }
          .report_detail {
            .report_title {
              @include font(500, $small);
              color: $primary_blue;
            }
            .report_value {
              @include font(400, $normal);
              color: $primary_black;
            }

            .date_wrapper,
            .subject_wrapper,
            .report_company_wrapper {
              @extend .report_value;
              @include flex(column, flex-start, center);

              .time,
              .original_name,
              .user {
                @include font(400, $small);
                color: $secondary_blue;
              }
            }
          }
        }
      }
      // tracking icons
      .tracking_icons_wrapper {
        padding: 1.2rem 1.6rem;
        @include flex(row, center, flex-start);
        gap: 1.5rem;
        @include respond(mobile) {
          padding: 1.2rem;
          gap: 0.75rem;
          @include flex(column, flex-start, flex-start);
        }
        .tracking_icons_list {
          @include flex(row, center, center);
          gap: 1rem;
          img {
            height: 2rem;
            width: 2rem;
          }
        }
        .tracking_btns {
          margin-left: auto;
          button {
            @extend .report_sidebar_button;
          }
        }
      }

      // price chart
      .price_chart_wrapper {
        padding: 1.2rem 1.6rem;
        @include flex(row, flex-start, flex-start);
        gap: 3rem;
        @include respond(mobile) {
          flex-direction: column;
          gap: 1rem;
        }
        .price_detail {
          @include respond(mobile) {
            width: 100%;
            @include flex(row, center, space-between);
          }
          .price_title {
            @include font(500, $small);
            color: $secondary_blue;
          }
          .price_value {
            @include font(400, $normal);
            color: $primary_black;
          }
        }
      }

      // price details
      .price_details_wrapper {
        padding: 1.2rem 1.6rem;
        @include flex(row, center, space-between);
        gap: 1rem;
        @include respond(mobile) {
          flex-direction: column;
        }

        .price_details_content {
          width: max-content;
          @include flex(row, center, flex-start);
          gap: 3rem;
          @include respond(mobile) {
            width: 100%;
            @include flex(column, flex-start, center);
            gap: 1rem;
          }
          .price_detail {
            @include flex(row, center, flex-start);
            gap: 1rem;
            @include respond(mobile) {
              width: 100%;
              justify-content: space-between;
            }

            .price_title {
              @include font(500, $normal);
              color: $secondary_blue;
            }
            .price_value {
              @include font(500, $normal);
              color: $primary_black;
            }
          }
        }

        .price_details_actions {
          @include flex(row, center, flex-start);
          gap: 1rem;
          .view_logs {
            white-space: nowrap;
            cursor: pointer;
            @include font(500, $small);
            color: $dark;
          }
        }
      }

      //price logs
      .price_logs_wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;

        .price_logs_content {
          padding: 1.2rem 1.6rem;
          display: grid;
          grid-template-columns: repeat(3, 1fr) 3fr;
          grid-column-gap: 1rem;

          .price_log_title {
            @include font(500, $small);
            color: $secondary_blue;
          }
          .price_log_value {
            @include font(500, $normal);
            color: $primary_black;
          }
          .date_wrapper {
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
            .time {
              @include font(500, $small);
            }
          }
        }
        hr {
          margin: 0 1.6rem;
          border: 1px solid $lightgrey;
          &:last-child {
            border-color: transparent;
          }
        }
      }
      .price_logs_expanded {
        @extend .price_logs_wrapper;
        .price_log_content_wrapper {
          height: 30rem;
          overflow-y: auto;
          @include scrollbar;
        }
        max-height: 100rem;
        transition: max-height 1s;
      }

      // credit applications
      .credit_application_wrapper,
      .external_link_wrapper,
      .score_section_wrapper {
        padding: 1.2rem 1.6rem;
        @include flex(row, center, flex-start);
        gap: 2rem;
        .view_more {
          margin-left: auto;
        }
      }
      .credit_application_list_wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;

        .credit_applications_list {
          padding: 1.2rem 1.6rem;
          .credit_application_content {
            @include flex(row, center, flex-start);
            .application_name {
              @include font(500, $normal);
              color: $primary_black;
            }
            .application_actions {
              @include flex(row, center, flex-start);
              gap: 1rem;
              margin-left: auto;
            }
          }
          hr {
            border: 1px solid $lightgrey;
            margin: 1rem 0;
            &:last-child {
              border-color: transparent;
              margin: 0;
            }
          }
        }
      }
      .credit_applications_expanded {
        @extend .credit_application_list_wrapper;
        max-height: 100rem;
        transition: max-height 1s;
      }

      //external links
      .external_link_list_wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;

        .external_links_list {
          padding: 1.2rem 1.6rem;
          hr {
            border: 1px solid $lightgrey;
            margin: 1rem;
            &:last-child {
              border-color: transparent;
              margin: 0;
            }
          }

          .external_link_content_wrapper {
            width: 100%;

            @include flex(row, center, space-between);
            flex-wrap: wrap;
            gap: 1rem;
            .external_link {
              width: max-content;
              @include respond(mobile) {
                width: 100%;
              }
              .link_content {
                @include flex(row, center, flex-start);
                gap: 1.5rem;
                .link_header {
                  @include font(500, $small);
                  color: $secondary_blue;
                }
                .link {
                  max-width: 35rem;
                  @include respond(mobile) {
                    max-width: 20rem;
                  }
                  @include ellipsis;
                  @include font(500, $normal);
                }
              }
            }
            .external_links_actions {
              @include flex(row, center, flex-start);
              gap: 1rem;
              @include respond(mobile) {
                flex-wrap: wrap;
              }
              button {
                @extend .report_sidebar_button;
              }
            }
          }
        }
      }
      .external_links_expanded {
        @extend .external_link_list_wrapper;
        max-height: 100rem;
        transition: max-height 1s;
      }

      // score details
      .score_section_details_wrapper {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.5s;

        .score_section_content_wrapper {
          padding: 1.2rem 1.6rem;

          .score_content {
            @include font(500, $normal);
            color: $primary_black;
            padding: 1rem 0;
            .score_content_header {
              padding-bottom: 1rem;
            }
          }
          .final_score {
            @extend .score_content;
            color: $secondary_blue;
          }
          .incorporate_score,
          .legal_score {
            .score_details {
              display: grid;
              grid-template-columns: repeat(5, auto);
              gap: 1rem;
              @include respond(mobile) {
                grid-template-columns: 1fr;
              }

              .score_title_value {
                @include flex(row, center, flex-start);
                gap: 1rem;
                border-right: 1px solid $lightgrey;
                &:last-child {
                  border-right: none;
                }
                @include respond(mobile) {
                  justify-content: space-between;
                  border-right: none;
                }
              }

              .score_title {
                @include font(500, $small);
                color: $secondary_blue;
              }
              .score_value {
                @extend .score_title;
                color: $primary_black;
              }
            }
          }
          .score_divider {
            height: 2rem;
            border: 1px solid $lightgrey;
            @include respond(mobile) {
              display: none;
            }
          }
        }
        hr {
          border: 1px solid $lightgrey;
        }
      }
      .score_details_expanded {
        @extend .score_details_expanded;
        max-height: 100rem;
        transition: max-height 1s;
      }

      // activities log
      .activities_log_wrapper {
        padding: 0.8rem 1.6rem 1.2rem;
        overflow-x: auto;

        .activity_log_navigation_wrapper {
          @include flex(row, center, flex-start);
          overflow-x: auto;
          &::-webkit-scrollbar {
            display: none;
          }
          .activity_log {
            padding: 0.8rem 2.4rem;
            @include font(500, $small);
            color: $dark;
            height: 4rem;
            border-bottom: 2px solid transparent;
            transition: 0.3s ease-in-out;
            cursor: pointer;
            white-space: nowrap;
          }
          .selected_tab {
            @extend .activity_log;
            border-bottom: 2px solid $primary_blue;
            color: $primary_blue;
          }
        }
        .activity_log_details {
          overflow-y: auto;
          margin: 1rem 0;
          height: 30rem;
          @include scrollbar;
          .activity_log {
            @include flex(row, center, flex-start);
            gap: 2rem;
            height: auto;

            .activity_log_bullet {
              position: relative;
              height: 1.2rem;
              width: 1.2rem;
              border-radius: 50%;
              background: $light_9;
              &:not(.last_bullet)::after {
                content: '';
                border: 1px solid $light_9;
                height: 4rem;
                position: absolute;
                left: 50%;
                top: 1rem;
                transform: translate(-50%, 0);
              }
            }
            .activity_log_status {
              @include font(400, $normal);
              color: $primary_black;
              width: 100%;
            }
            .activity_time {
              @include font(400, $very-small);
              color: $secondary_blue;
            }
          }
          .activity_log_no_data {
            height: 100%;
            display: grid;
            place-items: center;
            @include font(500, $normal);
            color: $primary_black;
          }
        }
      }
      // status comments
      .status_comments_wrapper {
        padding: 1.6rem 1.2rem;
        .comment_form_wrapper {
          display: grid;
          grid-template-columns: 1fr max-content;
          grid-gap: 1rem;
          .select_wrapper,
          .input_wrapper {
            height: 4rem;
          }
          button {
            height: 2rem !important;
          }
          @include respond(mobile) {
            grid-template-columns: 1fr;
          }
          .comment_select_btn {
            @include flex(row, flex-start, flex-start);
            gap: 1rem;
          }
        }
        .comments_wrapper {
          margin-top: 1rem;
          height: 30rem;
          overflow-y: auto;
          @include scrollbar;
          .order_comment_wrapper {
            margin-top: 1rem;
            .comment_wrapper {
              @include flex(row, center, space-between);
              .commented_by_status_tag_wrapper {
                @include flex(row, center, flex-start);
                gap: 1rem;

                .private_comment {
                  @include font(400, $normal);
                  color: $slate700;
                  position: relative;
                  margin-left: 1rem;
                  &::before {
                    content: ' ';
                    display: block;
                    height: 0.75rem;
                    width: 0.75rem;
                    background: $slate200;
                    border-radius: 50%;
                    position: absolute;
                    top: 50%;
                    left: -30%;
                    transform: translate(0, -50%);
                  }
                }
                .commented_by {
                  @include font(500, $normal);
                  color: $primary_blue;
                }
              }
              svg {
                margin-right: 1rem;
                cursor: pointer;
              }
            }

            .order_comment {
              @include font(400, $normal);
              color: $primary_black;
            }
            .comment_time {
              @include font(400, $very-small);
              color: $secondary_blue;
            }
          }
        }
      }

      ////////////////////////////////////////////////////////
    }
    .credit_report_sidebar_section_shrinked {
      @extend .credit_report_sidebar_section;
      margin-top: 0;
      box-shadow: none;
    }

    .section_expanded {
      @extend .credit_report_sidebar_section_shrinked;
      box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    }

    .report_actions_wrapper {
      margin: 1.8rem 0;
      @include flex(row, center, flex-start);
      gap: 1.5rem;
      flex-wrap: wrap;
    }

    .status {
      padding: 0.6rem 1.2rem;
      @include font(500, $normal);
    }
  }
  .credit_sidebar_visible {
    @extend .credit_report_sidebar_content;
    right: 0;
    visibility: visible;
    opacity: 1;
    transition: 0.3s ease-in-out;
    width: 70%;
    @include respond(mobile) {
      width: 100%;
    }
  }
  .credit_sidebar_invisible {
    @extend .credit_sidebar_visible;
    right: -100%;
    opacity: 1;
    visibility: hidden;
  }

  // report details
  .report_details_wrapper {
    max-height: 100%;
    overflow-y: auto;
    @include scrollbar;
    @include font(500, $normal);
    .report_details_header {
      .report_title {
        color: $primary_black;
      }
      .report_title_2 {
        margin: 2.5rem 0 1rem 0;
      }
      @include flex(row, center, space-between);
    }
    .report_key_value_wrapper {
      margin-top: 2rem;
      .report_key_value {
        @include flex(row, center, flex-start);
        padding: 0.35rem;
        &:nth-child(odd) {
          background: $slate100;
        }
        .report_key {
          color: $secondary_blue;
          width: 50%;
          white-space: nowrap;
        }

        .ordered_reports_wrapper {
          @include flex(row, center, flex-start);
          gap: 1rem;
          @include respond(mobile) {
            @include flex(column, flex-start, flex-start);
            gap: 0.5rem;
          }
        }
      }
    }
  }
}

.credit_content_visible {
  @extend .credit_report_sidebar_content_wrapper;
  height: auto;
}
.credit_content_invisible {
  @extend .credit_content_visible;
  height: 0;
}

.separator {
  border-bottom: 1px solid $lightgrey;
  padding-bottom: 1rem;
}

.report_sidebar_button {
  @include font(500, $small);
  color: $dark;
  padding: 0.8rem 1.2rem;
}

.report_sidebar_more {
  transition: 0.3s ease-in-out;
  cursor: pointer;
}
.up_arrow {
  transform: rotate(180deg);
}

.report_sidebar_section {
  background: #fff;
  border-radius: 0.4rem;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
  border: 1.5px solid $lightgrey;

  .report_score_section_wrapper {
    padding: 1.2rem 1.6rem;
  }

  //score section
  .report_score_section_wrapper {
    .score_section_header {
      @include flex(row, center, space-between);
    }
  }
}

.report_sidebar_heading {
  @include font(500, $normal);
  color: $primary_black;
  white-space: nowrap;
}

.credit_sidebar_loader {
  height: 100%;
  display: grid;
  grid-template-rows: repeat(5, 4rem);
  gap: 1rem;
  .credit_sidebar_skeleton {
    &:nth-child(2n + 1) {
      width: 75%;
    }
  }
}

// esc -data

.esc_actions_wrapper {
  background: #fff;
  @include flex(row, center, space-between);
  @include respond(tablet) {
    @include flex(column, flex-start, flex-start);
  }

  .esc_filter_content {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    .esc_content_wrapper {
      @include flex(row, center, flex-start);
      gap: 1rem;
      @include respond(mobile) {
        @include flex(column, flex-start, flex-start);
      }
      @include font(500, $normal);
      color: $slate700;
      .extra_fee {
        font-weight: 400;
        font-style: italic;
      }
      .esc_divider {
        height: 0.75rem;
        width: 0.75rem;
        border-radius: 50%;
        background: $slate200;
        @include respond(mobile) {
          display: none;
        }
      }
    }

    .search_wrapper {
      width: 35rem;
      @include respond(tablet) {
        width: 100%;
      }
    }
  }

  .selected_subject_details {
    width: 60rem;
    padding: 1rem;
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    .esc_heading {
      @include flex(row, center, flex-start);
      gap: 1rem;
      @include font(600, $normal);
      color: $slate700;
    }
    .esc_key_value {
      @include flex(row, center, flex-start);
      gap: 1rem;
      @include font(500, $normal);

      .esc_key {
        color: $secondary_blue;
      }
    }
  }
}

.esc_search_results {
  background: $slate50;
  padding: 1rem;
  @include flex(row, center, flex-start);
  gap: 2rem;
  height: 4.5rem;

  .esc_search_text {
    @include font(400, $normal);
    color: $secondary_blue;
  }
  .searched_result {
    padding: 0.25rem 1.5rem;
    background: $slate100;
    border-radius: 1.75rem;
    @include font(400, $normal);
    color: $slate700;
    cursor: pointer;
    text-transform: capitalize;
  }
  .selected_search {
    @extend .searched_result;
    background: $primary_blue;
    color: #fff;
  }
}

.esc_select_company_text {
  @include font(400, $normal);
  color: $primary_black;
}

.edit_bot_form_wrapper {
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  .form_field {
    .input_icon {
      left: 84%;
    }
  }
}

.send_fax_wrapper {
  max-height: 45rem;
  overflow-y: auto;
  @include scrollbar;
  padding-right: 1rem;
  .fax_key_value {
    display: grid;
    grid-template-columns: auto 1fr;
    grid-column-gap: 1rem;
    align-items: baseline;
    @include font(500, $normal);
    .fax_key {
      color: $secondary_blue;
    }
    .fax_value {
      color: $primary_black;
      @include flex(row, center, flex-start);
      gap: 2rem;
      .field_0,
      .field_1 {
        width: 100%;
      }
    }
  }
  .fax_files {
    @include flex(column, flex-start, flex-start);
    gap: 1rem;
    .fax_value_files {
      display: grid;
      grid-template-rows: auto;
      grid-row-gap: 1rem;
      width: 100%;
      .fax_file {
        @include flex(row, center, space-between);
      }
    }
  }
  .fax_logs_wrapper {
    .logs_header {
      padding: 0.5rem;
      @include flex(row, center, flex-start);
      gap: 1rem;

      .logs_heading {
        color: $primary_blue;
        @include font(500, $small);
      }
    }
    .logs_value_wrapper {
      border-radius: 4px 0px 0px 4px;
      &:nth-child(even) {
        background: $slate100;
      }

      @extend .logs_header;
      .logs_value {
        color: $slate700;
        @include font(400, $small);
      }
      .logs_mode {
        text-transform: capitalize;
      }
    }
    .log_date {
      width: 12rem;
    }
    .log_language {
      width: 7rem;
    }
    .log_mode {
      width: 3.5rem;
    }
    .log_to {
      width: 11rem;
      @include ellipsis;
    }
  }
  .no_transit_wrapper {
    @include font(500, $normal);
    color: $error;
    text-align: center;
    div:nth-child(2) {
      color: $primary_blue;
      margin-top: 1rem;
    }
  }
}
