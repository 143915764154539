.datepicker_component {
  width: max-content;

  .input_component {
    position: relative;
  }
  .calendar_wrapper {
    transform: scale(0.95);
    opacity: 0;
    transition: 0.1s ease-out;
    visibility: hidden;
  }
  .show_calendar {
    @extend .calendar_wrapper;
    transform: scale(1);
    opacity: 1;
    visibility: visible;

    .calendar_component {
      width: 24.8rem;
      border-radius: 0.6rem;
      border: 1px solid $slate200;
      background: #fff;
      box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.05);
      padding: 1.2rem;
      position: fixed;
      margin-top: 0.8rem;

      .calendar_header {
        padding: 1rem;
        height: 2.8rem;
        @include flex(row, center, space-between);
        cursor: pointer;

        .month_year {
          @include font(500, $normal);
          color: $slate900;
          padding: 0.25rem 0.8rem;
          border-radius: 0.6rem;
          transition: 0.2s ease-out;
          &:hover {
            background: $slate100;
          }
        }
      }
      .calendar_week_days {
        .calendar_week,
        .calendar_days {
          display: grid;
          grid-template-columns: repeat(7, 1fr);

          span {
            width: 100%;
            @include center;
          }
        }
        .calendar_week {
          padding-top: 1.6rem;
          span {
            width: 3.2rem;
            padding: 0 1rem;
            @include font(400, $small);
            color: $slate500;
          }
        }
        .calendar_days {
          grid-row-gap: 0.8rem;
          margin-top: 0.8rem;
          width: 22.4rem;

          .previous_days,
          .next_days {
            @extend .month_days;
            opacity: 0.5;
            &:hover {
              opacity: 1;
            }
          }
        }
      }
      .month_days {
        height: 3.2rem;
        width: 3.2rem;
        @include font(400, $normal);
        color: $slate900;
        transition: 0.2s ease-out;
        border-radius: 0.6rem;
        &:hover {
          background: $slate100;
          cursor: pointer;
        }
      }
      .selected_day {
        @extend .month_days;
        background: $slate900;
        color: #fff;
        &:hover {
          background: $slate900;
          color: #fff;
        }
      }
      .calendar_year_month {
        margin-top: 1.6rem;
        height: 27.6rem;
        overflow-y: scroll;
        scrollbar-width: none;
        @include flex(column-reverse, flex-start, flex-start);

        .year_component {
          display: grid;
          grid-template-columns: 3.5rem 1fr;

          grid-gap: 1.2rem;
          height: 10.4rem;
          margin-top: 0.8rem;

          .years {
            @include flex(row, center, flex-start);
            color: $slate700;
            @include font(400, $normal);
          }

          .current_year {
            color: $primary_blue;
          }

          .months {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-column-gap: 0.8rem;
            grid-row-gap: 0.4rem;

            .month {
              @extend .month_days;
              @include center;
            }
            .current_month {
              @extend .selected_day;
            }
          }
          .year_separator {
            height: 1px;
            background: $slate50;
            width: 21.5rem;
          }
        }
      }
    }
  }
  .hide_calendar {
    @extend .show_calendar;
    opacity: 0;
    transition: 0.1s ease-out;
    transform: scale(0.95);
    visibility: hidden;
  }
}
