.order_new_report_container {
  display: grid;
  grid-template-columns: 28rem 1fr;

  @include respond(tablet) {
    border: 3px solid green;
    grid-template-columns: 1fr;
  }

  .order_new_report_wrapper {
    @include font(500, $normal);
    padding: 2rem;
    background: $slate50;
    @include respond(mobile) {
      padding: 1rem;
    }
    .order_report_navigation {
      display: grid;
      grid-template-columns: 4rem 1fr;
      .order_report_description_wrapper {
        .order_report_description {
          height: 14.5rem;
          @include respond(tablet) {
            height: 8rem;
          }
          .title_status_wrapper {
            height: 2.5rem;
            @include flex(row, center, flex-start);
            height: 2.5rem;
            .title {
              @include font(500, $normal);
              white-space: nowrap;
              color: $slate700;
            }
            .step_active {
              @extend .title;
              color: $primary_blue;
            }
            .status {
              height: 2.5rem;
              @include flex(row, center, center);
            }
          }
          .description {
            @include font(400, $small);
            color: $slate500;
          }
        }
      }
      .order_report_bullets_wrapper {
        .report_count_bar_wrapper {
          @include flex(column, center, center);
          .report_count {
            height: 2.5rem;
            width: 2.5rem;
            border-radius: 50%;
            padding: 0.6rem;
            @include flex(row, center, center);
            font-size: $small;
            background: $slate200;
            color: $slate500;
          }
          .report_bar {
            height: 12rem;
            width: 0.35rem;
            background: $slate200;
            @include respond(tablet) {
              height: 6rem;
            }
            .report_progress {
              background: $primary_blue;
            }
            .step_complete {
              @extend .report_progress;
              animation: fillBar 1s forwards;
            }
          }
        }
        .step_active {
          @extend .report_count_bar_wrapper;
          .report_count {
            background: $primary_blue;
            color: #fff;
          }
        }
      }
    }
  }
  .order_new_report_components {
    height: calc(100vh - 11rem);
    overflow-y: auto;
    font-size: $normal;
    background: #fff;
    @include scrollbar;
    scroll-behavior: smooth;

    .select_wrapper,
    .input_wrapper {
      z-index: 1;
    }
    .heading {
      color: $slate900;

      @include font(600, $normal);
    }

    .report_options_wrapper {
      padding: 2.5rem;
      @include respond(tablet) {
        padding: 1rem;
      }
      .report_ref_no {
        width: 40rem;
        @include respond(mobile) {
          width: 33rem;
        }
      }

      .basic_info_wrapper {
        margin-bottom: 1rem;
        .basic_info_form {
          // border: 1px solid blue;
          .account_to_bill,
          .report_subject_name {
            width: 40rem;
            @include respond(mobile) {
              width: 33rem;
            }
          }
          .section_one {
            // border: 1px solid blue;
            @include flex(row, center, flex-start);
            flex-wrap: wrap;
            gap: 2rem;

            .subject_type_wrapper {
              .subject_type_content {
                @include flex(row, center, flex-start);
                gap: 1rem;
                .title {
                  @include font(500, $normal);
                  white-space: nowrap;
                }
                .title_error {
                  color: $red700;
                }
              }
            }
          }
          .location_wrapper {
            @include flex(row, center, flex-start);
            gap: 1rem;
            white-space: nowrap;
            flex-wrap: wrap;
            .title {
              @include font(500, $normal);
            }
            .title_error {
              color: $red700;
            }
          }
        }
      }

      .upload_file_wrapper {
        margin-bottom: 2rem;
        .heading {
          margin-bottom: 0.5rem;
        }
        .upload_subheading {
          margin-bottom: 1rem;
          @include font(400, $small);
          color: $slate500;
        }
        .upload_content_wrapper,
        .uploaded_file_wrapper {
          width: 25rem;
        }
        .uploaded_credit_applications {
          margin-top: 1rem;
          @include flex(column, flex-start, flex-start);
          gap: 1rem;
          .application_wrapper {
            @include font(500, $normal);
            color: $primary_blue;
            @include flex(row, center, flex-start);
            gap: 1rem;
            svg {
              cursor: pointer;
            }
          }
        }
      }

      .report_btns_wrapper {
        @include flex(row, center, flex-start);
        gap: 2rem;
        @include respond(mobile) {
          gap: 1rem;
        }
      }
      .heading {
        @include font(500, $normal);
        color: $primary_blue;
      }
    }
    .order_new_report_form_wrapper {
      scroll-behavior: smooth;
      .report_form_section {
        .report_form_note {
          @include flex(row, center, flex-end);

          @include font(500, $small);
          color: $dark_1;
        }
        padding: 2rem 2rem 1rem;
        margin-bottom: 3rem;
        background: #fff;
        box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.05);
        border-radius: 0.8rem;

        .report_form_wrapper {
          display: grid;
          grid-column-gap: 2rem;
          margin: 2rem 0;
          .select_wrapper,
          .input_wrapper {
            margin-bottom: 0.25rem;
          }
          .select_wrapper {
            z-index: 2;
          }
        }
        .form_2 {
          @extend .report_form_wrapper;
          grid-template-columns: repeat(2, 1fr);
          @include respond(mobile) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-row-gap: 1rem;
          }
        }
        .form_3 {
          @extend .report_form_wrapper;
          grid-template-columns: repeat(3, 1fr);
          @include respond(mobile) {
            grid-template-columns: 1fr;
            grid-template-rows: auto;
            grid-row-gap: 1rem;
          }
        }
        .supplier_fields_wrapper {
          .supplier_group_wrapper {
            @include flex(row, center, space-between);
            margin-bottom: 2rem;
          }
          .supplier_group {
            @include flex(row, center, space-between);
            gap: 1rem;
            flex-wrap: wrap;
            .form_field {
              &:nth-child(-n + 2) {
                width: calc(50% - 1rem);
              }
              &:nth-child(n + 3) {
                width: calc(33% - 1rem);
              }
            }
          }
        }
        .general_information_fields {
          @include flex(row, center, space-between);
          gap: 1rem;
          flex-wrap: wrap;
          .form_field {
            &:nth-child(-n + 2) {
              width: calc(50% - 1rem);
            }
            &:nth-child(n + 3) {
              width: calc(33% - 1rem);
            }
            &:nth-last-child(-n + 2) {
              width: calc(50% - 1rem);
            }
          }
          .field_invisible {
            display: none;
          }
        }
        .legal_entity_details {
          @extend .form_2;
        }
        .bank_information_fields {
          @include flex(row, center, space-between);
          gap: 1rem;
          flex-wrap: wrap;

          .form_field {
            &:nth-child(-n + 2) {
              width: calc(50% - 1rem);
            }
            &:nth-child(n + 3) {
              width: calc(33% - 1rem);
            }
          }
        }
      }
      .report_form_btn_wrapper {
        padding: 1rem 2rem;
      }
      .report_form_heading {
        color: $primary_blue;
        @include font(600, $normal);
        margin-bottom: 2rem;
      }
      .report_form_action {
        @include flex(row, center, space-between);
      }
      .report_form_subheading {
        @extend .report_form_heading;
        margin: 1rem 0;
        color: $dark;
      }
    }
    button {
      @include font(500, $normal);

      padding: 1rem 3rem;
      @include respond(mobile) {
        padding: 1rem 1.5rem;
        font-size: $small;
      }
    }
    .report_confirmation_wrapper {
      height: 100%;

      @include flex(row, center, center);
      .confirmation_box {
        height: 50rem;
        width: 60rem;
        background: #fff;
        border-radius: 0.8rem;
        @include flex(column, center, space-evenly);
        @include respond(mobile) {
          max-width: 30rem;
        }
        .confirmation_text {
          width: 60%;

          @include font(400, $normal);
          @include respond(mobile) {
            text-align: center;
            width: 85%;
          }
        }
        .confirmation_btns {
          @include flex(row, center, center);
          @include respond(mobile) {
            flex-direction: column;
          }
          gap: 1.5rem;
        }
      }
    }
  }
}
.update_report_from_sidebar {
  @extend .order_new_report_container;
  grid-template-columns: 1fr;
  .order_new_report_components {
    height: calc(100vh - 6rem);
  }
}

.report_section_status_wrapper {
  padding: 1rem 0;
  @include flex(row, center, flex-start);
  gap: 1rem;
  width: 100%;

  .report_status_processing {
    border-color: $orange_3;
    background: $light_7;
  }
  .report_status_pending {
    background: $slate100;
    border-color: $slate300;
  }
  .report_status_error {
    background: $red50;
    border-color: $red700;
  }
  .report_status_completed {
    background: $green_1;
    border-color: $success;
  }
  .report_status_cancelled {
    background: $slate100;
    border-color: $slate400;
  }
  .report_status_action_needed {
    background: $red100;
    border-color: $red700;
  }

  .report_section_status {
    border-radius: 0.4rem;
    padding: 0.8rem;
    height: 3.2rem;
    width: max-content;
    @include flex(row, center, flex-start);
    gap: 1rem;
  }
  .incorporate {
    @extend .report_section_status;
    border: 1.5px solid $orange_1;
    background: $light_7;
  }
  .legal {
    @extend .report_section_status;
    background: $green_1;
    border: 1.5px solid $success;
  }
  .bank {
    @extend .report_section_status;
    background: $red_2;
    border: 1.5px solid $dark_1;
  }
  .suppliers {
    @extend .report_section_status;
    background: linear-gradient(90deg, $green_1 0%, $light_7 100%);
    border: 1.5px solid $orange_1;
  }

  .download_report {
    @include flex(row, center, flex-start);
    gap: 1rem;
    border-radius: 0.4rem;
    background: #fff;
    border: 1px solid $lightgrey;
    padding: 0.8rem;
    @include font(500, $very-small);
    color: $secondary_blue;
  }
}
.from_sidebar {
  @extend .report_section_status_wrapper;
  @include respond(mobile) {
    flex-wrap: wrap;
  }
}

@keyframes fillBar {
  0% {
    height: 0;
  }
  100% {
    height: 100%;
  }
}

.report_line {
  margin: 1.5rem 0;
  border: 1px solid $slate100;
}

.report_error_box {
  margin-bottom: 0.75rem;
  @include flex(row, center, flex-start);
}

.report_selection_wrapper {
  .heading_subheading_wrapper {
    @include flex(row, flex-start, flex-start);
    margin-bottom: 1rem;
    @include respond(tablet) {
      @include flex(column, flex-start, flex-start);
      gap: 0;
    }
    gap: 1rem;
    .heading {
      white-space: nowrap;
      @include font(600, $normal);
      color: $primary_blue;
    }
    .sub_heading {
      @include font(400, $normal);
      color: $slate600;
    }
  }
  .report_selection {
    label {
      margin-bottom: 1rem;
      @include flex(row, center, flex-start);
      gap: 0.75rem;
      color: $primary_black;
      @include font(500, $normal);
      @include respond(mobile) {
        font-size: $small;
        margin-top: 1rem;
      }
    }
  }
  .report_selection_box_wrapper {
    margin: 1.5rem 0;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    padding: 0.5rem;
    @include flex(row, center, flex-start);
    gap: 2rem;
    flex-wrap: wrap;

    .report_selection_box {
      background: $lightgrey;
      transition: 0.3s ease-in-out;
      padding: 1.6rem 2.4rem;
      border-radius: 0.4rem;
      width: max-content;
      @include flex(row, center, flex-start);
      cursor: pointer;
      gap: 1rem;
      @include font(500, $normal);
      color: $slate900;

      span {
        white-space: nowrap;
        user-select: none;
        @include respond(mobile) {
          font-size: $small;
        }
      }
    }
    .report_disabled {
      cursor: not-allowed !important;
      background: $slate50;
      color: $slate400;
      pointer-events: none;

      svg {
        fill: $slate400;
      }
    }
    .report_selected {
      @extend .report_selection_box;
      color: #fff;
      background: linear-gradient(
        104deg,
        $primary_blue -9.21%,
        $blue_4 106.19%
      );
      box-shadow: 0px 4px 8px 0px rgba(53, 110, 177, 0.1);
    }
  }
  .report_selection_error {
    @extend .report_selection_box_wrapper;
    border-color: $error;
  }
  .other_reports_wrapper {
    .other_report_heading {
      @include font(500, $normal);
      color: $primary_blue;
      margin: 1rem 0;
    }
  }
}

.location_wrapper,
.bank_options_wrapper {
  margin-bottom: 2rem;
  .location_form,
  .bank_options_list {
    @include flex(row, center, flex-start);
    label {
      @include font(400, $normal);
      @include flex(row, center, flex-start);
      margin-right: 2rem;
      input {
        margin-right: 0.5rem;
      }
    }
  }
  .heading {
    margin-bottom: 1rem;
  }
}

.report_status_tag {
  border: 1px solid transparent;
  @include flex(row, center, flex-start);
  gap: 0.8rem;
  padding: 0.8rem;
  border-radius: 0.5rem;
  position: relative;
  .tooltip_wrapper {
    width: 100%;
    position: absolute;
    bottom: 100%;
    left: 0;
    @include flex(row, center, center);
    .report_status_tooltip {
      margin-bottom: 1rem;
      opacity: 0;
      pointer-events: none;
      transform: translateY(1rem);
      transition: 0.3s ease-in-out;
      color: #fff;
      @include font(500, $very-small);
      padding: 0.5rem 1rem;
      border-radius: 0.4rem;
      width: max-content;
      &::before {
        content: ' ';
      }
      &::after {
        border-left: solid transparent 0.75rem;
        border-right: solid transparent 0.75rem;
        bottom: -1rem;
        content: ' ';
        height: 0;
        left: 50%;
        transform: translateX(-50%);
        position: absolute;
        width: 0;
      }
    }
    .tooltip_Pending {
      background: $slate700;
      &::after {
        border-top: solid $slate700 1rem;
      }
    }
    .tooltip_Processing {
      background: $orange_3;
      &::after {
        border-top: solid $orange_3 1rem;
      }
    }
    .tooltip_Completed {
      background: $success;
      &::after {
        border-top: solid $success 1rem;
      }
    }
    .tooltip_Canceled {
      background: $black_2;
      &::after {
        border-top: solid $black_2 1rem;
      }
    }
  }
  &:hover {
    cursor: pointer;
    .report_status_tooltip {
      opacity: 1;
      pointer-events: auto;
      transform: translateY(0rem);
    }
  }
}
