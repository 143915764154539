$hover_color: #2b73b2;
$primary_black: #121723;
$black: #000000;
$secondary_blue: #6d738d;
$error: #f44336;
$danger: #dc2626;
$ember600: #d97706;
$success: #33ad73;
$disabled: #e0e0e0;
$blur: #ef5b0c;
$lightgrey: #edf1f5;
$field: rgba(0, 0, 0, 0.23);
$primary_blue: #356eb1;
$offwhite: #dfe7ef;
$navitembackgroud: #2d5f99;
$dark: #384665;
$accountbg: #eff3f8;
$sidebarItembg: #ecf6ff;
$dullgrey: #f6f8fa;
$brightOrange: #ef5b0c;
$lightred: #929292;
$slightgrey: #f2f5f8;
$skeleton: #ebeff1;
$greywhite: #f7f7f7;
$darkgreywhite: #f8f8f8;
$lightgreywhite: #f1f1f1;
$lightwhite: #e1e8ef;
$light_1: #e5ebef;
$light_2: #eef2f7;
$light_3: #fafbfc;
$light_4: #d9e1eb;
$light_5: #e0e8ef;
$light_6: #c1cddc;

$grey_1: #ebeff4;
$grey_2: #dce6ef;
$slate500: #64748b;
$slate600: #475569;
$slate400: #94a3b8;
$grey_5: #a3a3a3;
$grey_6: #e5e5e5;
$grey_7: #e9e9ea;

$blue_1: #dfeeff;
$blue_2: #dbeafe;
$blue_3: #eff6ff;
$blue_4: #588ecf;
$blue_5: #4e87ca;
$blue_6: #3b7ac4;
$blue_7: #2f629d;
$blue_8: #ccdbeb;

$dark_1: #ed4e62;
$slate800: #1e293b;
$slate700: #334155;
$text_foreground: #09090b;

$green_1: #eafff5;
$green_2: #ffa700;
$green_3: #dcdcdc;
$green_4: #929292;
$green_5: #65c466;
$green700: #15803d;
$green_7: #dcfce7;

$red_2: #ffeaea;
$red_3: #be123c;
$red500: #ef4444;
$red600: #dc2626;
$red700: #b91c1c;
$red50: #fef2f2;
$red100: #fee2e2;
$red_7: #71717a;

$red_1: #c53d3d;
$orange_1: #ff9900;
$orange_2: #16a34a;
$orange_3: #f90;

$light_7: #fff7ea;
$light_8: #f7f9fa;
$light_9: #e9eff3;
$slate100: #f1f5f9;
$slate200: #e2e8f0;
$blue200: #eff7ff;
$slate50: #f8fafc;
$slate300: #cbd5e1;
$light_15: #f5f5f5;
$light_16: rgba(0, 0, 0, 0.08);
$light_17: rgba(0, 0, 0, 0.04);
$light_18: rgba(0, 0, 0, 0.12);
$light_19: #e5e7eb;
$lgith_20: #9ca3af;
$light_21: #a1acc2;
$light_22: #fff7f7;
$light_23: #bdbdbd;
$light_24: #e4e4e7;

$slate900: #0f172a;
$black_2: #020617;
$secondary_foreground: #18181b;
$timestamp: rgba(0, 0, 0, 0.5);

$ember50: #fffbeb;
$ember_2: #fef3c7;
$ember700: #b45309;
$green50: #f0fdf4;
$ember_5: #f4f5f8;
$ember_6: #ecfff6;
